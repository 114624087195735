import React, { useCallback } from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";

import "./NumericEditor.scss";

export interface NumericEditorProps {
    className?: string;
    spinners?: boolean;
    value?: number;
    min?: number;
    max?: number;
    onChange?: (value: number, event: Event) => void;
    format?: string;
    disabled?: boolean;
    placeholder?: string;
    width?: string;
}
  
export default function NumericEditor(props: NumericEditorProps) {
    const onChange = useCallback((e: any) => {
        if (props.onChange) {
            props.onChange(e.target.value, e);
        }
    }, [props.onChange]);

    return <NumericTextBox
        className={props.className}
        spinners={props.spinners || false}
        value={props.value}
        min={props.min}
        max={props.max}
        onChange={onChange}
        format={props.format}
        placeholder={props.placeholder}
        disabled={props.disabled}
        width={props.width}
    />;
}