import React, { useState, useEffect } from 'react';
import arxs from 'infra/arxs';
import { OriginModuleEnum, StatusEnum, TaskStatus, TaskRequestStatus, MultiYearPlanStatus, RecommendationStatus, RelationshipType, DocumentStatus, CommissioningStatus, InstructionCardStatus, DecommissioningStatus, IncidentV2Status } from 'infra/api/contracts';

import NotificationTile from './NotificationTile';

import "./NotificationTiles.scss";

const isNotDeleted = x => !x.isDeleted;
const isSubscribed = x => x.isSubscribed;
const containsAction = (x, action) => x.actions.map(a => a.split(":")[0]).contains(action);
const currentDate = new Date();

const hasReachedNotificationDate = (card, planningMoments, currentDate) => {
  if (!card.notificationDate || !card.targetDate) {
    return true;
  }

  const targetDate = new Date(card.targetDate);
  const notificationDate = new Date(card.notificationDate);
  const planningMoment = planningMoments.filter(x => x.subject && x.subject.id === card.id && x.subject.module === card.module)[0];

  if (planningMoment) {
    const offset = targetDate.getTime() - notificationDate.getTime();
    const planningDate = new Date(new Date(planningMoment.start).getTime() - offset);
    return currentDate >= planningDate;
  }
  return currentDate >= notificationDate;
};

const buildShouldNotifyAssignee = (profile, planningMoments) => {
  const userId = profile.id;
  const userRoleIds = profile.getUserRoleIds();

  const isAssignee = card => {
    return (card.relationships || [])
      .some(x => (x.type === RelationshipType.Assignee)
          && ((x.employee && x.employee.id === userId)
              || (x.userRole && userRoleIds.indexOf(x.userRole.id) > -1)));
  };

  return x => !isAssignee(x) || hasReachedNotificationDate(x, planningMoments, currentDate);
};

const tileDefinitions =
  [
    [
      [
        OriginModuleEnum.NotificationDefect
      ],
      [
        {
          status: TaskRequestStatus.Pending,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "accept")),
          searchTerm: "action:accept subscribed",
        },
      ]
    ],
    [
      [
        OriginModuleEnum.Task,
        OriginModuleEnum.PeriodicMaintenance,
        OriginModuleEnum.PeriodicControl,
        OriginModuleEnum.RiskAnalysis,
      ],
      [
        {
          status: TaskStatus.OnHold,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "unhold")),
          searchTerm: "action:unhold subscribed",
        },
        {
          status: TaskStatus.ToVerify,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "verify")),
          searchTerm: "action:verify subscribed",
        },
        {
          status: TaskStatus.ExecutionOverdue,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === TaskStatus.ExecutionOverdue),
          searchTerm: "status:executionoverdue subscribed",
        },
        {
          status: TaskStatus.Active,
          filter: (items, profile, planningMoments) => items.filter(isNotDeleted).filter(isSubscribed)
            .filter(x => x.status === TaskStatus.Active)
            .filter(buildShouldNotifyAssignee(profile, planningMoments)),
          searchTerm: "status:active subscribed",
        }
      ]
    ],
    [
      [
        OriginModuleEnum.Labourmeans,
        OriginModuleEnum.EquipmentInstallation,
        OriginModuleEnum.HazardousSubstance,
        OriginModuleEnum.Pbm,
        OriginModuleEnum.IntangibleAsset
      ],
      [
        {
          status: StatusEnum.Expired,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "unexpire")),
          searchTerm: "action:unexpire subscribed",
        },
      ]
    ],
    [
      [OriginModuleEnum.GlobalPreventionPlan],
      [
        {
          status: MultiYearPlanStatus.Overdue,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === MultiYearPlanStatus.Overdue),
          searchTerm: "status:overdue subscribed"
        }
      ]
    ],
    [
      [OriginModuleEnum.Consultancy],
      [
        {
          status: RecommendationStatus.Requested,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === RecommendationStatus.Requested),
          searchTerm: "status:requested subscribed"
        },
        {
          status: RecommendationStatus.ToSign,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "sign")),
          searchTerm: "action:sign subscribed"
        }
      ]
    ],
    [
      [OriginModuleEnum.Document],
      [
        {
          status: DocumentStatus.Expired,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === DocumentStatus.Expired),
          searchTerm: "status:expired subscribed",
        }
      ]
    ],
    [
      [OriginModuleEnum.Commissioning],
      [
        {
          status: CommissioningStatus.InProcess,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === CommissioningStatus.InProcess),
          searchTerm: "status:inprocess subscribed",
        },
        {
          status: CommissioningStatus.ToSign,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "sign")),
          searchTerm: "action:sign subscribed",
        }
      ]
    ],
    [
      [OriginModuleEnum.OutOfCommissioning],
      [
        {
          status: DecommissioningStatus.InProcess,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === DecommissioningStatus.InProcess),
          searchTerm: "status:inprocess subscribed",
        },
        {
          status: DecommissioningStatus.ToSign,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "sign")),
          searchTerm: "action:sign subscribed",
        }
      ]
    ],
    [
      [OriginModuleEnum.SafetyInstructionCard],
      [
        {
          status: InstructionCardStatus.InProcess,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === InstructionCardStatus.InProcess),
          searchTerm: "status:inprocess subscribed",
        },
        {
          status: InstructionCardStatus.ToSign,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "sign")),
          searchTerm: "action:sign subscribed",
        }
      ]
    ],
    [
      [OriginModuleEnum.InstructionCard],
      [
        {
          status: InstructionCardStatus.InProcess,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === InstructionCardStatus.InProcess),
          searchTerm: "status:inprocess subscribed",
        },
        {
          status: InstructionCardStatus.ToSign,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => containsAction(x, "sign")),
          searchTerm: "action:sign subscribed",
        }
      ]
    ],
    [
      [OriginModuleEnum.IncidentManagement],
      [
        {
          status: IncidentV2Status.Pending,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === IncidentV2Status.Pending),
          searchTerm: "status:pending subscribed",
        },
        {
          status: IncidentV2Status.ToInvestigate,
          filter: (items) => items.filter(isNotDeleted).filter(isSubscribed).filter(x => x.status === IncidentV2Status.ToInvestigate),
          searchTerm: "status:toinvestigate subscribed",
        }
      ]
    ],
  ]
    .flatMap(x => x[0].map(module => [module, x[1]]))
    .toDictionary(x => x[0], x => x[1]);

const generateTiles = (module, data, profile, planningMoments) => {
    return tileDefinitions[module]
      .map(definition => {
        const items = definition.filter(data || [], profile, planningMoments);
        return {
          title: definition.title,
          status: definition.status,
          searchTerm: definition.searchTerm,
          items,
          count: items.length,
        };
      })
      .filter(x => x.count > 0);
};

export default function NotificationTiles(props) {
  const lookups = {
    tasks: [],
    taskRequests: [],
    inspections: [],
    multiYearPlans: [],
    maintenances: [],
    labourmeans: [],
    equipments: [],
    protectionEquipments: [],
    intangibleAssets: [],
    hazardousSubstances: [],
    documents: [],
    riskAnalysises: [],
    recommendations: [],
    commissionings: [],
    decommissionings: [],
    safetyInstructionCards: [],
    instructionCards: [],
    planningMoments: [],
    incidents: [],
  };

  const [tasks, setTasks] = useState([]);
  const [taskRequests, setTaskRequests] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [multiYearPlans, setMultiYearPlans] = useState([]);
  const [labourmeans, setLabourmeans] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [protectionEquipments, setProtectionEquipments] = useState([]);
  const [intangibleAssets, setIntangibleAssets] = useState([]);
  const [hazardousSubstances, setHazardousSubstances] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [riskAnalysises, setRiskAnalysises] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [commissionings, setCommissionings] = useState([]);
  const [decommissionings, setDecommissionings] = useState([]);
  const [safetyInstructionCards, setSafetyInstructionCards] = useState([]);
  const [instructionCards, setInstructionCards] = useState([]);
  const [planningMoments, setPlanningMoments] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
        for (var key of Object.keys(values)) {
          switch (key) {
            case "tasks": setTasks(values[key]); break;
            case "inspections": setInspections(values[key]); break;
            case "maintenances": setMaintenances(values[key]); break;
            case "multiYearPlans": setMultiYearPlans(values[key]); break;
            case "taskRequests": setTaskRequests(values[key]); break;
            case "labourmeans": setLabourmeans(values[key]); break;
            case "equipments": setEquipments(values[key]); break;
            case "protectionEquipments": setProtectionEquipments(values[key]); break;
            case "intangibleAssets": setIntangibleAssets(values[key]); break;
            case "hazardousSubstances": setHazardousSubstances(values[key]); break;
            case "documents": setDocuments(values[key]); break;
            case "riskAnalysises": setRiskAnalysises(values[key]); break;
            case "recommendations": setRecommendations(values[key]); break;
            case "commissionings": setCommissionings(values[key]); break;
            case "decommissionings": setDecommissionings(values[key]); break;
            case "safetyInstructionCards": setSafetyInstructionCards(values[key]); break;
            case "instructionCards": setInstructionCards(values[key]); break;
            case "planningMoments": setPlanningMoments(values[key]); break;
            case "incidents": setIncidents(values[key]); break;
            default: break;
          }
        }
      })
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, [lookups]);

  useEffect(() => {
    const getItemsForModule = (module, profile) => {
      switch (module) {
        case OriginModuleEnum.Task:
          return generateTiles(module, tasks, profile, planningMoments);
        case OriginModuleEnum.PeriodicControl:
          return generateTiles(module, inspections, profile, planningMoments);
        case OriginModuleEnum.PeriodicMaintenance:
          return generateTiles(module, maintenances, profile, planningMoments);
        case OriginModuleEnum.GlobalPreventionPlan:
          return generateTiles(module, multiYearPlans, profile);
        case OriginModuleEnum.NotificationDefect:
          return generateTiles(module, taskRequests, profile);
        case OriginModuleEnum.Labourmeans:
          return generateTiles(module, labourmeans, profile);
        case OriginModuleEnum.EquipmentInstallation:
          return generateTiles(module, equipments, profile);
        case OriginModuleEnum.HazardousSubstance:
          return generateTiles(module, hazardousSubstances, profile);
        case OriginModuleEnum.Pbm:
          return generateTiles(module, protectionEquipments, profile);
        case OriginModuleEnum.IntangibleAsset:
          return generateTiles(module, intangibleAssets, profile);
        case OriginModuleEnum.Document:
          return generateTiles(module, documents, profile);
        case OriginModuleEnum.RiskAnalysis:
          return generateTiles(module, riskAnalysises, profile, planningMoments);
        case OriginModuleEnum.Consultancy:
          return generateTiles(module, recommendations, profile);
        case OriginModuleEnum.Commissioning:
          return generateTiles(module, commissionings, profile);
        case OriginModuleEnum.OutOfCommissioning:
          return generateTiles(module, decommissionings, profile);
        case OriginModuleEnum.SafetyInstructionCard:
          return generateTiles(module, safetyInstructionCards, profile);
        case OriginModuleEnum.InstructionCard:
          return generateTiles(module, instructionCards, profile);
        case OriginModuleEnum.IncidentManagement:
          return generateTiles(module, incidents, profile);
        default: break;
      }
      return [];
    };

    const tiles = [
      OriginModuleEnum.Task,
      OriginModuleEnum.PeriodicControl,
      OriginModuleEnum.PeriodicMaintenance,
      OriginModuleEnum.GlobalPreventionPlan,
      OriginModuleEnum.NotificationDefect,
      OriginModuleEnum.Labourmeans,
      OriginModuleEnum.EquipmentInstallation,
      OriginModuleEnum.HazardousSubstance,
      OriginModuleEnum.Pbm,
      OriginModuleEnum.IntangibleAsset,
      OriginModuleEnum.Document,
      OriginModuleEnum.RiskAnalysis,
      OriginModuleEnum.Consultancy,
      OriginModuleEnum.Commissioning,
      OriginModuleEnum.OutOfCommissioning,
      OriginModuleEnum.SafetyInstructionCard,
      OriginModuleEnum.InstructionCard,
      OriginModuleEnum.IncidentManagement,
    ]
      .flatMap(module => {
        const metadata = arxs.moduleMetadataRegistry.get(module);
        const items = getItemsForModule(module, arxs.Identity.profile);
        const count = items.flatMap(x => x.items.map(i => i.id)).distinct().length;

        if (metadata && items) {
          return [{
            key: `tile-${module}`,
            module,
            moduleIcon: metadata.icon,
            moduleTitle: metadata.title,
            items,
            count
          }];
        }
        return [];
      })
      .filter(x => x.count > 0);

    setTiles(tiles);
  }, [
    tasks,
    taskRequests,
    labourmeans,
    equipments,
    hazardousSubstances,
    inspections,
    maintenances,
    multiYearPlans,
    intangibleAssets,
    protectionEquipments,
    documents,
    riskAnalysises,
    recommendations,
    commissionings,
    decommissionings,
    safetyInstructionCards,
    instructionCards,
    planningMoments,
    incidents,
  ]);

  return (
    <div className="notification-tiles">
      {tiles.map(tile => <NotificationTile key={tile.key} data={tile} history={props.history} />)}
    </div>
  );
}