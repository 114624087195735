import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import arxs from "infra/arxs";
import { OriginModuleEnum, RelationshipType } from "infra/api/contracts";

import PlanningList from "./PlanningList";
import PlanningFilters from "./PlanningFilters";
import Badge from "components/controls/Badge";
import Toaster from "components/util/Toaster";
import { Spinner } from "components/animations/Spinner";

import "./PlanningDashboard.scss";
import GlobalContext from "infra/GlobalContext";

const hasReachedNotificationDate = (card, planningMoment, currentDate) => {
  if (!card.notificationDate || !card.targetDate) {
    return true;
  }

  const targetDate = new Date(card.targetDate);
  const notificationDate = new Date(card.notificationDate);

  if (planningMoment) {
    const offset = targetDate.getTime() - notificationDate.getTime();
    const planningDate = new Date(new Date(planningMoment.start).getTime() - offset);
    return currentDate >= planningDate;
  }
  return currentDate >= notificationDate;
};

const getTaskData = (predicate) => {
  return (lookups, securityContext, arxs) => {
    const planningMoments = lookups.planningMoments || [];
    const filter = predicate(
      new PlanningFilters(
        planningMoments,
        securityContext,
        arxs.Identity.profile,
        RelationshipType.Assignee
      )
    );
    const planningMomentsMap = lookups.planningMoments.toDictionary(
      (x) => x.id
    );
    const currentDate = new Date();
    return (lookups.tasks || [])
      .concat(lookups.maintenances || [])
      .concat(lookups.inspections || [])
      .concat(lookups.riskAnalysises || [])
      .filter(filter)
      .flatMap((x) => {
        const planningMoment = x.inboundLinks
          .filter(
            (l) =>
              l.module === OriginModuleEnum.Planning && l.isDeleted !== true
          )
          .map((l) => planningMomentsMap[l.id])
          .filter((l) => l)[0];

        if (!hasReachedNotificationDate(x, planningMoment, currentDate)) {
          return [];
        }
        
        return [{
          ...x,
          start: planningMoment && planningMoment.start,
          end: planningMoment && planningMoment.end,
        }];
      })
      .orderBy((x) => x.start || x.targetDate);
  };
};

const tabDefinitions = [
  {
    label: "planning.filters.overdue_tasks",
    className: "pink",
    getter: getTaskData((x) => x.expiredTaskFilter()),
  },
  {
    label: "planning.filters.overdue_planning",
    className: "pink",
    getter: getTaskData((x) => x.expiredPlanningFilter()),
  },
  {
    label: "planning.filters.plan_today",
    getter: getTaskData((x) => x.plannedfilter(0, 1)),
  },
  {
    label: "planning.filters.plan_1d_7d",
    getter: getTaskData((x) => x.plannedfilter(1, 8)),
  },
  {
    label: "planning.filters.plan_7d_14d",
    getter: getTaskData((x) => x.plannedfilter(8, 15)),
  },
  {
    label: "planning.filters.plan_14d",
    getter: getTaskData((x) => x.plannedfilter(15)),
  },
  {
    label: "planning.filters.no_target_date_and_planning",
    getter: getTaskData((x) => x.noTargetDateAndPlanningfilter()),
  },
];

export default function PlanningDashboard(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [planningMoments, setPlanningMoments] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [subscriptions, setSubscriptions] = useState();
  const [tabs, setTabs] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [riskAnalysises, setRiskAnalysises] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [reportsByAliasMap, setReportsByAliasMap] = useState({});

  useEffect(() => {
    const state = {
      planningMoments,
      tasks,
      maintenances,
      inspections,
      riskAnalysises
    };

    const securityContext = arxs.securityContext.buildForContext();
    const tabs = tabDefinitions.map((x) => {
      const data = x.getter(state, securityContext, arxs);
      return {
        label: x.label,
        className: x.className,
        data,
        count: data.length,
        render: <PlanningList data={data} />,
      };
    });
    setTabs(tabs);

    const firstTabIndexWithCards = tabs.findIndex((x) => x.data.length > 0);
    const hasCurrentTabCards = ((tabs[selectedTabIndex] || {}).data || []).length > 0;

    const cardIndexToSet = hasCurrentTabCards ? selectedTabIndex : firstTabIndexWithCards;

    if (firstTabIndexWithCards !== -1) {
      setSelectedTabIndex(cardIndexToSet);
    }

  }, [planningMoments, tasks, maintenances, inspections, riskAnalysises]);

  useEffect(() => {
    if (!subscriptions) {
      setSubscriptions({
        lookups: arxs.Api.lookups.subscribe(
          { planningMoments: {}, tasks: {}, maintenances: {}, inspections: {}, reportsByAliasMap: {}, riskAnalysises: {} },
          (lookups) => {
            if (lookups.planningMoments) {
              setIsLoaded(true);
              setPlanningMoments(
                lookups.planningMoments
                  .filter((x) => x.subject)
                  .map((x) => ({
                    ...x,
                    start: new Date(x.start),
                    end: new Date(x.end),
                  }))
              );
            }
            if (lookups.tasks) {
              setTasks(
                lookups.tasks.map((x) => ({
                  ...x,
                  targetDate: x.targetDate ? new Date(x.targetDate) : null,
                  notificationDate: x.notificationDate
                    ? new Date(x.notificationDate)
                    : null,
                }))
              );
            }
            if (lookups.maintenances) {
              setMaintenances(
                lookups.maintenances.map((x) => ({
                  ...x,
                  targetDate: x.targetDate ? new Date(x.targetDate) : null,
                  notificationDate: x.notificationDate
                    ? new Date(x.notificationDate)
                    : null,
                }))
              );
            }
            if (lookups.inspections) {
              setInspections(
                lookups.inspections.map((x) => ({
                  ...x,
                  targetDate: x.targetDate ? new Date(x.targetDate) : null,
                  notificationDate: x.notificationDate
                    ? new Date(x.notificationDate)
                    : null,
                }))
              );
            }
            if (lookups.riskAnalysises) {
              setRiskAnalysises(
                lookups.riskAnalysises.map((x) => ({
                  ...x,
                  targetDate: x.targetDate ? new Date(x.targetDate) : null,
                  notificationDate: x.notificationDate
                    ? new Date(x.notificationDate)
                    : null,
                }))
              );
            }
            if (lookups.reportsByAliasMap) {
              setReportsByAliasMap(lookups.reportsByAliasMap);
            }
          }
        ),
      });
    }

    return () => {
      if (subscriptions) {
        subscriptions.lookups.dispose();
      }
    };
  }, [subscriptions]);

  const printWorkOrders = () => {
    const tabData = ((tabs.filter(x => x.count > 0)[selectedTabIndex] || {}).data || []);

    const taskRefs = tabData
      .filter((x) => OriginModuleEnum.Task === x.module)
      .map((x) => ({ module: x.module, objectId: x.id }));
    const maintenanceRefs = tabData
      .filter((x) => OriginModuleEnum.PeriodicMaintenance === x.module)
      .map((x) => ({ module: x.module, objectId: x.id }));
    const inspectionRefs = tabData
      .filter((x) => OriginModuleEnum.PeriodicControl === x.module)
      .map((x) => ({ module: x.module, objectId: x.id }));

    if (tabData.length > 0) {
      const requests = [
        {
          template: reportsByAliasMap["TaskWorkOrder"][0],
          filters: [{ refs: taskRefs }]
        },
        {
          template: reportsByAliasMap["MaintenanceWorkOrder"][0],
          filters: [{ refs: maintenanceRefs }]
        },
        {
          template: reportsByAliasMap["InspectionWorkOrder"][0],
          filters: [{ refs: inspectionRefs }]
        },

      ];
      arxs.ReportClient.reporting().generatePDF(
        requests.filter(x => x.filters.any(filter => filter.refs.length > 0))
      );
      Toaster.notify(arxs.t("report.requested"));
    }
  };

  const hasItems = tabs.any((x) => x.count > 0);

  const hasWorkOrderItems = tabs
    .filter(x => x.count > 0)
    .flatMap(x => x.data)
    .any(x => [OriginModuleEnum.Task, OriginModuleEnum.PeriodicMaintenance, OriginModuleEnum.PeriodicControl].contains(x.module));

  const renderTabs = (context) => {
    return (
      <Tabs
        className="planning-dashboard-tabs"
        selectedIndex={selectedTabIndex}
        onSelect={(index) => setSelectedTabIndex(index)}
      >
        <TabList>
          {isLoaded &&
            tabs
              .filter((tab) => tab.count > 0)
              .map((tab, i) => (
                <Tab key={`tab-${i}`}>
                  {arxs.t(tab.label)}{" "}
                  <Badge className={tab.className || ""}>{tab.count}</Badge>
                </Tab>
              ))}
          {isLoaded && !hasItems && (
            <Tab key="tab-empty">{arxs.t("planning.planning_is_empty")}</Tab>
          )}
          {!isLoaded && (
            <Tab key="tab-empty">{arxs.t("planning.planning_is_loading")}</Tab>
          )}
        </TabList>
        {!context.platform.isPhone && isLoaded && hasItems && hasWorkOrderItems && (
          <div onClick={printWorkOrders} className="planning-dashboard-report">
            <i className="fas fa-file-pdf"></i>
          </div>
        )}
        {isLoaded &&
          tabs
            .filter((tab) => tab.count > 0)
            .map((tab, i) => (
              <TabPanel key={`tabpanel-${i}`}>{tab.render}</TabPanel>
            ))}
        {isLoaded && !hasItems && (
          <TabPanel key="tabpanel-empty" className="planning-empty">
            {arxs.t("planning.planning_is_empty")}
          </TabPanel>
        )}
        {!isLoaded && (
          <TabPanel key="tabpanel-loading" className="planning-loading">
            <Spinner />
          </TabPanel>
        )}
      </Tabs>
    );
  };

  return <GlobalContext.Consumer>
    {(context) =>
    <div className="planning-dashboard">
      <div className="planning-dashboard-tabs">{renderTabs(context)}</div>
    </div>}
  </GlobalContext.Consumer>;
}
