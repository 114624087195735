import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import AssetActions from "modules/assetmanagement/AssetActions";
import LabourMeansBoard from "./LabourMeansBoard";
import LabourMeansWizard from "./LabourMeansWizard";
import {
  ObjectDocumentType,
  OriginModuleEnum,
  RelationshipType,
  StatusEnum,
} from "infra/api/contracts";
import AssetCard from "../AssetCard";

const getLabourMeansMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Labourmeans;

  return {
    module,
    title: arxs.t("modules.labourmeans"),
    icon: "far fa-wrench",
    path: "/labourmeans",
    base: {
      name: "LabourMeans",
      route: "/labourmeans",
      getResource: () => arxs.Api.getResource("labourmeans"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "LabourMeans.Write",
      readAction: "LabourMeans.Read",
    },
    settings: {
      name: "LabourmeanSettings",
      route: "/labourmeans/settings",
      getImportResource: () => arxs.ApiClient.assets.labourmeansSettings,
      readAction: "LabourMeans.Settings.Write",
      writeAction: "LabourMeans.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "description" },
          { name: "internalNumber" },
          { name: "kind" },
          { name: "type", parent: "kind" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
      {
        title: arxs.t("details_pane.details"),
        fields: [
          { name: "brand" },
          { name: "model" },
          { name: "purchaseDate" },
          { name: "purchasePrice" },
          { name: "constructionYear" },
          { name: "serialNumber" },
          { name: "depreciationPeriod" },
        ],
      },
      {
        title: arxs.t("details_pane.supplier_customer"),
        fields: [{ name: "supplier" }, { name: "contacts" }],
      },
      {
        title: arxs.t("details_pane.energy_supplies"),
        fields: [
          { name: "power" },
          { name: "energySupplies", code: "EnergySupplies" },
          {name: "co2"}
        ],
      },
      {
        title: arxs.t("details_pane.extra_specifications"),
        fields: [
          {
            name: "extraSpecifications",
            children: [{ name: "name" }, { name: "description" }],
          },
          {
            name: "parts",
            children: [{ name: "name" }, { name: "warrantyPeriod" }],
          },
        ],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.Manual,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      StatusEnum.InTreatment,
      StatusEnum.ToCommission1,
      StatusEnum.ToCommission2,
      StatusEnum.InService,
      StatusEnum.Expired,
      StatusEnum.OutOfService,
    ],
    actions: AssetActions.getActions(module),
    wizard: {
      wizardClass: LabourMeansWizard,
      steps: [
        {
          title: arxs.t("details_pane.location"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                  ],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  LabourMeansWizard.setScopeFields(stateProxy, "context").then(
                    () => LabourMeansWizard.applyProcessFlow(stateProxy, "context")
                  );
                },
                onLoad: (stateProxy) => {
                  LabourMeansWizard.getContext(stateProxy);
                },
                title: arxs.t("field.location"),
              },
              { name: "geoLocation" },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = [
              "legalStructure",
              "branch",
              "building",
              "location",
            ].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "type",
                parent: "kind",
                props: { code: "LabourMeans.Kind" },
                type: "codeelementlist",
                onChange: (stateProxy) =>
                  LabourMeansWizard.applyProcessFlow(stateProxy, "context")
              },
            ],
            [{ name: "brand" }, { name: "model" }],
            [
              { name: "internalNumber" },
            ],
            [{ name: "description" }],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [{ name: "purchaseDate" }, { name: "purchasePrice" }],
            [
              { name: "constructionYear" },
              {
                name: "depreciationPeriod",
                unit: arxs.t("unit.month").toLowerCase(),
              },
            ],
            [
              { name: "power", unit: arxs.t("unit.watt") },
              { name: "energySupplies", code: "EnergySupply" },
            ],
            [{ name: "serialNumber" }, {name: "co2", unit: arxs.t("unit.kg")}],
            [
              {
                name: "recommendation",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Consultancy],
                  singleSelection: true,
                },
                title: arxs.t("field.recommendation"),
                onChange: (stateProxy) => {
                  LabourMeansWizard.copyRecommendationData(stateProxy);
                },
              },
            ],
            [
              {
                name: "supplier",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.supplier"),
              },
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  setFilterOnField: "supplier",
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.contact"),
              },
            ],
            [
              {
                name: "parts",
                type: "itemlist",
                children: [
                  { name: "name", title: arxs.t(`field.description`) },
                  {
                    name: "warrantyPeriod",
                    unit: arxs.t("unit.month").toLowerCase(),
                  },
                ],
              },
            ],
            [
              {
                name: "extraSpecifications",
                type: "itemlist",
                children: [
                  { name: "name", code: "LabourMeans.ExtraSpecification" },
                  { name: "description", multiLine: false },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.expiration"),
          fields: [
            [
              { name: "expirationDate" },
              { name: "expirationNotificationDate" },
            ],
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        recommendationMap: {}
      },
      fieldsWithIdsForDuplication: ["extraSpecifications", "parts"],
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        labourmeans: [],
        tagMap: {},
        supplierMap: {},
        employeeMap: {},
        contactMap: {},
        recommendationMap: {},
        combinedInstallationMap: {},
        periodicalMap: {}
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "geoLocation" },
        { name: "kind" },
        { name: "type" },
        { name: "description" },
        { name: "brand" },
        { name: "model" },
        { name: "serialNumber" },
        { name: "internalNumber" },
        { name: "recommendation" },
        { name: "power" },
        { name: "energySupplyNames" },
        { name: "co2" },
        { name: "purchaseDate" },
        { name: "constructionYear" },
        { name: "supplier" },
        { name: "purchasePrice" },
        { name: "depreciationPeriod" },
        { name: "remainingDepreciationPeriod" },
        { name: "depreciationPeriodEnd" },
        { name: "expirationDate" },
        { name: "combinedInstallationUniqueNumber" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
        { name: "link" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: LabourMeansBoard,
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
    },
    cardClass: AssetCard
  };
};
export default getLabourMeansMetadata;
