import * as React from 'react';
import { GridColumnMenuItem } from '@progress/kendo-react-grid';
import { GridColumnMenuItemGroup } from '@progress/kendo-react-grid';
import { GridColumnMenuItemContent } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';
import { clone } from '@progress/kendo-react-common';

import arxs from 'infra/arxs';

const filterClearButton = "grid.filterClearButton";
const filterSubmitButton = "grid.filterSubmitButton";
const filterTitle = "grid.filterTitle";
const searchPlaceholder = "grid.searchPlaceholder";
const filterCheckAll = "grid.filterCheckAll";

const isArrayEqual = (firstArray, secondArray) => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }
  return firstArray.every((ex, i) => ex === secondArray[i]);
};

const getNestedValue = (fieldName, dataItem) => {
  var path = (fieldName || '').split('.');
  var data = dataItem;
  path.forEach((p) => {
    data = data ? data[p] : undefined;
  });
  return data;
}

export class CustomGridColumnMenuCheckboxFilter extends React.Component {
  constructor(props) {
    super(props);

    this.compositeFilterIndex = this.getFilterIndex();

    this.state = {
      expanded: props.expanded || false,
      value: '',
      data: this.parseData(this.props.data, this.props.uniqueData) || [],
      dataFromProps: this.parseData(this.props.data, false) || [],
      currentFilter: this.defaultFilter()
    };

    this.messages = arxs.translations.translation.kendo.grid;
  }

  defaultFilter = () => {
    if (this.props.filter) {
      return clone(this.props.filter);
    }
    return { filters: [], logic: 'and' };
  }

  isControlled = () => {
    return this.props.expanded !== undefined;
  }

  parseData = (originalData, isUnique) => {
    var field = this.props.column.field || '';
    var data = originalData.map((item) => getNestedValue(field, item));
    if (isUnique) {
      return data.filter((item, index) => data.indexOf(item) === index);
    }
    return data;
  }

  getFilterIndex = () => {
    var field = this.props.column.field;
    var currentFilter = this.defaultFilter();
    var compositeFilterIndex = currentFilter.filters.findIndex((filter) => {
      return filter.filters && filter.filters.length > 0 && filter.filters[0].field === field;
    });
    return compositeFilterIndex;
  }

  onFilterExpand = () => {
    var isControlled = this.isControlled();
    var nextValue = !(isControlled ? this.props.expanded : this.state.expanded);
    if (this.props.onExpandChange) {
      this.props.onExpandChange(nextValue);
    }
    if (!isControlled) {
      this.setState({
        expanded: nextValue
      });
    }
  }

  handleSearchChange = (e) => {
    var filterExpression = {
      logic: 'and',
      filters: [
        { field: this.props.column.field, operator: 'startswith', value: e.target.value, ignoreCase: true }
      ]
    };
    this.setState({
      value: e.target.value,
      data: this.parseData(filterBy(this.props.data || [], filterExpression), this.props.uniqueData)
    });
  }

  clear = (e) => {
    e.preventDefault();
    if (!this.props.onFilterChange) {
      return;
    }
    var updatedFilter = this.state.currentFilter || null;
    if (updatedFilter !== null && updatedFilter.filters.length > 0) {
      if (this.compositeFilterIndex >= 0) {
        updatedFilter.filters.splice(this.compositeFilterIndex, 1);
      }
      this.props.onFilterChange(updatedFilter, e);
    }
    else {
      this.props.onFilterChange(null, e);
    }
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  }

  submit = (e) => {
    e.preventDefault();
    if (!this.props.onFilterChange) {
      return;
    }
    var updatedFilter = this.state.currentFilter || null;
    this.props.onFilterChange(updatedFilter, e);
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  }

  handleCheckBoxChange = (e, value) => {
    var field = this.props.column.field || '';
    var newFilter = { ...this.state.currentFilter };
    var filters = this.state.currentFilter.filters.slice() || [];
    var fieldFilters = [];

    if (this.compositeFilterIndex !== -1 && newFilter.filters[this.compositeFilterIndex].filters && value !== 'all') {
      fieldFilters = newFilter.filters[this.compositeFilterIndex].filters;
    }
    if (e.value && value === 'all') {
      this.state.data.forEach((item) => {
        fieldFilters.push({ field: field, operator: this.props.customOperators.inarray, value: item });
      });
    }
    else if (e.value) {
      fieldFilters.push({ field: field, operator: this.props.customOperators.inarray, value: value });
    }
    else if (this.state.currentFilter) {
      var index = fieldFilters.findIndex((filter) => { return filter.value === value; });
      fieldFilters.splice(index, 1);
    }
    newFilter.logic = 'and';
    if (this.compositeFilterIndex !== -1) {
      filters[this.compositeFilterIndex] = {
        logic: 'or',
        filters: fieldFilters
      };
    }
    else {
      filters.push({
        logic: 'or',
        filters: fieldFilters
      });
    }
    if ((!e.value && value === 'all') || (fieldFilters.length === 0)) {
      filters.splice(this.compositeFilterIndex, 1);
    }
    newFilter.filters = filters;
    this.setState({ currentFilter: newFilter });
  }

  isAllSelected = () => {
    var isAllChecked = false;
    if (this.state.currentFilter) {
      var filters_1 = this.state.currentFilter.filters.slice();
      if (this.compositeFilterIndex === -1) {
        return false;
      }
      isAllChecked = this.state.data.every((item) => {
        if (this.compositeFilterIndex !== -1 && filters_1[this.compositeFilterIndex].filters) {
          var index = filters_1[this.compositeFilterIndex].filters.findIndex((filter) => filter.value === item);
          return index >= 0;
        }
        return false;
      });
      return isAllChecked;
    }
    return isAllChecked;
  }

  componentDidUpdate() {
    var field = this.props.column.field || '';
    var data = this.props.data.map((item) => getNestedValue(field, item));
    if (!isArrayEqual(data, this.state.dataFromProps)) {
      this.setState({
        data: data,
        dataFromProps: data
      });
    }
  }

  render() {
    const column = this.props.column;
    if (!column || !column.field) {
      return React.createElement("div", null);
    }
    const localizationService = provideLocalizationService(this);
    const expandState = this.isControlled() ? this.props.expanded : this.state.expanded;
    const filterValues = [];
    if (this.state.currentFilter) {
      const currentFilter = this.state.currentFilter.filters.slice();
      this.compositeFilterIndex = currentFilter.findIndex((filter) => {
        if (filter.filters && filter.filters.length > 0) {
          return filter.filters[0].field === column.field;
        }
        return false;
      });
      if (this.compositeFilterIndex !== -1 && currentFilter[this.compositeFilterIndex].filters.length > 0) {
        currentFilter[this.compositeFilterIndex].filters.forEach((filterItem) => {
          if (filterItem.field === this.props.column.field) {
            filterValues.push(filterItem.value);
          }
        });
      }
    }

    const searchBox = this.props.searchBox ?
      React.createElement(this.props.searchBox, { value: this.state.value, onChange: this.handleSearchChange }) :
      (React.createElement("div", { className: "k-textbox k-space-right" },
        React.createElement("input", { placeholder: localizationService.toLanguageString(searchPlaceholder, this.messages[searchPlaceholder]), value: this.state.value, onChange: this.handleSearchChange }),
        React.createElement("span", { className: "k-icon k-i-zoom" })));
    const uniqueFilterValues = filterValues.filter((item, index) => filterValues.indexOf(item) === index);
    const getLabel = this.props.getLabel || ((item) => String(item));
    return (React.createElement(GridColumnMenuItemGroup, null,
      React.createElement(GridColumnMenuItem, { title: localizationService.toLanguageString(filterTitle, this.messages[filterTitle]), iconClass: 'k-i-filter', onClick: this.onFilterExpand }),
      React.createElement(GridColumnMenuItemContent, { show: !!expandState },
        React.createElement("div", { className: 'kendo-grid-filter-menu-container' },
          React.createElement("form", { className: 'k-filter-menu k-group k-reset k-state-border-up', onSubmit: this.submit, onReset: this.clear },
            React.createElement("div", { className: 'k-filter-menu-container' },
              searchBox,
              React.createElement("ul", { className: "k-reset k-multicheck-wrap" },
                React.createElement("li", { className: "k-item" },
                  React.createElement(Checkbox, { label: localizationService.toLanguageString(filterCheckAll, this.messages[filterCheckAll]), onChange: (e) => this.handleCheckBoxChange(e, 'all'), checked: this.isAllSelected() })),
                this.state.data.map((item, index) => {
                  return (React.createElement("li", { className: "k-item", key: index },
                    React.createElement(Checkbox, { label: getLabel(item), onChange: (e) => this.handleCheckBoxChange(e, item), checked: uniqueFilterValues.includes(item) })));
                })),
              React.createElement("div", { className: 'k-actions k-actions-stretched' },
                React.createElement("button", { className: 'k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md' }, localizationService.toLanguageString(filterSubmitButton, this.messages[filterSubmitButton])),
                React.createElement("button", { className: 'k-button k-button-md k-button-solid k-button-solid-base k-rounded-md', type: 'reset' }, localizationService.toLanguageString(filterClearButton, this.messages[filterClearButton]))
              )
              )
            )
          )
        )
      )
    );
  }
}
registerForLocalization(CustomGridColumnMenuCheckboxFilter);
