import React from 'react';
import { GridColumnMenuFilter, GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';
import { CustomGridColumnMenuCheckboxFilter } from './CustomGridColumnMenuCheckboxFilter';

export default class GridColumnMenu extends React.Component {
  state = { data: [] }

  componentDidMount() {
    this.refreshPossibleValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items !== this.props.items) {
      this.refreshPossibleValues();
    }
  }

  enumerateListValues = (item) => {
    if (Array.isArray(item)) {
      return item.map(x => x);
    }
    if (!item) {
      return [];
    }
    return [item];
  }

  refreshPossibleValues = () => {
    const { field } = this.props.column;
    const possibleValues = this.props.items
      .map(item => item[field])
      .flatMap(this.enumerateListValues)
      .distinct()
      .orderBy(x => x)
      .map(x => ({ [field]: x }));
    this.setState({ data: possibleValues });
  }

  render() {
    const checkboxFilter = this.props.checkboxFilter && this.state.data.length < 100;
    return (
      <div>
        {checkboxFilter && <CustomGridColumnMenuCheckboxFilter {...this.props} data={this.state.data} expanded={true} searchBox={() => null} />}
        {!checkboxFilter && <GridColumnMenuFilter {...this.props} expanded={true} />}
      </div>
    );
  }
}