import React, { useState } from "react";

import Switch from "components/controls/Switch";
import NumericEditor from "components/controls/NumericEditor";
import AutoCompleteTextBox from "components/controls/AutoCompleteTextBox";
import NominatimSearch from "components/layouts/geo/NominatimSearch";
import MultiSelect from "components/controls/MultiSelect";
import DropDown from "components/controls/DropDown";
import { RichTextEditor } from "components/controls/RichTextEditor";
import DateRange from "components/controls/dateRange/DateRange";

import "./KendoControlTester.scss";
import FieldSet from "components/controls/FieldSet";
import Calendar from "components/controls/Calendar";
import TreeList from "components/controls/treelist/TreeList";

const _multiSelectItems = [
    {
        id: 1,
        name: "Optie 1",
    },
    {
        id: 2,
        name: "Optie met icoon",
        icon: "far fa-dungeon"
    },
];
const _dropDownItems = [
    {
        id: 1,
        name: "Optie 1",
    },
    {
        id: 2,
        name: "Optie met icoon",
        icon: "far fa-dungeon"
    },
    {
        id: 3,
        name: "Optie met afbeelding",
        getImage: () => ""
    }
];
const _treeItems = [
    {
        id: 1,
        text: "Niveau 1",
        icon: "fa fa-dungeon",
        items: [
            {
                id: 2,
                text: "Binnenste niveau 1-1",
                icon: "fa fa-dungeon",
            }
        ]
    },
    {
        id: 3,
        text: "Niveau 2",
        icon: "fa fa-dungeon",
        items: [
            {
                id: 4,
                text: "Binnenste niveau 2-1",
                icon: "fa fa-dungeon",
            },
            {
                id: 5,
                text: "Binnenste niveau 2-1",
                icon: "fa fa-dungeon",
                url: "https://google.be/"
            }
        ]
    }
];

export default function KendoControlTester(props) {
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);

    const [value1, setValue1] = useState();
    const [value2, setValue2] = useState();
    const [value3, setValue3] = useState();
    const [value4, setValue4] = useState();

    const [autoComplete1, setAutoComplete1] = useState();
    const [autoComplete2, setAutoComplete2] = useState();

    const [multiSelect1, setMultiSelect1] = useState();
    const [dropDown1, setDropDown1] = useState();

    const [dateRange1, setDateRange1] = useState();

    const [date1, setDate1] = useState({});

    const [date2, setDate2] = useState();

    const [richText1, setRichText1] = useState();

    const richTextField = {
        isLoaded: true,
        getter: () => richText1,
        setter: setRichText1,
    };

    return <div className="diagnostics-kendo">
        <h1>Kendo Componenten</h1>

        <div className="groups">
            <div className="group">
                {
                    // CardActions                          @progress/kendo-react-inputs        Switch
                    // Activities                           @progress/kendo-react-inputs        Switch
                    // UserProfile                          @progress/kendo-react-inputs        Switch
                    // DateRange                            @progress/kendo-react-inputs        Switch
                    // Field                                @progress/kendo-react-inputs        Switch
                }
                <h3>Aan/uit Switch</h3>
                <Switch
                    checked={checked1}
                    onChange={(x) => setChecked1(x)}
                    title={"Verplichte switch"}
                    required />
                <Switch
                    checked={checked2}
                    onChange={(x) => setChecked2(x)}
                    title={"Omgekeerde switch"}
                    reverse />
                <Switch
                    checked={checked3}
                    onChange={(x) => setChecked3(x)}
                    title={"Alleen-lezen switch"}
                    disabled />
            </div>

            <div className="group">
                {
                    // IDS Wizard Periodieken Editor <-> Invullen
                    // Meerjarenplan Wizard Risicowaarde
                    // Meerjarenplan Wizard Geraamde duur / kost
                    // Personeel wizard Functies Opdrachtbreuk
                }
                <h3>Numerieke invoervelden</h3>
                <NumericEditor
                    spinners={true}
                    value={value1}
                    onChange={setValue1}
                    placeholder="Met spinner"
                    />
                <NumericEditor
                    value={value2}
                    onChange={setValue2}
                    min={100}
                    max={200}
                    placeholder="Min 100 Max 200"
                    />
                <NumericEditor
                    value={value3}
                    onChange={setValue3}
                    format={{
                        style: "currency",
                        currency: "EUR",
                        currencyDisplay: "name",
                      }}
                    placeholder="Met EUR formattering"
                    />
                <NumericEditor
                    value={value4}
                    onChange={setValue4}
                    placeholder="Alleen-lezen halve breedte"
                    width="50%"
                    disabled
                    />
            </div>

            <div className="group">
                {
                    // Map-view in ABM Nominatim search
                    // AutoCompleteTextBox                  @progress/kendo-react-dropdowns     AutoComplete
                    // NominatimSearch                      @progress/kendo-react-dropdowns     AutoComplete
                }
                <h3>Autocomplete velden</h3>
                <NominatimSearch />
                <AutoCompleteTextBox
                    items={["Waarde 1", "Waarde 2", "Waarde 3"]}
                    value={autoComplete1}
                    onChange={setAutoComplete1}
                    placeholder={"Waarde 1 ... 3"}
                />
                <AutoCompleteTextBox
                    items={[]}
                    value={autoComplete2}
                    onChange={setAutoComplete2}
                    placeholder={"Alleen-lezen autocomplete"}
                    readOnly
                />
            </div>

            <div className="group">
                {
                    // Map-view in ABM Nominatim search
                    // MultiSelect                          @progress/kendo-react-dropdowns     MultiSelect
                    // DropDown                             @progress/kendo-react-dropdowns     ComboBox
                }
                <h3>MultiSelect/DropDown velden</h3>
                <MultiSelect
                    items={_multiSelectItems}
                    selected={multiSelect1}
                    onChange={setMultiSelect1}
                    placeholder="Allerlei opties"
                />
                <DropDown
                    items={_dropDownItems}
                    selected={dropDown1}
                    onChange={setDropDown1}
                    placeholder="Allerlei opties"
                />
            </div>

            <div className="group">
                <h3>Datumbereik velden</h3>
                <DateRange
                    title={"Van/tot"}
                    value={dateRange1}
                    onChange={setDateRange1}
                    required={true}
                    disabled={false}
                    showTime={true}
                />
            </div>

            <div className="group">
                {
                    // Field                                @progress/kendo-react-dateinputs    TimePicker, DateTimePicker, DatePicker
                    // DateRange                            @progress/kendo-react-dateinputs    TimePicker, DateTimePicker, DatePicker
}
                <h3>Datum/Tijd velden</h3>
                <FieldSet
                    schemaName={"KendoControlTesterDateFields"}
                    rows={[
                        [{
                            name: "incidentTime",
                            props: { showTime: true },
                        }],
                        [{
                            name: "incidentTime",
                            props: { showTime: true, split: true },
                        }],
                        [{
                            name: "targetDate",
                        }],
                        [{
                            name: "planFrom",
                        }]
                    ]}
                    data={date1}
                    setData={setDate1}
                    />
            </div>

            <div className="group">
                {
                    // Planning                             @progress/kendo-react-inputs        Calendar
                }
                <h3>Kalender</h3>
                <Calendar
                    value={date2}
                    onChange={setDate2}
                    />
            </div>

            <div className="group">
                {
                    // RichTextEditor                       @progress/kendo-react-editor        Editor, EditorTools, ProseMirror
                }
                <h3>RichText veld</h3>
                <RichTextEditor
                    field={richTextField}
                    />
            </div>

            <div className="group">
                {
                    // TreeList                             @progress/kendo-react-treeview      TreeView
                    // TreeView                             @progress/kendo-react-treeview      TreeView
                }
                <h3>TreeView</h3>
                <TreeList
                    data={_treeItems}
                    onClick={x => alert(x.id)}
                    onCheckToggle={x => alert(x.id)}
                    idField={"id"}
                    />
            </div>
        </div>
    </div>
}