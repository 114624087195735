import {
  OriginModuleEnum,
  RelationshipType,
  TaskStatus,
  TaskType,
  TaskPriorityEnum,
} from "infra/api/contracts";
import { createWizardPopup } from "components/wizard/WizardPopup";
import arxs from "infra/arxs";

class WizardController {

  buildGetValue = (stateProxy) => {
    const data = stateProxy.getter("data");
    const pristine = stateProxy.getter("pristine");

    return fieldName => data[fieldName] === undefined ? pristine[fieldName] : data[fieldName];
  }

  getSetProcessFlow = (stateProxy, legalStructures, branches) => {
    return new Promise((resolve, reject) => {
      const processFlows = stateProxy.getter("processFlows");

      if (processFlows && processFlows.length > 0) {

        const data = stateProxy.getter("data");
        const pristine = stateProxy.getter("pristine");

        const sort = (data.sort ? data.sort : pristine.sort) || {};
        const kind = (data.kind ? data.kind : pristine.kind) || {};
        const type = (data.type ? data.type : pristine.type) || {};

        const relevantProcessFlows = processFlows
          .filter(
            (pf) =>
              (!legalStructures ||
                !pf.legalStructure ||
                legalStructures.map((ls) => ls.id).includes(pf.legalStructure.id)) &&
              (!branches ||
                !pf.branch ||
                branches.map((b) => b.id).includes(pf.branch.id)) &&
              (!pf.sort || pf.sort.id === sort.id) &&
              (!pf.kind || pf.kind.id === kind.id) &&
              (!pf.type || pf.type.id === type.id)
          )
          .orderByDescending(
            (x) =>
              (x.legalStructure &&
                legalStructures &&
                legalStructures.map((ls) => ls.id).includes(x.legalStructure.id)
                ? 16
                : 0) +
              (x.branch &&
                branches &&
                branches.map((b) => b.id).includes(x.branch.id)
                ? 8
                : 0) +
              (x.sort && x.sort.id === sort.id ? 4 : 0) +
              (x.kind && x.kind.id === kind.id ? 2 : 0) +
              (x.type && x.type.id === type.id ? 1 : 0)
          );

        this.setProcessFlowValues(stateProxy, relevantProcessFlows).then(resolve);
      }
    });
  };

  applyProcessFlow = (stateProxy, fieldName) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");

      let legalStructure = data.legalStructure
        ? data.legalStructure
        : pristine.legalStructure;
      let branch = data.branch ? data.branch : pristine.branch;

      if (!legalStructure) {
        const getValue = this.buildGetValue(stateProxy);
        const ref = getValue(fieldName);

        if (ref) {
          const subject = arxs.Api.lookups.resolveSubject(ref);
          if (subject) {
            legalStructure = subject.legalStructure;
            branch = subject.branch || null;
          }

          switch (ref.module) {
            case OriginModuleEnum.School:
              branch = ref.id;
              break;
            case OriginModuleEnum.SchoolGroup:
              legalStructure = ref.id;
              break;
            default: break;
          }
        }
      }

      const legalStructures = (legalStructure && [legalStructure]) || [];
      const branches = (branch && [branch]) || [];

      this.getSetProcessFlow(stateProxy, legalStructures, branches).then(resolve);
    });
  };

  applyProcessFlowForSubjects = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");

      const subjects = (data.subjects ? data.subjects : pristine.subjects) || [];

      const legalStructures = subjects
        .map(subject => {
          const resolved = arxs.Api.lookups.resolveSubject(subject);
          if (subject.module === OriginModuleEnum.SchoolGroup) {
            return resolved.id ? { id: resolved.id } : resolved.legalStructure;
          }
          return resolved.legalStructure;
        })
        .filter(x => x) 
        .distinct(x => x.id);

      const branches = subjects
        .map(subject => {
          const resolved = arxs.Api.lookups.resolveSubject(subject);
          if (subject.module === OriginModuleEnum.School) {
            return resolved.id ? { id: resolved.id } : resolved.branch;
          }
          return resolved.branch;
        })
        .filter(x => x) 
        .distinct(x => x.id);

      this.getSetProcessFlow(stateProxy, legalStructures, branches).then(resolve);
    });
  };

  applyprocessFlowForMultiple = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");

      const legalStructures = (data.legalStructures
        ? data.legalStructures
        : pristine.legalStructures) || [];
      const branches = (data.branches ? data.branches : pristine.branches) || [];

      this.getSetProcessFlow(stateProxy, legalStructures, branches).then(resolve);
    });
  };

  setProcessFlowValues = (stateProxy, relevantProcessFlows) => {
    return new Promise((resolve, reject) => {
      if (relevantProcessFlows.some((x) => x)) {
        const data = stateProxy.getter("data");
        const pristine = stateProxy.getter("pristine");

        const relationships =
          (data.relationships ? data.relationships : pristine.relationships) ||
          [];
        let newRelationShips = [];
        let newResponsible = data["responsible"] || pristine["responsible"];

        const newResponsibles = relevantProcessFlows.filter(
          (x) => x.relationshipType === RelationshipType.Responsible
        );
        if (newResponsibles.length > 0) {
          const mostRelevantResponsible = newResponsibles[0] || {}; //there can be only one!
          newResponsible = { id: (mostRelevantResponsible.employee || {}).id };
        }

        const relationshipTypes = [
          RelationshipType.CoResponsible,
          RelationshipType.Cc,
          RelationshipType.Assignee,
          RelationshipType.PreventionAdvisor,
        ];

        for (const relationshipType of relationshipTypes) {
          const matchingRecords = relevantProcessFlows.filter(
            (x) => x.relationshipType === relationshipType
          );
          if (matchingRecords.length > 0) {
            newRelationShips = newRelationShips.concat(
              matchingRecords.map((x) => ({
                employee: x.employee,
                type: relationshipType,
                userRole: x.userRole,
              }))
            );
          } else {
            newRelationShips = newRelationShips.concat(
              relationships.filter((x) => x.type === relationshipType)
            );
          }
        }

        stateProxy.setter(
          {
            data: {
              ...data,
              relationships: newRelationShips,
              responsible: newResponsible,
            },
          },
          resolve
        );
      } else {
        resolve();
      }
    });
  };

  onChangePreferredInItemList = (stateProxy, field, item, itemField) => {
    return new Promise((resolve, reject) => {
      if (itemField === "isPreferred") {
        const data = stateProxy.getter("data");
        const pristine = stateProxy.getter("pristine");
        const values = data[field] || pristine[field];
        let changedValues = [];

        if (values.length === 1) {
          changedValues = values.map((x) => ({ ...x, isPreferred: true }));
        } else {
          const isPreferred = item.isPreferred;

          if (isPreferred) {
            changedValues = values.map((x) => ({
              ...x,
              isPreferred: x === item ? isPreferred : !isPreferred,
            }));
          } else {
            changedValues = values.map((x) => ({
              ...x,
              isPreferred: isPreferred,
            }));
            changedValues[0].isPreferred = !isPreferred;
          }
        }

        const newData = {
          ...data,
          [field]: changedValues,
        };
        stateProxy.setter({ data: newData }, resolve);
      } else {
        resolve();
      }
    });
  };

  setFirstItemInItemListAsPreferred = (stateProxy, field) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");
      const values = data[field] || pristine[field];
      if (values.length <= 1) {
        let changedValues = [];
        changedValues = values.map((x) => ({ ...x, isPreferred: true }));

        const newData = {
          ...data,
          [field]: changedValues,
        };
        stateProxy.setter({ data: newData }, resolve);
      } else {
        resolve();
      }
    });
  };

  onLookupsChange(stateProxy) {
    return;
  }

  setInitialValues = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const metadata = stateProxy.getter("metadata");
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");

      const getValue = this.buildGetValue(stateProxy);
      const getFieldMeta = (fieldName) =>
        metadata.wizard.steps.flatMap((step) =>
          step.fields.flatMap((row) => row)
            .filter((field) => field && field.name === fieldName))[0];
      const hasFieldInMeta = (fieldName) => !!getFieldMeta(fieldName);

      const context = {};

      const getCurrentUser = () => {
        const { profile } = arxs.Identity;
        return { id: profile.id, module: OriginModuleEnum.Employee };
      };

      if (hasFieldInMeta("owner")) {
        if (!getValue("owner")) {
          context["owner"] = getCurrentUser();
        }
      }

      if (hasFieldInMeta("notifier")) {
        if (!getValue("notifier")) {
          context["notifier"] = getCurrentUser();
        }
      }

      if (hasFieldInMeta("responsible")) {
        if (!getValue("responsible")) {
          context["responsible"] = getCurrentUser();
        }
      }

      if (hasFieldInMeta("startAt")) {
        if (!getValue["startAt"]) {
          context["startAt"] = new Date();
        }
      }
      // if (hasFieldInMeta("needsSignature")) {
      //   if (!getValue("needsSignature")) {
      //     context["needsSignature"] = true;
      //   }
      // }

      const subjectMeta = getFieldMeta("subject");
      if (subjectMeta) {
        const assignments = arxs.Identity.profile.assignments;
        const uniqueLegalStructures = assignments
          .map((x) => x.legalStructure.id)
          .distinct((x) => x);
        const uniqueBranches = assignments
          .flatMap((x) => x.branches)
          .map((x) => x.id)
          .distinct((x) => x);

        const subject = getValue("subject");
        if (subject) {
          context["subject"] = subject;
          const subjectData = arxs.Api.lookups.resolveSubject(subject);
          context["legalStructure"] = subjectData.legalStructure;
          context["branch"] = subjectData.branch;
          context["building"] = subjectData.building;
          context["location"] = subjectData.location;
        } else if (!(subjectMeta.props || {}).disableInitialValue) {
          if (!pristine.legalStructure) {
            if (uniqueLegalStructures.length === 1) {
              context["legalStructure"] = { id: uniqueLegalStructures[0] };

              if (uniqueBranches.length === 1) {
                context["branch"] = { id: uniqueBranches[0] };
                context["subject"] = {
                  module: OriginModuleEnum.School,
                  id: uniqueBranches[0],
                };
              } else {
                context["subject"] = {
                  module: OriginModuleEnum.SchoolGroup,
                  id: uniqueLegalStructures[0],
                };
              }
            }
          }
        }
      }

      if (hasFieldInMeta("creationDate")) {
        context["creationDate"] = new Date();
      }

      stateProxy.setter({ data: { ...data, ...context } }, resolve);
    });
  };

  setScopeFields = (stateProxy, fieldName) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      const getValue = this.buildGetValue(stateProxy);
      const ref = getValue(fieldName);

      if (ref) {
        const subject = arxs.Api.lookups.resolveSubject(ref);
        if (subject) {
          let newData = {
            ...data,
            legalStructure: subject.legalStructure,
            branch: subject.branch || null,
            building: subject.building || null,
            location: subject.location || null,
          };

          switch (ref.module) {
            case OriginModuleEnum.Room:
              newData = { ...newData, location: { id: ref.id } };
              break;
            case OriginModuleEnum.Building:
              newData = { ...newData, building: { id: ref.id } };
              break;
            case OriginModuleEnum.School:
              newData = { ...newData, branch: { id: ref.id } };
              break;
            case OriginModuleEnum.SchoolGroup:
              newData = { ...newData, legalStructure: { id: ref.id } };
              break;
            default: break;
          }

          stateProxy.setter({ data: newData }, resolve);
        }
      } else {
        let newData = {
          ...data,
          legalStructure: null,
          branch: null,
          building: null,
          location: null,
        };
        stateProxy.setter({ data: newData }, resolve);
      }
    });
  };

  getContext = (stateProxy) => {
    // context is een berekend veld ter vervanging van de location->building->branch->legalStructure velden
    // Bij edit/copy functionaliteit wordt in functie van location->building->branch->legalStructure context gezet
    // Van zodra context wordt aangepast moeten location->building->branch->legalStructure ook worden aangepast
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const getValue = this.buildGetValue(stateProxy);

      let ref;

      if (getValue("location")) {
        ref = { module: OriginModuleEnum.Room, ...getValue("location") };
      } else if (getValue("building")) {
        ref = { module: OriginModuleEnum.Building, ...getValue("building") };
      } else if (getValue("branch")) {
        ref = { module: OriginModuleEnum.School, ...getValue("branch") };
      } else if (getValue("legalStructure")) {
        ref = {
          module: OriginModuleEnum.SchoolGroup,
          ...getValue("legalStructure"),
        };
      }

      if (ref) {
        const context = arxs.Api.lookups.resolveSubject(ref);

        if (context && Object.keys(context).some((x) => x)) {
          const newData = { ...data, context: context };
          stateProxy.setter({ data: newData }, resolve);
        }
      } else {
        resolve();
      }
    });
  };

  getCodeElementValue = (ref, codeElementsById) => {
    if (!ref || !codeElementsById) return "";
    return this.getLookupValue(codeElementsById, ref);
  };

  getLookupValue = (lookup, ref) => {
    const match = lookup && ref && lookup[ref.id];
    return (match && match.name) || (ref && ref.name) || "";
  };

  doesFieldNeedsValidation = (stateProxy, fieldName) => {
    const steps = stateProxy.getter("steps");

    for (const step of steps) {
      //is it in a readOnly step?
      if (step.readOnly) {
        for (const fields of step.fields) {
          if (fields.some(x => x.name === fieldName)) {
            return false;
          }
        }
      } else {
        //is it actually in a step?
        for (const fields of step.fields) {
          if (fields.some(x => x.name === fieldName)) {
            return true;
          }
        }
      }
    }
  }

  templatify = (stateProxy, fieldName, context) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      const getValue = this.buildGetValue(stateProxy);

      const ref = getValue(fieldName);

      const templatifyRecord = (item) => {
        if (!item) {
          return item;
        }

        // Deep clone 
        const template = JSON.parse(JSON.stringify(item));
        delete template.id;
        delete template.uniqueNumber;
        delete template.createdAt;
        delete template.numberOfMessages;
        delete template.modifiedAt;
        delete template.actions;
        delete template.previousStatus;
        delete template.isDeleted;
        delete template.isSubscribed;
        delete template.numberOfImages;
        delete template.numberOfDocuments;
        delete template.numberOfAssignees;
        delete template.targetDate;
        template.status = template.status || TaskStatus.Active;
        template.taskType = template.taskType || TaskType.Internal;
        template.priority = template.priority || TaskPriorityEnum.Low;
        return template;
      }

      if (ref && ref.id && ref.module) {
        let item = arxs.Api.lookups.resolveSubject(ref);
        if (item && item.id && item.uniqueNumber) {
          item = templatifyRecord(item);
          item.status = TaskStatus.Active;

          const onClose = () => {
            context.popup.close();
            const newData = { ...data, [fieldName]: item, referenceModule: ref.module };
            stateProxy.setter({ data: newData }, resolve);
          }

          const handleEdit = (value) => {
            item = templatifyRecord(value);
          }

          const wizardPopup = createWizardPopup(
            item.module,
            item,
            false,
            onClose,
            handleEdit
          );

          context.popup.show(wizardPopup);
        }
      } else if (ref) {
        const newData = { ...data, [fieldName]: templatifyRecord(ref), referenceModule: ref.module };
        stateProxy.setter({ data: newData }, resolve);
      } else {
        const newData = { ...data, [fieldName]: null, referenceModule: null };
        stateProxy.setter({ data: newData }, resolve);
      }
    })
  }

  onFieldChange = (stateProxy, fieldName) => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getCommonEntries = (arr1, arr2) => {
    if (arr1.some((x) => x) && arr2.some((x) => x)) {
      return arr1.filter((item) => arr2.includes(item));
    }

    return false;
  };
}
export default WizardController;
