import arxs from "infra/arxs";
import GlobalActions from "modules/GlobalActions";
import { OriginModuleEnum } from "infra/api/contracts";
import Toaster from "components/util/Toaster";

class BranchActions {
  getActions = (module) => {
    let actions = GlobalActions.getActions(module);

    actions = actions.filter((x) => x.name !== "archive");

    actions.push({
      name: "archive",
      module: module,
      icon: arxs.actions.icons["archive"],
      getTitle: () => arxs.actions.titles["archive"],
      onClick: (state) => this.archive(state),
    });

    return actions;
  };

  archive = (state) => {
    const ids = state["ids"] || [state.item.objectId];

    const assetModules = [
      OriginModuleEnum.Labourmeans,
      OriginModuleEnum.Pbm,
      OriginModuleEnum.EquipmentInstallation,
      OriginModuleEnum.HazardousSubstance,
      OriginModuleEnum.IntangibleAsset,
      OriginModuleEnum.Room,
      OriginModuleEnum.Building
    ];

    const cards = state.cards || [];

    const cardsWithInboundAssetLinks = cards.filter((x) =>
      x.inboundLinks.some((y) => assetModules.includes(y.module))
    );

    if (cardsWithInboundAssetLinks.length !== 0) {
      let unarchivableBranches = [];

      const promises = [];

      for (let i = 0; i < cardsWithInboundAssetLinks.length; i++) {
        const cardWithInboundAssetLinks = cardsWithInboundAssetLinks[0];
        const inboundAssetLinks = cardWithInboundAssetLinks.inboundLinks.filter(
          (x) => assetModules.includes(x.module)
        );

        for (let j = 0; j < inboundAssetLinks.length; j++) {
          const inboundAssetLink = inboundAssetLinks[j];
          const moduleInScope = inboundAssetLink.module;

          const promise = arxs.moduleMetadataRegistry
            .getResourceByModule(moduleInScope)
            .getById(inboundAssetLink.id)
            .then((x) => {
              if (
                !x.isDeleted &&
                !unarchivableBranches.includes(
                  cardWithInboundAssetLinks.uniqueNumber
                )
              ) {
                unarchivableBranches.push(
                  cardWithInboundAssetLinks.uniqueNumber
                );
              }
            });

          promises.push(promise);
        }
      }

      Promise.all(promises).then(() => {
        if (unarchivableBranches.length != 0) {
          Toaster.error(
            arxs.t("actions.archive_unarchivable_branch", {
              branches: unarchivableBranches.join(", "),
            })
          );
        } else {
          this.doArchive(ids);
        }
      });
    } else {
      this.doArchive(ids);
    }
  };

  doArchive = (ids) => {
    return arxs.moduleMetadataRegistry
      .getResourceByModule(OriginModuleEnum.School)
      .archive(ids)
      .then(() => Toaster.success(arxs.t("actions.archive_confirmation")));
  };
}
export default new BranchActions();
