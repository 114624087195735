import arxs from "infra/arxs";
import { ObjectDocumentType, OriginModuleEnum } from 'infra/api/contracts';
import GlobalActions from "modules/GlobalActions";
import Toaster from "components/util/Toaster";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import { createSignaturePopup } from "components/controls/signature/SignaturePopup";
import { createReportPreviewPopup } from "components/controls/ReportPreviewPopup";

class SafetyInstructionCardActions {
  getActions = (module) => {
    return GlobalActions.getActions(module)
      .concat([
        {
          name: "finalize",
          module: module,
          icon: arxs.actions.icons["finalize"],
          getTitle: () => arxs.actions.titles["finalize"],
          onClick: (state) => this.finalize(state),
        },
        {
          name: "sign",
          module: module,
          icon: arxs.actions.icons["sign"],
          getTitle: () => arxs.actions.titles["sign"],
          onClick: (state) => this.sign(state),
        },
        {
          name: "set_inprocess",
          module: module,
          icon: arxs.actions.icons["set_inprocess"],
          getTitle: () => arxs.actions.titles["set_inprocess"],
          onClick: (state) => this.setInProcess(state),
        },
        {
          name: "deactivate",
          module: module,
          icon: arxs.actions.icons["deactivate"],
          getTitle: () => arxs.actions.titles["deactivate"],
          onClick: (state) => this.deactivate(state),
        },
      ]);
  };

  finalize = (state) => {
    const { cards, context } = state;
    const onConfirm = (ids) => {
      arxs.ApiClient.safety.safetyInstructionCard
        .finalize(ids)
        .then(() => {
          Toaster.success(arxs.t("actions.safety_instruction_card.finalize_confirmation"));
        });

      context.popup.close();
      context.popup.close();
    }
    return new Promise((resolve, reject) => {
      if (cards) {
        const subjects = cards
          .map(x => ({ id: x.id, module: OriginModuleEnum.SafetyInstructionCard }))
          .map(x => arxs.Api.lookups.resolveSubject(x));

        const attachmentToStoredFileIdMap = subjects
          .filter(x => x.attachmentInfo)
          .flatMap(x => x.attachmentInfo.storedFiles || [])
          .toDictionary(x => x.id, x => x.storedFileId);

        const subjectToAttachmentMap = subjects
          .filter(x => x.attachmentInfo)
          .toDictionary(x => x.id, x => (x.attachmentInfo.attachments || [])
            .filter(a => a.type === ObjectDocumentType.MainDocument)
            .flatMap(a => a.value || [])
            .filter(v => !v.isDeleted)
            .map(v => v.id)[0]
          );

        const hasReport = (subject) => {
          return subject.formDefinition && (subject.formDefinition.controls.some(x => x) || subject.formDefinition.link);
        };

        const hasMainDocument = (subject) => {
          const mainDocumentAttachmentId = subjectToAttachmentMap[subject.id];
          if (mainDocumentAttachmentId) {
            const storedFileId = attachmentToStoredFileIdMap[mainDocumentAttachmentId];
            if (storedFileId) {
              return true;
            }
            return false;
          }
        }

        if (subjects.every(subject => hasReport(subject) || hasMainDocument(subject))) {
          const popup = createReportPreviewPopup({
            reports: cards.map(x => ({
              id: x.id,
              module: OriginModuleEnum.SafetyInstructionCard,
              uniqueNumber: x.uniqueNumber,
              reportAlias: "SafetyInstructionCard"
            })),
            onConfirm,
            title: arxs.t("actions.safety_instruction_card.finalize_preview_title"),
            confirmation: arxs.t("actions.safety_instruction_card.finalize_confirmation_question")
          });

          context.popup.show(popup);
        }
        else {
          if (subjects.length > 1) {
            Toaster.warning(arxs.t("actions.safety_instruction_card.finalize_no_documents_warning"))
          } else {
            Toaster.warning(arxs.t("actions.safety_instruction_card.finalize_no_document_warning"))
          }
        }
      }
    });
  }

  sign = (state) => {
    return new Promise((resolve, reject) => {
      const { cards, context } = state;
      if (cards) {
        const attachmentToStoredFileIdMap = cards
          .flatMap(x => x.attachmentInfo.storedFiles)
          .toDictionary(x => x.id, x => x.storedFileId);

        const cardToAttachmentMap = cards
          .toDictionary(x => x.id, x => x.attachmentInfo.attachments
            .filter(a => a.type === ObjectDocumentType.MainDocument)
            .flatMap(a => a.value || [])
            .filter(v => !v.isDeleted)
            .map(v => v.id)[0]
          );

        const popup = createSignaturePopup({
          module: OriginModuleEnum.SafetyInstructionCard,
          documents: cards
            .map(x => ({
              id: x.id,
              module: x.module,
              name: x.name,
              uniqueNumber: x.uniqueNumber,
              storedFileId: attachmentToStoredFileIdMap[cardToAttachmentMap[x.id]]
            })),
          onConfirm: () => {
            context.popup.close();
          }
        });
        context.popup.show(popup);
      }
    });
  }

  setInProcess = (state) => {
    const { context } = state;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.safetyInstructionCard
        .setInProcess(data)
        .then(() =>
          Toaster.success(arxs.t("actions.safety_instruction_card.set_inprocess_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.safety_instruction_card.set_inprocess_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  deactivate = (state) => {
    const { context } = state;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.safetyInstructionCard
        .deactivate(data)
        .then(() =>
          Toaster.success(arxs.t("actions.safety_instruction_card.deactiviate_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.safety_instruction_card.deactiviate_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  dropActions = () => {
    return {
      onDropInProcess: (state) => {
        this.setInProcess(state);
      },
    };
  };
}
export default new SafetyInstructionCardActions();
