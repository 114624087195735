import React, { Component, createRef, ReactNode } from "react";
import arxs from "infra/arxs";
import { createCardLookup } from "components/shell/CardLookup/CardLookup";
import { Card } from "components/card/Card";
import GlobalContext from "infra/GlobalContext";
import SelectionLabel from "components/controls/selectionLabel/SelectionLabel";
import Button from "components/controls/Button";
import { SecurityContext } from "infra/SecurityContext";
import { createWizardPopup } from "components/wizard/WizardPopup";

import "./CardList.scss";

export interface CardListProps {
  title?: string;
  readOnly?: boolean;
  singleSelection?: boolean;
  modules: string[];
  value: any[];
  selected?: any;
  onChange(value: any[]): void;
  onMouseDown(e: any, card: any): void;
  onClick?(e: any, card: any): void;
  orderBy?(card: any): boolean;
  className?: string;
  required?: boolean;
  prefilter?: any;
  securityContext: SecurityContext;
  condensed?: boolean;
  showOpenInTab?: boolean;
  isTemplate?: boolean;
  undraggable?: boolean;
  vertical?: boolean;
  expandable?: boolean;
  extendData?: boolean;
  context?: any;
  navigation?: boolean;
  renderCardFooter?(card: any): ReactNode;
}

interface CardListState {
  cardLookup?: any;
  expanded?: boolean;
  expandableCardLength: number;
  singleSelection: boolean;
}

const _scrollOptions: ScrollIntoViewOptions = { behavior: "smooth", block: "nearest", inline: "center" };

export default class CardList extends Component<CardListProps, CardListState> {
  cardRefs: React.RefObject<HTMLDivElement>[] = [];

  state: CardListState = {
    expandableCardLength: 6,
    singleSelection: false,
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps: CardListProps, prevState: CardListState) {
    if (
      prevProps.singleSelection !== this.props.singleSelection ||
      prevProps.isTemplate !== this.props.isTemplate
    ) {
      this.refresh();
    }

    if (this.cardRefs.length !== (this.props.value || []).length) {
      this.cardRefs = (this.props.value || []).map(_ => createRef<HTMLDivElement>());
    }

    if (prevProps.selected !== this.props.selected) {
      this.scrollIntoView(this.props.selected);
    }
  }

  scrollIntoView = (card: any) => {
    if (card) {
      const cards = (this.props.value || []);
      const index = cards.indexOfFirstMatch(x => x.id === card.id);
      const element = this.cardRefs[index].current;
      if (element) {
        element.scrollIntoView(_scrollOptions);
      }
    }
  }

  refresh = () => {
    if (this.props.isTemplate) {
      this.setState({ singleSelection: true });
    } else {
      this.setState({ singleSelection: this.props.singleSelection || false });
    }
  };

  handleDelete = (context: any, value: any) => {
    const selected = (this.props.value || []).filter((x) => x.id !== value.id);
    this.props.onChange(selected);
  };

  handleAdd = (context: any) => {
    const { modules, prefilter, securityContext } = this.props;
    const { singleSelection } = this.state;

    const onApplyFilter = (state: any) => {
      context.popup.close();

      if (state) {
        const selected = (this.props.value || [])
          .filter((x) => !singleSelection)
          .concat(Object.keys(state).map((id) => ({ id, module: state[id] })));
        this.props.onChange(selected);
      }
    };

    const cardLookup = createCardLookup({
      modules,
      onApplyFilter,
      singleSelection,
      prefilter,
      securityContext,
    });

    if (!this.props.isTemplate) {
      context.popup.show(cardLookup);
    } else {
      const options = [];
      for (const module of this.props.modules || []) {
        options.push({
          title: arxs.t("actions.reference.create_new", {
            module: arxs.modules.titles[module],
          }),
          handle: () => {
            const wizardPopup = createWizardPopup(
              module,
              {},
              false,
              context.popup.close,
              (data: any) => this.handleEdit({ ...data, module })
            );

            context.popup.show(wizardPopup);
          },
        });
      }

      options.push({
        title: arxs.t("actions.reference.choose_existing"),
        handle: () => {
          context.popup.show(cardLookup);
        },
      });

      context.optionPopup.show(
        arxs.t("actions.reference.new_existing"),
        options
      );
    }
  };

  handleEdit = (data: any) => {
    if (data) {
      this.props.onChange([data]);
    }
  };

  navigateTo = (offset: number) => {
    const cards = (this.props.value || []);
    
    let index = cards.indexOfFirstMatch(x => this.props.selected && x.id === this.props.selected.id);
    if (index > -1 && this.props.onClick) {
      index = Math.max(0, Math.min(cards.length - 1, index + offset));
      this.props.onClick(null, cards[index]);
    }
  }

  render() {
    const { title, readOnly, className, expandable } = this.props;
    const { singleSelection } = this.state;
    const value = this.props.value || [];
    const required = this.props.required;
    const modules = this.props.modules || [];
    const defaultModule = modules.length === 1 ? modules[0] : null;
    const showOpenInTab =
      this.props.showOpenInTab === undefined ? true : this.props.showOpenInTab;
    const isTemplate =
      this.props.isTemplate !== undefined ? true : this.props.isTemplate;

    let cards = value.distinct((x) => x.id);

    if (this.props.orderBy) {
      cards = cards.orderBy(this.props.orderBy);
    }

    const classes = ["card-list", className, this.props.vertical && "vertical"]
      .filter((x) => x)
      .join(" ");

    const renderCards = (context: any) => {
      let cardsInScope = cards.distinct((x) => x.id);

      if (expandable && !this.state.expanded) {
        cardsInScope = cardsInScope.take(this.state.expandableCardLength);
      }

      return cardsInScope.map((x, index) => (
        <Card
          key={x.id || arxs.generateUniqueId()}
          ref={this.cardRefs[index]}
          showImage
          showOpenInTab={showOpenInTab}
          isTemplate={isTemplate}
          objectId={x.id}
          module={x.module || defaultModule}
          onDelete={() => this.handleDelete(context, x)} //(required && singleSelection) || (required && value.length === 1) ? this.handleAdd(context) : this.handleDelete(context, x)}
          onClick={this.props.onClick}
          onMouseDown={this.props.onMouseDown}
          selected={this.props.selected && this.props.selected.id === x.id}
          readOnly={this.props.readOnly}
          condensed={this.props.condensed}
          undraggable={this.props.undraggable}
          data={!x.id && x}
          onEdit={this.handleEdit}
          extraData={x}
          context={this.props.context}
          renderFooter={this.props.renderCardFooter}
        />
      ));
    };

    const handleExpansion = () => {
      this.setState({ expanded: !this.state.expanded });
    };

    const renderExpansionToggle = (context: any) => {
      if (
        this.props.expandable &&
        cards.length > this.state.expandableCardLength
      ) {
        return (
          <Button
            className="wizard-button wizard-expand icon"
            onClick={handleExpansion}
            title={
              this.state.expanded
                ? arxs.t("wizard.collaps")
                : arxs.t("wizard.expand")
            }
          >
            <i
              className={
                this.state.expanded
                  ? "fas fa-chevron-double-up"
                  : "fas fa-chevron-double-down"
              }
            ></i>
          </Button>
        );
      }
    };

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className={classes}>
            {title && (
              <label>
                {title}
                {required && <span> *</span>}
              </label>
            )}
            <div className="card-list-content-wrapper">
              {this.props.navigation && <Button className="wizard-button icon" onClick={() => this.navigateTo(-1)}>
                  <i className="fas fa-chevron-left"></i>
                </Button>}
              {cards && cards.length > 0 && (
                <div className="card-list-content">
                  <div className="card-list-content-cards">
                    {renderCards(context)}
                  </div>
                  <div className="card-list-content-expansion">
                    {renderExpansionToggle(context)}
                  </div>
                </div>
              )}
              {this.props.navigation && <Button className="wizard-button icon" onClick={() => this.navigateTo(1)}>
                  <i className="fas fa-chevron-right"></i>
                </Button>}
            </div>
            {!readOnly &&
              ((singleSelection && cards.length === 0) || !singleSelection) && (
                <div className="card-list-actions">
                  <SelectionLabel
                    onClick={() => this.handleAdd(context)}
                    title={title}
                  />
                </div>
              )}
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
