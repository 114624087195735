import React, { Component } from 'react';
import GlobalContext from 'infra/GlobalContext';
import CheckBox from 'components/controls/CheckBox';
import arxs from 'infra/arxs'

import './TagLabel.scss';

export default class TagLabel extends Component {
    state = {}

    lookups = {
        codeElementsById: {},
        tagMap: {}
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    handleCheckboxClick = (e) => {
        if (this.props.onClick) {
            e.stopPropagation();
            this.props.onClick(this.props.id);
        }
    }

    onDelete = (event) => {
        if (this.props["onDelete"]) {
            this.props.onDelete(this.props.id);
        }
    }

    render() {
        const { id, name, checkBox, checked, readOnly } = this.props
        const { codeElementsById, tagMap } = this.state;

        const tag = tagMap && tagMap[id];

        const breadCrumb = codeElementsById && tag && codeElementsById[tag.kindId] && codeElementsById[tag.typeId] && `${codeElementsById[tag.kindId].name} > ${codeElementsById[tag.typeId].name}`

        const renderCheckBox = () => {
            if (checkBox) {
                return <div className="tag-checkbox"><CheckBox name={name} id={id} key={id} checked={checked} onChange={(e) => this.handleCheckboxClick(e)}  /></div>
            }
        }

        const renderActions = () => {
            if (!readOnly) {
                return <div className="tag-actions">
                    <div className="tag-remove"
                        onClick={this.onDelete}
                        title={arxs.t('controls.taglabel.delete')}>
                        <i className="far fa-trash-alt"></i>
                    </div>
                </div>
            }
        }

        return (
            <GlobalContext.Consumer>
                {(context) =>
                    <div className={`tag-label ${checked && 'selected'}`} onClick={this.handleCheckboxClick}>
                        <div className={`tag-icon-wrapper ${readOnly ? "readOnly" : "allowDelete"}`}>
                            <div className={`tag-icon ${checked && 'selected'}`}>
                                <i className='fas fa-tag' ></i>
                            </div>
                        </div>
                        <div className="tag-info-wrapper">
                            {breadCrumb &&
                                <div className="tag-info-breadcrumb" title={breadCrumb}>
                                    {breadCrumb}
                                </div>
                            }
                            <div className={`tag-name ${checked && 'selected'}`} title={name} id={id}>
                                {name}
                            </div>
                        </div>

                        {renderActions()}
                        {renderCheckBox()}
                    </div>}
            </GlobalContext.Consumer >
        );
    }
}          