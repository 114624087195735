import React, { Component } from "react";
import arxs from "infra/arxs";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import {
  AutoCompleteBlurEvent,
  AutoCompleteChangeEvent,
  AutoCompleteCloseEvent,
} from "@progress/kendo-react-dropdowns";

import "./AutoCompleteTextBox.scss";

export interface AutoCompleteProps {
  className?: string;
  items: Array<string>;
  value: string;
  onChange(value: string): void;
  placeholder?: string;
  id?: string;
  readOnly?: boolean;
}

interface AutoCompleteState {
  items: Array<string>;
  value: string;
  delay: number;
}

class AutoCompleteTextBox extends Component<
  AutoCompleteProps,
  AutoCompleteState
> {
  state: AutoCompleteState = {
    items: [],
    value: "",
    delay: 250
  };

  inputRef: any;

  componentDidMount() {
    this.setState({ items: this.props.items, value: this.props.value });
  }

  componentDidUpdate(prevProps: AutoCompleteProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items });
    }

    const prevvalue = prevProps.value;
    const value = this.props.value;
    if (prevvalue !== value) {
      this.setState({ value }, this.refresh);
    }
  }

  refresh = () => {
    const { value } = this.state;

    const items =
      value && value !== ""
        ? filterBy(this.props.items, {
            value,
            operator: "contains",
            ignoreCase: true,
          })
        : this.props.items;

    this.setState({ items });
  };

  onChange = (event: AutoCompleteChangeEvent) => {
    this.setState({ value: event.value }, this.refresh);
  };

  onBlur = (event: AutoCompleteBlurEvent) => {
    this.props.onChange && this.props.onChange(event.target.value);
  };

  onClose = (event: AutoCompleteCloseEvent) => {
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const id = this.props.id || arxs.uuid.generate();
    const { value, items } = this.state;

    return (
      <AutoComplete
        id={id}
        key={id}
        className={`autocomplete ${this.props.className || ""}`}
        data={items}
        value={value}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        disabled={this.props.readOnly}
        onBlur={this.onBlur}
        onClose={this.onClose}
      />
    );
  }
}
export default AutoCompleteTextBox;
