import React, { Component } from "react";
import GlobalContext from "infra/GlobalContext";
import { SecurityContext } from "infra/SecurityContext";
import { OriginModuleEnum } from "infra/api/contracts";
import SelectionLabel from "components/controls/selectionLabel/SelectionLabel";
import CodeElementLabel from "./CodeElementLabel";
import { createCodeElementLookup } from "./CodeElementLookup";

import "./CodeElementList.scss";

interface CodeElementRef {
  id?: string;
  parentId?: string;
  grandParentId?: string;
}

interface AdditionalCode {
  module: OriginModuleEnum;
  code: string;
}

export interface CodeElementListProps {
  title?: string;
  readOnly?: boolean;
  singleSelection?: boolean;
  value?: CodeElementRef[];
  onChange?(value: CodeElementRef[]): void;
  module?: OriginModuleEnum | undefined;
  code?: string;
  allowDelete?: boolean;
  className?: string;
  required?: boolean;
  securityContext?: SecurityContext | undefined;
  additionalCodes?: Array<AdditionalCode>;
  addParent?: boolean;
  excludeCodes?: Array<string>
}

interface CodeElementListState {
  codeElementLookup?: any;
}

export default class CodeElementList extends Component<
  CodeElementListProps,
  CodeElementListState
  > {
  state: CodeElementListState = {};



  handleDelete = (value: any) => {
    if (!this.props.onChange) {
      return;
    }

    const selected = (this.props.value || []).filter((x) => x.id !== value.id);
    this.props.onChange(selected);
  };

  handleAdd = (context: any) => {
    let codeElementLookup = this.state.codeElementLookup;
    const onChange = (state: any) => {
      if (!this.props.onChange) {
        return;
      }

      context.popup.close();
      if (state) {
        if (!Array.isArray(state)) {
          this.props.onChange([
            {
              id: state.type ? state.type.id : undefined,
              parentId: state.kind ? state.kind.id : undefined,
              grandParentId: state.sort ? state.sort.id : undefined,
            },
          ]);
        } else {
          const newSelected = state.map((x) => ({
            id: x.type ? x.type.id : undefined,
            parentId: x.kind ? x.kind.id : undefined,
            grandParentId: x.sort ? x.sort.id : undefined,
          })) as CodeElementRef[];
          this.props.onChange(newSelected.concat(this.props.value || []));
        }
      }
    };
    codeElementLookup = createCodeElementLookup(
      this.props.module,
      onChange,
      this.props.singleSelection,
      this.props.code,
      this.props.securityContext,
      this.props.additionalCodes,
      this.props.addParent,
      this.props.excludeCodes
    );
    this.setState({ codeElementLookup }, () =>
      context.popup.show(this.state.codeElementLookup)
    );
  };

  render() {
    const { title, readOnly, allowDelete, className, singleSelection } =
      this.props;
    const value = (this.props.value || [])
      .filter(x => x)
      .filter((x) => Object.keys(x).some((y) => y));
    const required = this.props.required;

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className={`codeElement-list ${className || ""}`}>
            {title && (
              <label>
                {title}
                {required && <span> *</span>}
              </label>
            )}
            {value && value.length > 0 && (
              <div className="codeElement-list-content">
                {value.map(
                  (x) =>
                    x &&
                    x.id && (
                      <CodeElementLabel
                        key={x.id}
                        data={x}
                        readOnly={readOnly}
                        allowDelete={allowDelete}
                        onDelete={() =>
                          this.props.required
                            ? this.handleAdd(context)
                            : this.handleDelete(x)
                        }
                      />
                    )
                )}
              </div>
            )}
            {!readOnly &&
              ((value.length === 0 && singleSelection) || !singleSelection) && (
                <SelectionLabel
                  onClick={() => this.handleAdd(context)}
                  title={title}
                />
              )}
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
