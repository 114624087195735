import React, { useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';

import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';

import { defaults as defaultInteractions } from 'ol/interaction';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlFeature from 'ol/Feature';
import OlVectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import OlSourceCluster from 'ol/source/Cluster';
import OlSourceVector from 'ol/source/Vector';
import OlGeomPoint from 'ol/geom/Point';
import OlStyleStyle from 'ol/style/Style';
import OlStyleIcon from 'ol/style/Icon';
import Control from 'ol/control/Control';

import generateLayerGroup from "components/controls/geolocation/MapMetadata";
import MapComponent from 'components/layouts/geo/MapComponent';
import GeoLocationButton from 'components/layouts/geo/GeoLocationButton';
import NominatimSearch from 'components/layouts/geo/NominatimSearch';
import LayerToggle from "components/layouts/geo/LayerToggle";

import './MapView.scss';

export const createMapView = (context, data) => {
  context = context || [];
  const state = {
    title: arxs.t("geo_lookup.title"),
    content: <MapView data={data} />
  };
  return state;
};

const fontSize = 6;
const fontFamily = "sans-serif";
const markerSize = 48;

const getClusterIcon = (count) => {
  const icon = `<text x='50%' y='8.5' fill='#fff' text-anchor='middle' font-family='${fontFamily}' font-size='${fontSize}px'>${count}</text>`;
  const svg = `<?xml version='1.0' encoding='utf-8'?><svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' width='${markerSize}' height='${markerSize}' xml:space='preserve'><path fill='#3D4A6380' class='path1' d='M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3 3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z'></path><path fill='#fff' class='path2' d='M8 15.8l-4.4-4.6c-1.2-1.2-1.9-2.9-1.9-4.7 0-1.7 0.6-3.2 1.8-4.5 1.3-1.2 2.8-1.8 4.5-1.8s3.2 0.7 4.4 1.9c1.2 1.2 1.8 2.8 1.8 4.5s-0.7 3.5-1.8 4.7l-4.4 4.5zM4 10.7l4 4.1 3.9-4.1c1-1.1 1.6-2.6 1.6-4.2 0-1.5-0.6-2.9-1.6-4s-2.4-1.7-3.9-1.7-2.9 0.6-4 1.7c-1 1.1-1.6 2.5-1.6 4 0 1.6 0.6 3.2 1.6 4.2v0z'></path><path fill='#fff' class='path3' d='M8 16l-4.5-4.7c-1.2-1.2-1.9-3-1.9-4.8 0-1.7 0.6-3.3 1.9-4.6 1.2-1.2 2.8-1.9 4.5-1.9s3.3 0.7 4.5 1.9c1.2 1.3 1.9 2.9 1.9 4.6 0 1.8-0.7 3.6-1.9 4.8l-4.5 4.7zM8 0.3c-1.6 0-3.2 0.7-4.3 1.9-1.2 1.2-1.8 2.7-1.8 4.3 0 1.7 0.7 3.4 1.8 4.5l4.3 4.5 4.3-4.5c1.1-1.2 1.8-2.9 1.8-4.5s-0.6-3.1-1.8-4.4c-1.2-1.1-2.7-1.8-4.3-1.8zM8 15.1l-4.1-4.2c-1-1.2-1.7-2.8-1.7-4.4s0.6-3 1.7-4.1c1.1-1.1 2.6-1.7 4.1-1.7s3 0.6 4.1 1.7c1.1 1.1 1.7 2.6 1.7 4.1 0 1.6-0.6 3.2-1.7 4.3l-4.1 4.3zM4.2 10.6l3.8 4 3.8-4c1-1 1.6-2.6 1.6-4.1s-0.6-2.8-1.6-3.9c-1-1-2.4-1.6-3.8-1.6s-2.8 0.6-3.8 1.6c-1 1.1-1.6 2.4-1.6 3.9 0 1.6 0.6 3.1 1.6 4.1v0z'></path>${icon}</svg>`;
  return svg;
};
const getMarkerIcon = (module) => {
  const icon = arxs.icons.getByModule(module);
  const formattedIcon = icon
    .replace("<svg", `<svg x='5' y='4' width='6' height='6'`)
    .replace("<path", `<path fill='#fff'`)
    ;
  const svg = `<?xml version='1.0' encoding='utf-8'?><svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' width='${markerSize}' height='${markerSize}' xml:space='preserve'><path fill='#3D4A63B0' class='path1' d='M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3 3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z'></path><path fill='#fff' class='path2' d='M8 15.8l-4.4-4.6c-1.2-1.2-1.9-2.9-1.9-4.7 0-1.7 0.6-3.2 1.8-4.5 1.3-1.2 2.8-1.8 4.5-1.8s3.2 0.7 4.4 1.9c1.2 1.2 1.8 2.8 1.8 4.5s-0.7 3.5-1.8 4.7l-4.4 4.5zM4 10.7l4 4.1 3.9-4.1c1-1.1 1.6-2.6 1.6-4.2 0-1.5-0.6-2.9-1.6-4s-2.4-1.7-3.9-1.7-2.9 0.6-4 1.7c-1 1.1-1.6 2.5-1.6 4 0 1.6 0.6 3.2 1.6 4.2v0z'></path><path fill='#fff' class='path3' d='M8 16l-4.5-4.7c-1.2-1.2-1.9-3-1.9-4.8 0-1.7 0.6-3.3 1.9-4.6 1.2-1.2 2.8-1.9 4.5-1.9s3.3 0.7 4.5 1.9c1.2 1.3 1.9 2.9 1.9 4.6 0 1.8-0.7 3.6-1.9 4.8l-4.5 4.7zM8 0.3c-1.6 0-3.2 0.7-4.3 1.9-1.2 1.2-1.8 2.7-1.8 4.3 0 1.7 0.7 3.4 1.8 4.5l4.3 4.5 4.3-4.5c1.1-1.2 1.8-2.9 1.8-4.5s-0.6-3.1-1.8-4.4c-1.2-1.1-2.7-1.8-4.3-1.8zM8 15.1l-4.1-4.2c-1-1.2-1.7-2.8-1.7-4.4s0.6-3 1.7-4.1c1.1-1.1 2.6-1.7 4.1-1.7s3 0.6 4.1 1.7c1.1 1.1 1.7 2.6 1.7 4.1 0 1.6-0.6 3.2-1.7 4.3l-4.1 4.3zM4.2 10.6l3.8 4 3.8-4c1-1 1.6-2.6 1.6-4.1s-0.6-2.8-1.6-3.9c-1-1-2.4-1.6-3.8-1.6s-2.8 0.6-3.8 1.6c-1 1.1-1.6 2.4-1.6 3.9 0 1.6 0.6 3.1 1.6 4.1v0z'></path>${formattedIcon}</svg>`;
  return svg;
};
const getSelectedMarkerIcon = (module) => {
  const icon = arxs.icons.getByModule(module);
  const formattedIcon = icon
    .replace("<svg", `<svg x='5' y='4' width='6' height='6'`)
    .replace("<path", `<path fill='#fff'`)
    ;
  const svg = `<?xml version='1.0' encoding='utf-8'?><svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' width='${markerSize}' height='${markerSize}' xml:space='preserve'><path fill='#4D79FD' class='path1' d='M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3 3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z'></path><path fill='#fff' class='path2' d='M8 15.8l-4.4-4.6c-1.2-1.2-1.9-2.9-1.9-4.7 0-1.7 0.6-3.2 1.8-4.5 1.3-1.2 2.8-1.8 4.5-1.8s3.2 0.7 4.4 1.9c1.2 1.2 1.8 2.8 1.8 4.5s-0.7 3.5-1.8 4.7l-4.4 4.5zM4 10.7l4 4.1 3.9-4.1c1-1.1 1.6-2.6 1.6-4.2 0-1.5-0.6-2.9-1.6-4s-2.4-1.7-3.9-1.7-2.9 0.6-4 1.7c-1 1.1-1.6 2.5-1.6 4 0 1.6 0.6 3.2 1.6 4.2v0z'></path><path fill='#fff' class='path3' d='M8 16l-4.5-4.7c-1.2-1.2-1.9-3-1.9-4.8 0-1.7 0.6-3.3 1.9-4.6 1.2-1.2 2.8-1.9 4.5-1.9s3.3 0.7 4.5 1.9c1.2 1.3 1.9 2.9 1.9 4.6 0 1.8-0.7 3.6-1.9 4.8l-4.5 4.7zM8 0.3c-1.6 0-3.2 0.7-4.3 1.9-1.2 1.2-1.8 2.7-1.8 4.3 0 1.7 0.7 3.4 1.8 4.5l4.3 4.5 4.3-4.5c1.1-1.2 1.8-2.9 1.8-4.5s-0.6-3.1-1.8-4.4c-1.2-1.1-2.7-1.8-4.3-1.8zM8 15.1l-4.1-4.2c-1-1.2-1.7-2.8-1.7-4.4s0.6-3 1.7-4.1c1.1-1.1 2.6-1.7 4.1-1.7s3 0.6 4.1 1.7c1.1 1.1 1.7 2.6 1.7 4.1 0 1.6-0.6 3.2-1.7 4.3l-4.1 4.3zM4.2 10.6l3.8 4 3.8-4c1-1 1.6-2.6 1.6-4.1s-0.6-2.8-1.6-3.9c-1-1-2.4-1.6-3.8-1.6s-2.8 0.6-3.8 1.6c-1 1.1-1.6 2.4-1.6 3.9 0 1.6 0.6 3.1 1.6 4.1v0z'></path>${formattedIcon}</svg>`;
  return svg;
};
const svgToDataUrl = (svg) => "data:image/svg+xml," + encodeURIComponent(svg);

const fallbackGeoCoordinate = [4.352435254798073, 50.84679449699351];
const fallbackXyCoordinate = fromLonLat(fallbackGeoCoordinate);

const markerLayer = new OlVectorLayer({
  name: "Marker",
  visible: true,
  style: (feature) => {
    const children = feature.get("features");
    const child = children.length === 1 && children[0];

    if (child) {
      return child.styleFunction_();
    }

    return new OlStyleStyle({
      image: new OlStyleIcon({
        anchor: [0.5, 0],
        anchorOrigin: "bottom-left",
        src: svgToDataUrl(getClusterIcon(children.length))
      })
    });
  }
});

let onSelectCard = null;

const map = new OlMap({
  interactions: defaultInteractions(),
  view: new OlView({
    center: fallbackXyCoordinate,
    zoom: 12
  }),
  controls: []
});

map.on('singleclick', function (event) {
  const features = map.getFeaturesAtPixel(event.pixel);
  if (features.length === 1) {
    const feature = features[0];
    const fs = feature.get("features");
    if (fs) {
      const child = fs[0];
      const data = child.get("data");
      if (onSelectCard) onSelectCard(feature, data);
    }
  }
});

var handleZoomIn = function (e) {
  map.getView().setZoom(map.getView().getZoom() + 1);
};

var handleZoomOut = function (e) {
  map.getView().setZoom(map.getView().getZoom() - 1);
};

var zoomInButton = document.createElement("button");
zoomInButton.innerHTML = "<i class='fas fa-search-plus'></i>";
zoomInButton.addEventListener('click', handleZoomIn, false);

var zoomOutButton = document.createElement("button");
zoomOutButton.innerHTML = "<i class='fas fa-search-minus'></i>";
zoomOutButton.addEventListener('click', handleZoomOut, false);

var zoomDiv = document.createElement('div');
zoomDiv.className = "ol-zoom ol-unselectable ol-control";
zoomDiv.appendChild(zoomInButton);
zoomDiv.appendChild(zoomOutButton);

map.addControl(new Control({ element: zoomDiv }));

function getViewForMarker(markerXyCoordinate) {
  if (markerXyCoordinate) {
    return new OlView({
      center: markerXyCoordinate,
      zoom: 16
    });
  }

  return new OlView({
    center: fallbackXyCoordinate,
    zoom: 12
  });
}

function MapView(props) {
  const [view, setView] = useState();
  const [layers, setLayers] = useState(["map"]);

  const refreshLayers = () => {
    const layerGroup = generateLayerGroup(layers);
    map.setLayers([layerGroup, markerLayer]);
  };

  useEffect(refreshLayers, []);
  useEffect(refreshLayers, [layers]);

  useEffect(() => {
    const items = (props.data || [])
      .flatMap(x => x.cards)
      .filter(c => c.geoLocation)
      .map(x => ({ id: x.id, module: x.module, geoLocation: x.geoLocation }))
      .map(x => ({ ...x, xyCoordinate: fromLonLat([x.geoLocation.longitude, x.geoLocation.latitude]) }));

    const source = new OlSourceVector({
      features: items.map(data => {
        const feature = new OlFeature({ geometry: new OlGeomPoint(data.xyCoordinate) });

        feature.set("data", data);
        feature.setStyle(() => {
          const src = (props.selected && props.selected.id === data.id) ? getSelectedMarkerIcon(data.module) : getMarkerIcon(data.module);
          return new OlStyleStyle({
            image: new OlStyleIcon({
              anchor: [0.5, 0],
              anchorOrigin: "bottom-left",
              src: svgToDataUrl(src)
            })
          });
        });

        return feature;
      }),
    });

    const clusterSource = new OlSourceCluster({
      distance: 50,
      source: source,
    });

    markerLayer.setSource(clusterSource);
    if (!view) {
      const centerPoint = items.length > 0 && [items.average(x => x.xyCoordinate[0]), items.average(x => x.xyCoordinate[1])];
      const newView = getViewForMarker(centerPoint);
      setView(newView);
      const features = source.getFeatures();
      if (features.length > 0) {
        const extent = source.getExtent();
        const width = (extent[2] - extent[0]);
        const height = (extent[3] - extent[1]);
        const xPadding = width * .1;
        const paddedExtent = [
          extent[0] - xPadding,
          extent[1] - (height * .1),
          extent[2] + xPadding,
          extent[3] + (height * .2),
        ];
        setTimeout(() => newView.fit(paddedExtent, map.getSize()));
      }
    }
    map.setTarget("map");
    map.render();
  }, [props.data, props.selected]);

  useEffect(() => {
    map.setView(view);
  }, [view]);

  const { onToggle } = props;
  useEffect(() => {
    onSelectCard = (feature, data) => {
      if (onToggle) {
        const child = feature.get("features")[0];
        onToggle(null, data);
        // Triggers a local refresh of this marker
        child.setStyle(child.styleFunction_);
      }
    };
  }, [onToggle]);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        <div className={`map-view ${props.className || ""}`}>
          <div className="map-view-toolbar">
            <NominatimSearch key="search" map={map} />
          </div>
          <div className="map-view-content">
            <div className="map-view-mapcontainer">
              <AutoSizer>
                {({ width, height }) => (<MapComponent style={{ width, height }} map={map} />)}
              </AutoSizer>
              <LayerToggle
                value={layers}
                onChange={setLayers}
                />
              <GeoLocationButton
                title={arxs.t("geo_lookup.current_location")}
                map={map}
                showMarker={true}
                follow={true}
                onGeolocationChange={event => console.log(event)}>
                <i className="fas fa-location"></i>
              </GeoLocationButton>
            </div>
          </div>
        </div>}
    </GlobalContext.Consumer>);
}
export default MapView;