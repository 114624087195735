import React, { Component } from 'react';
import arxs from 'infra/arxs'
import GlobalContext from 'infra/GlobalContext';
import { themes } from 'infra/GlobalState/ThemeState';
import { ActivityDropdown } from './Activities/ActivityDropdown';
import StatusTile from './Status/StatusTile';
import Avatar from 'components/controls/images/Avatar';
import DropDownMenu from 'components/controls/DropDownMenu';
import Search from './Search/Search';
import { withRouter } from 'react-router-dom';
import QRScanner from '../Search/QRScanner';

import './Header.scss';

class Header extends Component {
    logOff = () => {
        arxs.Identity.logOff();
    }

    toggleTheme = (context) => {
        let nextIndex = context.theme.index + 1;
        nextIndex %= themes.length;
        context.theme.setIndex(nextIndex);
    }

    showMyProfile = () => {
        this.props.history.push({ pathname: `/profile` });
    }

    render() {
        const getItems = context => {
            return [{
                icon: "far fa-user",
                title: arxs.t("header.profile.my_profile"),
                onClick: () => this.showMyProfile()
            }, {
                icon: "far fa-power-off red",
                title: arxs.t("header.profile.logoff"),
                onClick: () => this.logOff()
            }, {}, {
                getIcon: () => context.theme.index === 0 ? "far fa-moon-stars" : "far fa-sun",
                title: arxs.t("header.profile.mode"),
                onClick: () => this.toggleTheme(context)
            }];
        };

        return (
            <GlobalContext.Consumer>
                {(context) => (
                    !context.platform.isPhone
                        ? (<header className="page-header">
                            <div className="logo">
                                {context.theme.index === 0 ? <img src="images/ARXS__X_ICOON.png" alt="ArXs" /> : <img src="images/ARXS__X_ICOON_WIT.png" alt="ArXs" />}
                                <h1>{arxs.productName}</h1>
                                {arxs.buildVersion && <h3>[ {arxs.buildVersion} - {window.location.hostname} ]</h3>}
                            </div>
                            <div className="spacer">
                            </div>
                            {context.platform.isTablet && <QRScanner history={this.props.history} onSearchResult={this.props.onSearchResult}/>}
                            <Search history={this.props.history} />
                            <StatusTile />
                            <ActivityDropdown history={this.props.history} />
                            <DropDownMenu className="profile" items={getItems(context)}>
                                <Avatar src={context.profile.image} fullName={context.profile.fullName} />
                                <span>{arxs.t("header.profile.welcome", { firstName: context.profile.firstName })}</span>
                            </DropDownMenu>
                        </header>)
                        :
                        (<header className="page-header">
                            <div className="logo">
                                {context.theme.index === 0 ? <img src="images/ARXS__X_ICOON.png" alt="ArXs" /> : <img src="images/ARXS__X_ICOON_WIT.png" alt="ArXs" />}
                            </div>
                            <div className="spacer"></div>
                            <QRScanner history={this.props.history} onSearchResult={this.props.onSearchResult}/>
                            <ActivityDropdown history={this.props.history} />
                            <div className="hamburger" onClick={context.menu.toggle}>
                                <i className={context.menu.isOpen ? "fa fa-times" : "fa fa-bars"}></i>
                            </div>
                        </header>)
                )}
            </GlobalContext.Consumer>
        );
    }
}
export default withRouter(Header);