import React, { useRef, useState, useEffect } from "react";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import ExcelJS from "exceljs";
import { Buffer } from "buffer";
import FileSaver from "file-saver";

import arxs from "infra/arxs";
import Button from 'components/controls/Button';

export default function BoardExcelExport(props) {
  const { gridColumns, columnDefinitions } = props;
  const ref = useRef(null);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const ghsMap = {
    "ghs_explosief": "GHS01",
    "ghs_ontvlambaar": "GHS02",
    "ghs_oxiderend": "GHS03",
    "ghs_houderonderdruk": "GHS04",
    "ghs_bijtend": "GHS05",
    "ghs_acuutgiftig": "GHS06",
    "ghs_schadelijk": "GHS07",
    "ghs_chronischgiftig": "GHS08",
    "ghs_milieugevaarlijk": "GHS09"
  }

  const getGHSTranslation = (name, value) => {
    return value * 1 === 1 ? ghsMap[name] : "";
  }

  const getGHSInOrder = (item, index) => {
    const values = Object.keys(ghsMap).map(x => getGHSTranslation(x, item[x])).filter(x => x !== "");

    return values[index] || "";
  }

  useEffect(() => {
    setData(props.data
      .map(item => {
        return columns
          .reduce((acc, def) => {
            let value = item[def.name];
            switch (def.name) {
              case "hazardSentencesFull": value = item["hazardSentences"]; break;
              case "preventionSentencesFull": value = item["preventionSentences"]; break;
              case "ghs_pict_1": value = getGHSInOrder(item, 0); break;
              case "ghs_pict_2": value = getGHSInOrder(item, 1); break;
              case "ghs_pict_3": value = getGHSInOrder(item, 2); break;
              case "ghs_pict_4": value = getGHSInOrder(item, 3); break;
              case "ghs_pict_5": value = getGHSInOrder(item, 4); break;
              case "ghs_pict_6": value = getGHSInOrder(item, 5); break;
              case "ghs_pict_7": value = getGHSInOrder(item, 6); break;
              case "ghs_pict_8": value = getGHSInOrder(item, 7); break;
              case "ghs_pict_9": value = getGHSInOrder(item, 8); break;
              default: break;
            }

            if (value) {
              switch (def.name) {
                case 'status':
                  value = arxs.statuses.titles[value];
                  break;
                case 'priority':
                  value = arxs.enums.getTitle("TaskPriorityEnum", value);
                  break;
                case "locationType":
                  value = arxs.enums.getTitle("IncidentManagementLocationType", value);
                  break;
                case "victimType":
                  value = arxs.enums.getTitle("IncidentManagementVictimType", value);
                  break;
                case "taskType":
                  value = arxs.enums.getTitle("TaskType", value);
                  break;
                case "geoLocation":
                  value = value && `${value.latitude},${value.longitude}`;
                  break;
                case "purchaseDate":
                case "constructionYear":
                case "targetDate":
                case "createdAt":
                case "depreciationPeriodEnd":
                case "birthDate":
                case "dateInService":
                case "creationDate":
                case "revisionDate":
                case "mostRecentVersion":
                case "expirationDate":
                case "startAt":
                case "endAt":
                  value = new Date(value);
                  break;
                case "establishmentNumbers":
                  value = ((value || []).map(en => en.establishmentNumber)).join(', ')
                  break;
                case "schoolNumbers":
                  value = ((value || []).map(sn => sn.number)).join(', ')
                  break;
                case "participants":
                  value = value.join(', ')
                  break;
                case "ghsPictograms":
                case "hazardSentences":
                case "preventionSentences":
                  value = ((value || [])).map(p => p.name.split('.')[0]).join(', ');
                  break;
                case "hazardSentencesFull":
                case "preventionSentencesFull":
                  value = ((value || [])).map(p => `${p.name} ${p.description}`).join('\n');
                  break;
                case "signalWord":
                  value = arxs.enums.getTitle("HazardousSubstanceSignalWord", value);
                  break;
                // see https://en.wikipedia.org/wiki/GHS_hazard_pictograms for mapping to GHS numbers
                case "ghs_explosief":
                case "ghs_ontvlambaar":
                case "ghs_oxiderend":
                case "ghs_houderonderdruk":
                case "ghs_bijtend":
                case "ghs_acuutgiftig":
                case "ghs_schadelijk":
                case "ghs_chronischgiftig":
                case "ghs_milieugevaarlijk":
                  value = getGHSTranslation(def.name, value);
                  break;
                case "tags":
                  if  (value && Array.isArray(value)) {
                    value = value.map(x => x.name).join(", ");
                  }
                default:
                  if (Array.isArray(value)) {
                    value = value.map(x => x.name || x).join(", ");
                  }
                  break;
              }
            } else {
              switch (def.name) {
                case "ghs_oxiderend":
                case "ghs_houderonderdruk":
                case "ghs_chronischgiftig":
                case "ghs_ontvlambaar":
                case "ghs_explosief":
                case "ghs_schadelijk":
                case "ghs_acuutgiftig":
                case "ghs_milieugevaarlijk":
                case "ghs_bijtend":
                  value = "";
                  break;
                case "link":
                  value = item.uniqueNumber ? `${window.location.origin.toLowerCase()}/search/${item.uniqueNumber}` : "";
                  break;
                default:
                  break;
              }
            }

            acc[def.name] = value;

            return acc;
          }, {});
      }));
  }, [props.data, columns]);

  useEffect(() => {
    const mapToColumnDefinition = (nameOrDefinition) => {
      if (typeof nameOrDefinition === "string") {
        return { name: nameOrDefinition };
      }
      return nameOrDefinition;
    };

    setColumns(gridColumns
      .map(mapToColumnDefinition)
      .filter(def => columnDefinitions[def.name]));

  }, [gridColumns, columnDefinitions]);

  const mapToExcelExportColumn = (def) => {
    const fieldName = def.name;
    let title = arxs.t(`kanban.common.${fieldName}`);

    let cellOptions;

    switch (fieldName) {
      case "subject_uniqueNumber":
      case "subject_sort":
      case "subject_kind":
      case "subject_type":
        title = `${arxs.t("kanban.common." + fieldName.split("_")[1])} ${arxs.t("kanban.common.subject")}`;
        break;
      case "inactivityDuration":
        cellOptions = {
          format: "0 \\d",
        };
        break;
      case "purchaseDate":
      case "constructionYear":
      case "targetDate":
      case "createdAt":
      case "depreciationPeriodEnd":
      case "birthDate":
      case "dateInService":
      case "creationDate":
      case "revisionDate":
      case "mostRecentVersion":
      case "expirationDate":
      case "startAt":
      case "endAt":
        break;
      // see https://en.wikipedia.org/wiki/GHS_hazard_pictograms for mapping to GHS numbers
      case "ghs_explosief": title = "GHS01"; break;
      case "ghs_ontvlambaar": title = "GHS02"; break;
      case "ghs_oxiderend": title = "GHS03"; break;
      case "ghs_houderonderdruk": title = "GHS04"; break;
      case "ghs_bijtend": title = "GHS05"; break;
      case "ghs_acuutgiftig": title = "GHS06"; break;
      case "ghs_schadelijk": title = "GHS07"; break;
      case "ghs_chronischgiftig": title = "GHS08"; break;
      case "ghs_milieugevaarlijk": title = "GHS09"; break;
      case "hazardSentencesFull":
      case "preventionSentencesFull":
        cellOptions = {
          wrap: true
        };
        break;
      default:
        // Cell Formats follow standard excel formatting syntax
        // If you want to add string literals as a pre- or suffix, they need to be
        // surrounded with double quotes as a number of letters are reserved (h, m, s, ...)
        if (fieldName.match(/price/i) || fieldName.match(/cost/i)) {
          cellOptions = {
            format: `${arxs.t("unit.euro")} #,##0.00`,
          };
        } else if (fieldName.match(/duration/i)) {
          cellOptions = {
            format: `0.0 "${arxs.t("unit.h")}"`,
          };
        } else if (fieldName.match(/power/i)) {
          cellOptions = {
            format: `#,##0.00 ${arxs.t("unit.watt")}`,
          };
        } else if (fieldName.match(/height/i) || fieldName.match(/width/i) || fieldName.match(/length/i)) {
          cellOptions = {
            format: `#,##0.00 "${arxs.t("unit.meter")}"`,
          };
        } else if (fieldName.match(/surface/i)) {
          cellOptions = {
            format: `#,##0.00 "${arxs.t("unit.square_meter")}"`,
          };
        } else if (fieldName.match(/volume/i)) {
          cellOptions = {
            format: `#,##0.00 "${arxs.t("unit.cubic_meter")}"`,
          };
        }
    }

    return <ExcelExportColumn
      key={fieldName}
      field={fieldName}
      cellOptions={cellOptions}
      title={title}
      width={125}
    />;
  };

  const fileName = `export-${arxs.modules.titles[props.module].toLowerCase()}-${new Date().toISOString()}.xlsx`;

  const handle = () => {
    if (ref.current) {
      // const options = ref.current.workbookOptions();
      // options.creator = "ArXs Solutions";
      // ref.current.save(options);

      ref.current.toDataURL().then(dataUrl => {
        const index = dataUrl.indexOf("base64,");
        const data = dataUrl.substring(index + 7);
        const workbook = new ExcelJS.Workbook();
        const buffer = Buffer.from(data, 'base64');
        workbook.xlsx.load(buffer)
          .then(() => {
            workbook.clearThemes();
            workbook.xlsx.writeBuffer()
              .then(buffer => {
                const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                FileSaver.saveAs(blob, fileName);
              });
          });
      });
    }
  };

  return <>
    <Button className="section" title={arxs.t("actions.download")} onClick={handle}>
      <i className="far fa-file-download"></i>
    </Button>
    <ExcelExport data={data} fileName={fileName} ref={ref}>
      {columns.map(mapToExcelExportColumn)}
    </ExcelExport>
  </>;
}