import React, { useState, useEffect, useContext } from "react";
import { useDrag } from "react-dnd";

import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";

import { createWizardPopup } from "components/wizard/WizardPopup";

import { CardProps, CardActionsProps } from "./CardContracts";
import Scrolling from "./Scrolling";

import "./Card.scss";

export interface IItem {
  type: string;
  objectId: string;
  status: string;
  module: string;
  actions: Array<string>;
}

function getValue(map: any, cardValue: any) {
  const id = (cardValue || {}).id;
  const ref = (map && map[id]) || {};
  return ref.name
    ? ref.name
    : (Object.keys(ref).some((x) => x)
      ? ref
      : undefined);
}

// Technical debt: https://trello.com/c/4V8ZhlzD/178-label-card-refactor
export const Card: React.ForwardRefExoticComponent<CardProps & React.RefAttributes<HTMLDivElement>> = React.forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const initialLookups = {
    codeElementsById: {},
    employeeMap: {},
    userRoleMap: {},
    contactMap: {},
    supplierMap: {},

    legalStructureMap: {},
    branchMap: {},
    buildingMap: {},
    locationMap: {},

    labourmeanMap: {},
    equipmentMap: {},
    intangibleAssetMap: {},
    protectionEquipmentMap: {},
    hazardousSubstanceMap: {},
    combinedInstallationMap: {},

    taskMap: {},
    taskRequestMap: {},
    maintenanceMap: {},
    inspectionMap: {},
    periodicalMap: {},
    projectMap: {},
    planningMomentsBySubjectMap: {},

    formMap: {},
    recommendationMap: {},
  };

  const [lookups, setLookups] = useState(initialLookups);
  const [data, setData] = useState();
  const [rawData, setRawData] = useState();
  const context = useContext(GlobalContext);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(initialLookups, (values: any) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
      }),
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, []);

  useEffect(() => {
    const refresh = () => {
      if (!props.objectId && !props.module && !props.data) {
        return;
      }

      if (props.data) {
        setRawData(props.data);
      }

      let card =
        props.data ||
        arxs.Api.lookups.resolveSubject({
          id: props.objectId,
          module: props.module,
        });

      if (card && Object.keys(card).length > 0) {
        const legalStructure = getValue(
          lookups.legalStructureMap,
          card.legalStructure
        );

        const branch = getValue(lookups.branchMap, card.branch);
        const building = getValue(lookups.buildingMap, card.building);
        const location = getValue(lookups.locationMap, card.location);
        const sort = getValue(lookups.codeElementsById, card.sort);
        const kind = getValue(lookups.codeElementsById, card.kind);
        const type = getValue(lookups.codeElementsById, card.type);

        const legalStructureNames = (card.legalStructures || [])
          .map((x: any) => getValue(lookups.legalStructureMap, x))
          .filter((x: string) => x)
          .join(", ");
        const branchNames = (card.branches || [])
          .map((x: any) => getValue(lookups.branchMap, x))
          .filter((x: string) => x)
          .join(", ");

        const data = {
          ...card,
          legalStructure: legalStructure || card.legalStructure,
          branch: branch || card.branch,
          building: building || card.building,
          location: location || card.location,
          sort: sort || card.sort,
          kind: kind || card.kind,
          type: type || card.type,
          legalStructureNames,
          branchNames,
        };

        setData(data);
      }
    };
    refresh();
  }, [lookups, props.data, props.module, props.objectId]);

  const onClick = (e: any) => {
    const card = data || props.data;

    if (props.onClick) {
      // This ensures the event is only triggered once whether we click on the checkbox or the enclosing div
      e.stopPropagation();
      props.onClick(e, card);
    }

    if (props.onToggle) {
      // This ensures the event is only triggered once whether we click on the checkbox or the enclosing div
      e.stopPropagation();
      props.onToggle(e, card);
    }
  };

  const onMouseDown = (e: any) => {
    const card = data || props.data;

    if (props.onMouseDown) {
      // This ensures the event is only triggered once whether we click on the checkbox or the enclosing div
      e.stopPropagation();
      props.onMouseDown(e, card);
    }
  };

  const onCheckChange = (e: any) => {
    const card = data || props.data;

    if (props.onCheckChange) {
      e.stopPropagation();
      props.onCheckChange(e, card);
    }
  };

  const onToggleSubscription = (e: any) => {
    const card = data || props.data;
    if (props.onToggleSubscription) {
      // This ensures only onToggleSubscription is triggered and not onClick
      props.onToggleSubscription(card, e);
    }
  };

  const closeWizardPopup = (context: any) => {
    context.popup.close();
  };

  const openWizardPopup = (context: any, readOnly?: boolean) => {   
    const card = rawData as any;
    if (card && card.module) {
      const wizardPopup = createWizardPopup(
        card.module,
        card,
        readOnly || false,
        () => closeWizardPopup(context),
        (data: any) => props.onEdit && props.onEdit (data)
      );

      context.popup.show(wizardPopup);
    }
  };

  const [, drag] = useDrag({
    type: "Card",
    item: () => {
      Scrolling.addEventListenerBoardScroll();
      return {
        objectId: props.objectId || (props.data || {}).id,
        status: props.status,
        module: props.module || props.data.module,
        actions: props.actions,
        card: data || props.data,
      };
    },
    end: Scrolling.removeEventListenerForBoardScroll,
    canDrag: !(props.undraggable || false),
  });

  const card = data || props.data;
  if (!card) {
    return <></>;
  }

  const module = card && (card.module || props.module);
  const metadata = module && arxs.moduleMetadataRegistry.get(module);

  if (!metadata) {
    return <></>;
  }

  const cardProps = (props.getCardProps && props.getCardProps()) || {};

  const showOpenInTab = !!props.showOpenInTab && arxs.isActionAllowed(metadata.base.readAction);

  const isTemplate = !!props.isTemplate;
  const showCheckbox =
    cardProps.showCheckbox !== undefined
      ? cardProps.showCheckbox
      : !!props.onToggle;
  const showDelete = !!props.onDelete && !props.readOnly;

  const statusClassName = arxs.getStatusClassName(card.module, card.status) || props.statusClassName;
  const archivedClassName = card.isDeleted === true ? "archived" : "";
  const className = props.className || "";
  const selected = props.selected || false;
  const checked =
    props.checked !== undefined
      ? props.checked
      : (typeof card.checked === "function" ? card.checked() : card.checked) ||
        false;

  const actionProps: CardActionsProps = {
    card,
    checked,
    metadata,

    mini: props.mini,
    condensed: props.condensed,
    readOnly: props.readOnly,
    showImage: props.showImage,
    showSubjectImage: props.showSubjectImage,
    renderFooter: props.renderFooter,

    showNavigateTo: showOpenInTab,

    showTemplate: isTemplate,
    onOpenTemplate: (context: any) => openWizardPopup(context, props.readOnly),

    onToggleSubscription,

    showCheckbox,
    onCheckChange,

    showDelete,
    onDelete: props.onDelete,
  };

  const registerRef = (element: any) => {
    if (typeof ref === "function") {
      ref(element);
    } else if (ref) {
      ref.current = element;
    }

    if (!context.platform.isMobile) {
      drag(element);
    }
  };

  if (metadata.cardClass) {
    const CardClass = metadata.cardClass;

    if (props.condensed) {
      return (
        <GlobalContext.Consumer>
          {(context) => (
            <div className={`list-card-wrapper ${className}`}>
              <div className={`condensed-card ${archivedClassName}`}>
                <CardClass
                  card={card}
                  cardProps={cardProps}
                  actionProps={actionProps}
                  context={props.context}
                  lookups={lookups}
                />
              </div>
            </div>
          )}
        </GlobalContext.Consumer>
      );
    }

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div
            className={`list-card-wrapper ${className} ${
              selected === true ? "selected" : ""
            } ${context.platform.isMobile && "mobile"}`}
            onClick={onClick}
            onMouseDown={onMouseDown}
            ref={registerRef}
            style={{ height: 146 }}
          >
            <div
              className={`list-card ${statusClassName || ""} ${archivedClassName}`}
              style={props.style}
            >
              <CardClass
                card={card}
                cardProps={cardProps}
                actionProps={actionProps}
                context={props.context}
                lookups={lookups}
              />
            </div>
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }

  const renderUnsupportedCard = () => {
    return (
      <>
        <div className="list-card-right">
          <h2>
            {`Module '${module}' not supported`}
          </h2>
          <div className="list-card-details">
          </div>
        </div>
      </>
    );
  };

  if (props.condensed) {
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className={`list-card-wrapper ${className}`}>
            <div className={`condensed-card ${archivedClassName}`}>
              {renderUnsupportedCard()}
            </div>
          </div>
        )}
      </GlobalContext.Consumer>
    );
  } else if (props.mini) {
    return (
      <GlobalContext.Consumer>
        {(context) => 
          <div className={`mini-card ${className || ""} ${archivedClassName} selectable`}>
            {renderUnsupportedCard()}
          </div>}
      </GlobalContext.Consumer>
    );
  } else {
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div
            className={`list-card-wrapper ${className} ${
              selected === true ? "selected" : ""
            } ${context.platform.isMobile && "mobile"}`}
            onClick={onClick}
            onMouseDown={onMouseDown}
            ref={registerRef}
            style={{ height: 146 }}
          >
            <div
              className={`list-card ${statusClassName || ""} ${archivedClassName}`}
              style={props.style}
            >
              {renderUnsupportedCard()}
            </div>
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
});