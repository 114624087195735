import React from "react";

import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import { CardActionsProps } from "components/card/CardContracts";
import CheckBox from "components/controls/CheckBox";

import "./CardActions.scss";

export default function CardActions(props: CardActionsProps) {
  const { card } = props;

  return (
    <GlobalContext.Consumer>
      {(context) =>
        <div className="list-card-actions">
          {props.showNavigateTo && !props.showTemplate && (
            <div className="user-card-open">
              <i
                className="far fa-external-link"
                onClick={props.onNavigateTo ? props.onNavigateTo : () => context.detailsPane.open(props.card)}
              ></i>
            </div>
          )}
          {props.showTemplate && (
            <i
              className={props.readOnly ? "far fa-external-link" : "far fa-pencil pointer"}
              onClick={() => props.onOpenTemplate(context)}
            ></i>
          )}
          {!props.condensed && props.showCheckbox && (
            <CheckBox
              className="list-card-checkbox"
              onChange={props.onCheckChange}
              checked={props.checked}
            />
          )}
          {props.showDelete && (
            <div
              className="user-card-remove"
              title={arxs.t("controls.taglabel.delete")}
            >
              <i
                className="far fa-trash-alt pointer"
                onClick={props.onDelete}
              ></i>
            </div>
          )}
        </div>}
    </GlobalContext.Consumer>
  );
};
