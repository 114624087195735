import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import { IconOverview } from "components/controls/images/IconOverview";


export default class Icons extends FormItemEditorController {
    lookups = {
        iconMap: {}
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    renderIconControl = (context) => {
        const { field, readOnly, module } = this.props;
        const value = field.getter();
        const data = value.data || [];
        let settings = value.settings;

        const onAddIcon = (state) => {
            if (state && Object.keys(state).some(x => x)) {
                let newData = data || [];
                newData = newData.concat((Object.values(state) || []).map(x => x.iconId));
                field.setter({ ...value, data: newData, settings });
            }
        }

        const onDeleteIcon = (state) => {
            if (state && state.iconId) {
                let newData = data.filter(x => x !== state.iconId);
                field.setter({ ...value, data: newData, settings });
            }
        }

        const iconData = this.state.iconMap
            && Object.keys(this.state.iconMap).some(x => x)
            && data.flatMap(x => {
                const icon = this.state.iconMap[x];
                return icon ? [icon] : [];
            });

        const renderIcons = () => {
            return <IconOverview
                className="field full-width"
                key="icons"
                readOnly={readOnly}
                data={iconData}
                onAdd={onAddIcon}
                onDelete={onDeleteIcon}
                title=""
                module={module}
            />
        }

        const onNumberOfColumnsChange = (state) => {
            if (state && state.id) {
                let newSettings = settings || {};
                newSettings.numberOfColumns = state.id;
                field.setter({ ...value, settings: newSettings });
            }
        }

        const renderSettings = () => {
            const values = [1, 2, 3, 4, 5].map(x => ({ id: x, name: x }));
            const selected = settings && settings.numberOfColumns ? { id: settings.numberOfColumns, name: settings.numberOfColumns } : { id: 5, name: 5 };
            return <div className="field icon-settings">
                <label>{arxs.t("forms.items.icons.number_of_columns")}</label>
                <DropDown
                    id={`icon-settings-number-of-columns`}
                    key={`icon-settings-number-of-columns`}
                    className="number-of-columns"
                    items={values}
                    selected={selected}
                    onChange={onNumberOfColumnsChange}
                    readOnly={readOnly}
                    disableClearButton={true}
                />
            </div>
        }

        return <div className="icons-container">
            {renderSettings()}
            {renderIcons()}
        </div>;

    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderIconControl(context)}
                    </div>
                    {this.renderEnd()}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}