import React from "react";
import arxs from "infra/arxs";
import NumericEditor from "components/controls/NumericEditor";

export default function NumericRiskAnalysisValue(props) {
  const { value, onChange, type, readOnly } = props;

  if (type === "number") {
    const serialize = (value) => {
      return value && JSON.stringify({ type, value });
    };
    const deserialize = (rawValue) => {
      try {
        const payload = rawValue && JSON.parse(rawValue);
        return payload && payload.value;
      } catch {
        arxs.logger.error("NumericRiskAnalysisValue: Couldn't deserialize {rawValue}", rawValue);
      }
      return null;
    };

    const displayValue = deserialize(value);

    if (readOnly) {
      return <span>{displayValue}</span>;
    }

    const options = {
      style: "decimal",
    };
    const placeholder = arxs.t("kanban.common.riskValue");

    return <NumericEditor
      spinners={true}
      min={0}
      max={99999}
      value={displayValue}
      onChange={(value) => onChange(serialize(value))}
      format={options}
      placeholder={placeholder}
    />;
  }

  return <input />;
};