import arxs from "infra/arxs";
import { OriginModuleEnum } from 'infra/api/contracts';
import GlobalActions from "modules/GlobalActions";
import Toaster from "components/util/Toaster";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import { createSignaturePopup } from "components/controls/signature/SignaturePopup";
import { createReportPreviewPopup } from "components/controls/ReportPreviewPopup";

class CommissioningActions {
  getActions = (module) => {
    return GlobalActions.getActions(module)
      .concat([
        {
          name: "complete_form",
          module: module,
          icon: arxs.actions.icons["complete_form"],
          getTitle: () => arxs.actions.titles["complete_form"],
          onClick: (state) => this.editForm(state),
        },
        {
          name: "finalize",
          module: module,
          icon: arxs.actions.icons["finalize"],
          getTitle: () => arxs.actions.titles["finalize"],
          onClick: (state) => this.finalize(state),
        },
        {
          name: "sign",
          module: module,
          icon: arxs.actions.icons["sign"],
          getTitle: () => arxs.actions.titles["sign"],
          onClick: (state) => this.sign(state),
        },
        {
          name: "set_inprocess",
          module: module,
          icon: arxs.actions.icons["set_inprocess"],
          getTitle: () => arxs.actions.titles["set_inprocess"],
          onClick: (state) => this.setInProcess(state),
        },
      ]);
  };

  editForm(state) {
    const { cards, history } = state;
    const card = cards[0];
    if (card && card.id) {
      history.push({
        pathname: `/commissioning/form/${card.id}`,
      });
    }
  }

  completeForm = (state, card, values, action) => {
    return new Promise((resolve, reject) => {
      const context = state.context;
      if (card && card.id) {
        arxs.ApiClient.safety.commissioning
          .editForm({ id: card.id, values, action })
          .then(() => {
            Toaster.success(arxs.t("actions.form.save.confirmation"));
            context.popup.close();
            resolve();
          });
      }
    });
  }

  finalize = (state) => {
    const { cards, context } = state;
    const onConfirm = (ids) => {
      arxs.ApiClient.safety.commissioning
      .finalize(ids)
      .then(() => {
        Toaster.success(arxs.t("actions.commissioning.finalize_confirmation"));
      });

      context.popup.close();
      context.popup.close();
    }
    return new Promise((resolve, reject) => {
      if (cards) {
        const popup = createReportPreviewPopup({
          reports: cards.map(x => ({id: x.id, 
            module: OriginModuleEnum.Commissioning, 
            uniqueNumber: x.uniqueNumber,
            reportAlias: "Commissioning"})),
          onConfirm,
          title: arxs.t("actions.commissioning.finalize_preview_title"),
          confirmation: arxs.t("actions.commissioning.finalize_confirmation_question")
        });

        context.popup.show(popup);        
      }
    });
  }

  sign = (state) => {
    return new Promise((resolve, reject) => {
      const { cards, context } = state;
      if (cards) {
        const attachmentToStoredFileIdMap = cards
          .flatMap(x => x.attachmentInfo.storedFiles)
          .toDictionary(x => x.id, x => x.storedFileId);

        const cardToAttachmentMap = cards
          .toDictionary(x => x.id, x => x.attachmentInfo.attachments
            .filter(a => a.type === "MainDocument")
            .flatMap(a => a.value || [])
            .filter(v => !v.isDeleted)
            .map(v => v.id)[0]
          );

        const popup = createSignaturePopup({
          module: OriginModuleEnum.Commissioning,
          documents: cards
            .map(x => ({
              id: x.id,
              module: x.module,
              name: x.name,
              uniqueNumber: x.uniqueNumber,
              storedFileId: attachmentToStoredFileIdMap[cardToAttachmentMap[x.id]]
            })),
          onConfirm: () => {
            context.popup.close();
          }
        });
        context.popup.show(popup);
      }
    });
  }

  setInProcess = (state) => {
    const { context } = state;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.safety.commissioning
        .setInProcess(data)
        .then(() =>
          Toaster.success(arxs.t("actions.commissioning.set_inprocess_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.commissioning.set_inprocess_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  dropActions = () => {
    return {
      onDropInProcess: (state) => {
        this.setInProcess(state);
      },
    };
  };
}
export default new CommissioningActions();
