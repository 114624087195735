import React from "react";
import { Calendar as KendoCalendar } from '@progress/kendo-react-dateinputs';

import "./Calendar.scss";

export interface CalendarProps {
    value?: Date,
    onChange: (value: Date | undefined | null) => void;
}

export default function Calendar(props: CalendarProps) {
    return <KendoCalendar
        navigation={false}
        value={props.value}
        onChange={event => props.onChange && props.onChange(event.value)}
    />
}