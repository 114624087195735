import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactDOMServer from 'react-dom/server';
import ResizePanel from 'external/react-resize-panel/ResizePanel';

import { BryntumScheduler } from '@bryntum/scheduler-react-thin';
import { LocaleManager, DateHelper, LocaleHelper } from '@bryntum/core-thin';
import { NlLocale } from '@bryntum/scheduler-thin/lib/localization/Nl';

import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { OriginModuleEnum, RelationshipType, TaskStatus } from "infra/api/contracts";
import { createScheduledActionManager } from "infra/tools/ReactExtensions";
import { getOrRetry } from "infra/tools/AsyncExtensions";

import Avatar from "components/controls/images/Avatar";
import TextInput from 'components/controls/TextInput';
import { createCardLookup } from 'components/shell/CardLookup/CardLookup';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import Button from "components/controls/Button";
import Calendar from 'components/controls/Calendar';

import EmployeeSelector from './EmployeeSelector';
import TeamSelector from './TeamSelector';
import PlannableItemSelector from './PlannableItemSelector';
import PlanningActions from "./PlanningActions";
import Drag from "./PlanningDrag";
import { ObjectDocumentType } from "infra/api/contracts";

import '@bryntum/core-thin/core.classic-light.css';
import '@bryntum/grid-thin/grid.classic-light.css';
import '@bryntum/scheduler-thin/scheduler.classic-light.css';
import "./Planning.scss";

const _periods = ["day", "week", "month"];
const _planningSettingsLocalStorageKey = 'PlanningSettings';

export default class Planning extends Component {
  idMap = {}

  lookups = {
    employees: [],
    employeeMap: {},
    userRoles: [],
    userRoleMap: {},
    taskMap: {},
    periodicalMap: {},
    maintenanceMap: {},
    inspectionMap: {},
    taskRequestMap: {},
    planningMoments: [],
  }

  MODULE_SETTINGS_KEY = "planning.user";

  module = OriginModuleEnum.Planning;

  constructor(props) {
    super(props);

    LocaleManager.applyLocale('Nl');

    const settings = this.deserializeFromLocalStorage(JSON.parse(localStorage.getItem(_planningSettingsLocalStorageKey)));

    const date = settings.date || new Date();
    const initialDate = date;

    this.state = {
      ...this.lookups,
      selectedEmployees: {},
      selectedEmployeesInOrder: [],
      selectedTeams: {},
      selectedResources: [],
      isSideBarOpen: true,
      isPlannableItemsOpen: true,
      isAllDay: false,
      initialDate: initialDate,
      date: date,
      searchTerm: "",
      filtered: [],
      assignments: [],
      dependencies: [],
      userRoles: [],
      userRoleMap: {},
      modelFields: {
        id: 'id',
        title: 'title',
        start: 'start',
        end: 'end'
      },
      selected: null,
      activeTab: null,
      schedulerRef: React.createRef(),
      plannableItemSelectorRef: React.createRef(),
      drag: null,
      loaded: false,
      plannableItemSelectorHeight: 320,
      infiniteScroll: false,
      ...settings,
    };

    this.scheduledActionManager = createScheduledActionManager();
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
    if (this.scheduledActionManager) {
      this.scheduledActionManager.dispose();
    }
  }

  componentDidMount() {
    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => {
        if (lookups.employees) {
          lookups.employees = lookups.employees.orderBy(x => x.name);
        }
        if (lookups.userRoles) {
          lookups.userRoles = lookups.userRoles.orderBy(x => x.name);
        }

        let { loaded } = this.state;

        if (lookups.planningMoments) {
          lookups.planningMoments = lookups.planningMoments.map(x => ({
            ...x,
            start: new Date(x.start),
            end: new Date(x.end),
          }));

          loaded = true;
        }

        this.setState({ ...lookups, loaded }, () => this.scheduledActionManager.schedule("refresh", this.refresh, 50));
      })
    };

    const schedule = this.getSchedulerInstance();
    const drag = new Drag({
      grid: {},
      schedule,
      outerElement: this.state.plannableItemSelectorRef.current,
      handleDropCard: this.handleDropCard
    });
    this.setState({ drag });

    const snapshotPlannableItemsHeight = () => {
      const panel = window.document.getElementsByClassName("plannable-items-panel")[0];
      if (panel && panel.children && panel.children[1]) {
        const height = panel.children[1].style.height;
        if (height && height !== "0px") {
          const heightValue = parseInt(height);
          if (heightValue !== this.state.plannableItemsHeight) {
            this.setStateAndPersist({ plannableItemsHeight: heightValue });
          }
        }
      }
    };
    this.scheduledActionManager.schedule("snapshot_plannable_item_height", snapshotPlannableItemsHeight, 250);

    const securityContext = arxs.securityContext.buildForUserContext();
    this.setState({ securityContext });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loaded !== prevState.loaded) {
      this.scheduledActionManager.schedule("scroll_to_date"
      , () => {
        this.applyPeriod(this.state.initialDate);
        this.scrollToDate(this.state.initialDate);
      }
      , 50);
    }
  }

  deserializeFromLocalStorage = (payload) => {
    if (!payload) {
      return {};
    }
    const date = new Date(payload.date);

    return {
      isSideBarOpen: !!payload.isSideBarOpen,
      isPlannableItemsOpen: !!payload.isPlannableItemsOpen,
      plannableItemsHeight: payload.plannableItemsHeight,
      isAllDay: !!payload.isAllDay,
      searchTerm: payload.searchTerm || "",
      activeTab: payload.activeTab || "",
      period: payload.period || "week",
      date: isNaN(date) ? new Date() : date,
      assignments: payload.assignments || [],
      selectedEmployees: (payload.selectedEmployees || []).toDictionary(x => x, _ => true),
      selectedEmployeesInOrder: payload.selectedEmployees || [],
      selectedTeams: (payload.selectedTeams || []).toDictionary(x => x, _ => true),
    };
  }

  serializeToLocalStorage = (state) => {
    return {
      isSideBarOpen: state.isSideBarOpen,
      isPlannableItemsOpen: state.isPlannableItemsOpen,
      plannableItemsHeight: state.plannableItemsHeight,
      isAllDay: state.isAllDay,
      searchTerm: state.searchTerm,
      activeTab: state.activeTab,
      period: state.period,
      date: state.date.toISOString(),
      selectedEmployees: state.selectedEmployeesInOrder,
      selectedTeams: Object.keys(state.selectedTeams),
    };
  }

  setStateAndPersist = (state, callback) => {
    this.setState(state, () => {
      if (callback) callback();
      const stateToSerialize = this.serializeToLocalStorage(this.state);
      localStorage.setItem(_planningSettingsLocalStorageKey, JSON.stringify(stateToSerialize));
    });
  }

  handleDateChange = (date) => {
    this.setStateAndPersist({ date }
      , () => {
        // this.refresh();
        this.applyPeriod(date);
        this.scrollToDate(date);
      });
  }

  handleEmployeesSelectionChange = (selectedEmployees) => {
    this.handleResourceChange(selectedEmployees, this.state.selectedTeams);
  }

  handleTeamsSelectionChange = (selectedTeams) => {
    this.handleResourceChange(this.state.selectedEmployees, selectedTeams);
  }

  handleResourceChange = (selectedEmployees, selectedTeams) => {
    const newlySelectedEmployeeKeys = Object.keys(selectedEmployees);
    const previouslySelectedEmployeeKeys = Object.keys(this.state.selectedEmployees);
    const addedEmployeeKeys = newlySelectedEmployeeKeys.except(previouslySelectedEmployeeKeys);
    const removedEmployeeKeys = previouslySelectedEmployeeKeys.except(newlySelectedEmployeeKeys);

    const newlySelectedTeamKeys = Object.keys(selectedTeams);
    const previouslySelectedTeamKeys = Object.keys(this.state.selectedTeams);
    const addedTeamKeys = newlySelectedTeamKeys.except(previouslySelectedTeamKeys).toDictionary(x => x, _ => true);
    const removedTeamKeys = previouslySelectedTeamKeys.except(newlySelectedTeamKeys).toDictionary(x => x, _ => true);
    const addedTeamEmployees = this.state.userRoles.filter(x => addedTeamKeys[x.id]).flatMap(x => x.users).map(x => x.id);
    const removedTeamEmployees = this.state.userRoles.filter(x => removedTeamKeys[x.id]).flatMap(x => x.users).map(x => x.id);

    let selectedEmployeesInOrder = addedTeamEmployees
      .concat(addedEmployeeKeys)
      .concat(this.state.selectedEmployeesInOrder)
      .except(removedTeamEmployees)
      .except(removedEmployeeKeys)
      .filter(x => this.state.employeeMap[x])
      .distinct();

    if (selectedEmployeesInOrder.length === 0) {
      selectedEmployeesInOrder = previouslySelectedEmployeeKeys.slice(0, 1);
    }

    this.setStateAndPersist({
      selectedTeams,
      selectedEmployees: selectedEmployeesInOrder.toDictionary(x => x, _ => true),
      selectedEmployeesInOrder
    }, this.refresh);
  }

  handleSearchTermChange = (e) => {
    const searchTerm = (e.target.value || "").toLowerCase();
    if (this.state.searchTerm === searchTerm) { return; }

    const scheduler = this.getSchedulerInstance();
    if (!scheduler) { return; }

    scheduler.eventStore.filter({
      filters: event => event.name.toLowerCase().includes(searchTerm),
      replace: true,
      disabled: !searchTerm
    });
    this.setStateAndPersist({ searchTerm });
  }

  toggleSidebar = () => {
    this.setStateAndPersist({ isSideBarOpen: !this.state.isSideBarOpen });
  }

  togglePlannableItems = () => {
    this.setStateAndPersist({ isPlannableItemsOpen: !this.state.isPlannableItemsOpen });
  }

  getActualDuration = (subjectId, subjectModule) => {
    const relevantPlanningMoments = this.state.planningMoments
      .filter(x => x.subject && x.subject.id === subjectId && x.subject.module === subjectModule)
      .filter(x => !x.isDeleted)
      ;
    const actualDuration = relevantPlanningMoments
      .map(x => {
        if (x.start && x.end) {
          const duration = (x.end.getTime() - x.start.getTime()) / (1000 * 60 * 60);
          return duration * [x.assignees || []].length;
        }
        return 0;
      })
      .sum();
    return actualDuration;
  }

  generatePlanningMomentTitle = (card) => card.title && card.title.length > 87
    ? `${card.uniqueNumber} - ${card.title.substring(0, 84)}...`
    : `${card.uniqueNumber} - ${card.title}`;

  mapSubjectToPlanningMomentActions = (subject) => {
    if (!subject
      || !subject.actions
      || (subject.module !== OriginModuleEnum.Task
        && subject.module !== OriginModuleEnum.PeriodicControl
        && subject.module !== OriginModuleEnum.PeriodicMaintenance
        && subject.module !== OriginModuleEnum.RiskAnalysis)) {
      return [];
    }
    return subject.actions
      .map(action => action.split(":")[0])
      .flatMap(action => {
        if (action === "edit") return ["edit", "reassign", "delete"];
        if (action === "revise_planning") return ["revise"];
        return [];
      });
  }

  refresh = async () => {
    const currentDateTime = new Date();

    // Selected employees/teams first, then order alphabetically
    const employees = this.state.employees.orderBy(x => this.state.selectedEmployees[x.id] ? "000" + x.name : x.name);
    const userRoles = this.state.userRoles.orderBy(x => this.state.selectedTeams[x.id] ? "000" + x.name : x.name);
    const userRoleMap = userRoles
      .filter(x => this.state.selectedTeams[x.id])
      .flatMap(x => x.users
        .map(u => [u.id, x])
      )
      .groupBy(x => x[0], x => x[1]);

    const pristine = this.state.planningMoments
      .filter(x => !x.isDeleted)
      .filter(x => !x.generationDate || new Date(x.generationDate) > currentDateTime)
      ;

    const generateId = (...args) => arxs.getStableId("planning", ...args);

    const selectedResources = this.state.selectedEmployeesInOrder
      .map(x => this.state.employeeMap[x])
      .filter(x => x)
      .orderBy(x => (userRoleMap[x.id] || []).map(x => x.name).join(", "));

    const data = pristine
      .orderBy(x => x.createdAt)
      .map(x => {

        let title = x.title;
        let status = "";
        let progress = 0;
        let subject;
        if (x.subject) {
          subject = arxs.Api.lookups.resolveSubject(x.subject);
          title = title || this.generatePlanningMomentTitle(subject);
          status = subject.status;

          switch (subject.module) {
            case OriginModuleEnum.Task:
              const actualDuration = this.getActualDuration(subject.id, subject.module);
              progress = Math.min(1, subject.estimatedDuration ? (actualDuration / subject.estimatedDuration) : 0);
              break;
            default: break;
          }
        }

        return {
          id: generateId("moments", x.id),
          planningMomentId: x.id,
          name: title,
          actions: (x.actions && x.actions.length > 0) ? x.actions : this.mapSubjectToPlanningMomentActions(subject),
          status,
          progress,
          eventColor: this.mapEventColor(status, x.subject && x.subject.module),
          subject: x.subject,
          subjectId: x.subject && x.subject.id,
          module: x.subject && x.subject.module,
          endDate: x.end,
          startDate: x.start,
          duration: (x.end - x.start) / (1000 * 60 * 60 * 24),
          isAllDay: x.isAllDay,
          assignees: x.assignees || [],
          confirmed: x.confirmed,
          cls: x.confirmed && "confirmed",
        };
      });

    const linkRefToKey = (linkRef) => `${linkRef.module}-${linkRef.id}`;

    const planningMomentIdBySubjectIdMap = pristine
      .filter(x => x.subject)
      .toDictionary(x => linkRefToKey(x.subject), x => generateId("moments", x.id));

    const dependencies = pristine
      .filter(x => x.succeededBy)
      .flatMap(planningMoment => planningMoment.succeededBy
        .map(link => ({ from: linkRefToKey(planningMoment.subject), to: linkRefToKey(link) }))
      )
      .map(x => ({
        id: generateId("dependencies", `${x.from}-${x.to}`),
        from: planningMomentIdBySubjectIdMap[x.from],
        to: planningMomentIdBySubjectIdMap[x.to],
      }));

    const selectedResourceIds = selectedResources.toDictionary(x => x.id);

    const assignments = selectedResources.length > 0 
      ? data.flatMap(x => x.assignees
          .filter(assignee => selectedResourceIds[assignee.id])
          .map(assignee => ({ id: generateId("assignments", assignee.id, x.id), resourceId: assignee.id, eventId: x.id })))
          .distinct(x => x.id)
      : [];

    const filtered = selectedResources.length > 0 ? data : [];

    this.resetSelectedPlanningMomentIfFiltered(data);

    this.setState({ selectedResources, filtered, assignments, employees, userRoles, userRoleMap, dependencies });

    const scheduler = this.getSchedulerInstance();

    scheduler.suspendRefresh();
    scheduler.project = {
      assignmentStore: {
        useRawData: true,
        data: assignments
      },
      resourceStore: {
        useRawData: true,
        data: selectedResources
      },
      eventStore: {
        useRawData: true,
        data: filtered
      }
    };
    scheduler.resumeRefresh();
    await scheduler.project.commitAsync();
  }

  resetSelectedPlanningMomentIfFiltered = (data) => {
    const { selected, selectedResourceId } = this.state;

    // If no planningmoment was previously selected we have nothing to do
    if (!selected) {
      return;
    }

    const selectedIsInNewData = data.filter(x => selected && x.id === selected.id)[0];
    const selectedIsStillAssigned = selectedIsInNewData && selectedIsInNewData.assignees.some(x => x.id === selectedResourceId);
    if (selectedIsInNewData && selectedIsStillAssigned) {
      this.selectPlanningMoment(selectedIsInNewData, selectedResourceId);
    } else {
      this.selectPlanningMoment();
    }
  }

  selectPlanningMoment = (data, selectedResourceId) => {
    this.setState({ selected: data, selectedResourceId });

    if (data && data.subject) {
      const card = { ...arxs.Api.lookups.resolveSubject(data.subject), module: data.subject.module };
      this.context.detailsPane.open(card);
    } else {
      this.context.detailsPane.open();
    }
  }

  handleDataChange = (context, { created, updated, deleted }) => {
    if (created.length > 0) {
      const item = created[0];

      const inputPopup = createInputPopup(context,
        arxs.t("planning.add_manual_planningmoment_title"),
        (title) => {
          arxs.ApiClient.facilitymanagement.planning.post({
            start: item.start,
            end: item.end,
            assignees: item.assignees,
            title: title
          }).then(() => {
            this.refresh();
          });
        },
        true,
        true,
        arxs.t("planning.add_manual_planningmoment"),
        arxs.t("common.save"),
        arxs.t("common.cancel"),
        false);

      context.inputPopup.show(inputPopup);
    }
    if (updated.length > 0) {
      const item = updated[0];

      let subject;
      if (item.module !== undefined && item.subjectId !== undefined) {
        subject = { module: item.module, id: item.subjectId };
      }

      if (item.actions) {
        if (item.actions.contains("edit")) {
          arxs.ApiClient.facilitymanagement.planning.post({
            id: item.id,
            start: item.start,
            end: item.end,
            isAllDay: item.isAllDay,
            assignees: item.assignees,
            subject,
            title: item.title,
          });
        } else if (item.actions.contains("revise")) {
          arxs.ApiClient.facilitymanagement.planning.revise({
            id: item.id,
            start: item.start,
            end: item.end,
          });
        }
      }
    }
  }

  handlePlanExistingCard = (context, data, resource) => {
    const onApplyFilter = state => {
      const objectId = Object.keys(state)[0];
      const objectModule = state[objectId];

      arxs.ApiClient.facilitymanagement.planning.post({
        start: data.startDate,
        end: data.endDate,
        assignees: [{ id: resource.id }],
        subject: { module: objectModule, id: objectId }
      }).then(this.refresh());
      context.popup.close();
    };
    const securityContext = arxs.securityContext.buildForUserContext();
    const plannableStatusMap = {
      [TaskStatus.InProcess]: true,
      [TaskStatus.OnHold]: true,
      [TaskStatus.Active]: true,
      [TaskStatus.ExecutionOverdue]: true,
    }
    const cardLookup = createCardLookup({
      modules: [OriginModuleEnum.Task],
      onApplyFilter,
      singleSelection: true,
      securityContext,
      filterPredicate: (card) => plannableStatusMap[card.status]
    });
    this.setState({ cardLookup }, () => context.popup.show(this.state.cardLookup));
  }

  handleDropCard = (planningMoment) => {
    arxs.ApiClient.facilitymanagement.planning.post(planningMoment).then(this.refresh);
  }

  getSchedulerInstance = () => (this.state.schedulerRef.current || {}).schedulerInstance;

  getEventRecordById = (id) => {
    return getOrRetry(
      () => {
        const scheduler = this.getSchedulerInstance();
        const eventRecord = scheduler.eventStore.allRecords
          .filter(x => x.data.id === id)[0];
        return eventRecord;
      })
      .catch(_ => {
        arxs.logger.error("Planning::getEventRecordById didn't return a value on time");
        return new Promise(() => { });
      });
  }

  deletePlanningMoment = (context, data, assigneeId) => {
    this.getEventRecordById(data.id)
      .then(eventRecord => {
        const planningMoment = {
          ...this.mapFromEventRecord(eventRecord),
          assigneeId,
        };
        PlanningActions.delete({ context, planningMoment });
      });
  }

  completePlanningMoment = (context, data) => {
    this.getEventRecordById(data.id)
      .then(eventRecord => {
        const planningMoment = this.mapFromEventRecord(eventRecord);
        PlanningActions.complete({ context, planningMoment, canDelete: false });
      });
  }

  createFollowUp = (context, data) => {
    this.getEventRecordById(data.id)
      .then(eventRecord => {
        const planningMoment = this.mapFromEventRecord(eventRecord);
        PlanningActions.createFollowUp({ context, planningMoment });
      });
  }

  editPlanningMoment = (context, data, resource) => {
    this.getEventRecordById(data.id)
      .then(eventRecord => {
        const isCreating = eventRecord.meta.isCreating;

        let undo = null;

        const showEditPopup = () => {
          const onSubmit = (data) => { };

          const planningMoment = this.mapFromEventRecord(eventRecord, resource);
          PlanningActions.edit({ context, planningMoment, onSubmit, onCancel: undo });
        };

        if (isCreating) {
          undo = () => {
            const scheduler = this.getSchedulerInstance();
            scheduler.eventStore.remove(eventRecord);
          };

          this.scheduledActionManager.schedule("show_create_popup"
            , () => {
              const options = [
                { title: arxs.t("planning.add_ticket"), handle: () => this.handlePlanExistingCard(context, data, resource) },
                { title: arxs.t("planning.add_manual_planningmoment"), handle: showEditPopup }
              ];
              context.optionPopup.show(arxs.t("kanban.actions.add_to_selection"), options, { onClose: undo });
            }
            , 50);
        } else {
          if ((data.actions || []).contains("edit")) {
            this.scheduledActionManager.schedule("show_edit_popup", showEditPopup, 50);
          }
        }
      });
  }

  handleIsAllDay = () => {
    this.setStateAndPersist({ isAllDay: !this.state.isAllDay }, this.scrollToDate);
  }
 
  applyPeriod = (date) => {
    const scheduler = this.getSchedulerInstance();
    const centerDate = date || scheduler.viewportCenterDate;

    let start, end;
    if (this.state.period === "day") {
      start = arxs.dateTime.dateAtMidnight(centerDate);
      end = arxs.dateTime.addDays(start, 1);
    } else if (this.state.period === "week") {
      start = arxs.dateTime.dateAtMidnight(arxs.dateTime.dateOnDayOfWeek(centerDate, 1));
      end = arxs.dateTime.addDays(start, 7);
    } else {
      start = arxs.dateTime.dateAtMidnight(arxs.dateTime.dateOnDayOfMonth(centerDate, 1));
      end = arxs.dateTime.dateAtMidnight(arxs.dateTime.addMonths(start, 1));
    }

    scheduler.setTimeSpan(start, end);
  }

  scrollToDate = (date) => {
    const scheduler = this.getSchedulerInstance();
    const centerDate = date || scheduler.viewportCenterDate;
    if (centerDate) {
      scheduler.scrollToDate(centerDate, { block: "center", animate: 0 });
    }
  }

  handleDayView = () => {
    this.setState({ period: "day" }, this.applyPeriod);
  }

  handleWeekView = () => {
    this.setState({ period: "week" }, this.applyPeriod);
  }

  handleMonthView = () => {
    this.setState({ period: "month" }, this.applyPeriod);
  }

  selectCardAndAddAssignees = (card) => {
    if (card) {
      const assignees = card.relationships.filter(x => x.type === RelationshipType.Assignee);
      const employees = assignees.filter(x => x.employee).map(x => x.employee.id).toDictionary(x => x, _ => true);
      const userRoles = assignees.filter(x => x.userRole).map(x => x.userRole.id).toDictionary(x => x, _ => true);

      const selectedEmployees = { ...this.state.selectedEmployees, ...employees };
      const selectedTeams = { ...this.state.selectedTeams, ...userRoles };
      this.handleResourceChange(selectedEmployees, selectedTeams);

      // Scroll selected card's matching planning moment into view
      const matchingPlanningMoment = this.state.filtered.filter(x => !x.isDeleted && x.subject && x.subject.id === card.id)[0];
      if (matchingPlanningMoment) {
        this.scrollToDate(matchingPlanningMoment.startDate);
      }
    }
    this.setState({ selected: null, selectedResourceId: null });
    this.context.detailsPane.open(card);
  }

  handleChangeActiveTab = (activeTab) => {
    this.setStateAndPersist({ activeTab });
  }

  mapEventColor = (status, module) => {
    let color = "grey";

    switch (status) {
      case TaskStatus.InProcess:
      case TaskStatus.OnHold:
        color = "orange";
        break;
      case TaskStatus.Active:
      case TaskStatus.ToVerify:
        color = "blue";
        break;
      case TaskStatus.ExecutionOverdue:
        color = "red";
        break;
      case TaskStatus.Completed:
      case TaskStatus.MultiYearPlan:
        color = "green";
        break;
      default: break;
    }

    if (module) {
      switch (module) {
        case OriginModuleEnum.Periodical:
          color = `${color}-gradient`;
          break;
        default: break;
      }
    }

    return color;
  }

  mapFromEventRecord = (eventRecord, resource) => {
    const assignees = resource
      ? [{ id: resource.id }]
      : (eventRecord.resources
        ? eventRecord.resources.map(x => ({ id: x.data.id }))
        : eventRecord.assignees);

    const planningMoment = {
      id: eventRecord.data.planningMomentId,
      start: eventRecord.data.startDate,
      end: eventRecord.data.endDate,
      title: eventRecord.name,
      isAllDay: eventRecord.allDay,
      assignees: assignees,
      subject: eventRecord.subject ? { ...eventRecord.subject } : null,
      actions: eventRecord.data.actions,
    };

    return planningMoment;
  }

  onStartDragPlannableItem = (e, card) => {
    //const actualDuration = this.getActualDuration(card.id, card.module);
    const estimatedDuration = card.estimatedDuration ? card.estimatedDuration : 1;
    // const remainingDuration = Math.max(0.25, estimatedDuration - actualDuration);
    this.state.drag.startDraggingPlanningMoment({
      name: this.generatePlanningMomentTitle(card),
      duration: estimatedDuration,
      durationMS: estimatedDuration * 60 * 60 * 1000,
      durationUnit: "h",
      status: card.status,
      eventColor: this.mapEventColor(card.status, card.module),
      subject: { id: card.id, module: card.module },
      subjectId: card.id,
      module: card.module,
    });
  }

  eventRenderer = ({ eventRecord, renderData }) => {
    const name = eventRecord.data.name;
    const progress = eventRecord.data.progress;
    const value = 100 * (progress || 0);
    if (value) {
      renderData.children.push({
        className: "arxs-event progress",
        style: {
          width: `calc(${value}% + 20px)`
        },
        html: `<i class="${this.moduleToIcon(eventRecord.data)}"></i><span>${name}</span>`
      });
    } else {
      renderData.children.push({
        className: "arxs-event",
        html: `<i class="${this.moduleToIcon(eventRecord.data)}"></i><span>${name}</span>`
      });
    }
  }

  eventEditValidator = {
    validatorFn(e) {
      const { eventRecord, newResource, resourceRecord } = e;
      const data = (eventRecord || {}).data || {};
      const actions = data.actions || [];

      const resourceChangedTo = (newResource || {}).data || {};
      const resourceChangedFrom = (resourceRecord || {}).data || {};
      const wasResourceChanged = newResource && resourceChangedTo.id !== resourceChangedFrom.id;

      if (wasResourceChanged) {
        return actions.contains("reassign");
      }
      return actions.contains("revise");
    }
  }

  moduleToIcon = (data) => arxs.modules.icons[data.module];

  schedulerColumns = [{
    text: "",
    field: "name",
    htmlEncode: false,
    width: 200,
    renderer: ({ record }) => {
      const data = (record || {}).data || {};
      const attachmentInfo = data.attachmentInfo || {};
      const firstImageId = ((((attachmentInfo.attachments || []).filter(x => x.type === ObjectDocumentType.Image)[0] || {}).value || []).filter(x => !x.isDeleted)[0] || {}).id;
      const firstImageUrl = (firstImageId && ((attachmentInfo.storedFiles || []).filter(x => x.id === firstImageId)[0].url)) || "";
      const userRoles = (this.state.userRoleMap[data.id] || []).map(x => x.name).join(", ");
      const element = <div className="scheduler-row-header">
        <Avatar key={data.id} fullName={data.name} src={firstImageUrl} />
        <div>
          <div className="user-name">{data.name}</div>
          <div className="user-role">{userRoles}</div>
        </div>
      </div>;
      return ReactDOMServer.renderToString(element);
    }
  }]

  schedulerListeners = (() => {
    const self = this;
    return {
      horizontalScroll() {
        if (self.scheduledActionManager) {
          self.scheduledActionManager.schedule("horizontal_scroll"
            , () => {
              if (this.viewportCenterDate) {
                const date = DateHelper.floor(this.viewportCenterDate, '1 day');
                self.setStateAndPersist({ date });
              }
            }, 100);
        }
      },

      beforeEventAdd: e => {
        e.eventRecord.data.name = arxs.t("planning.new_planning_moment_name");
      },

      beforeEventEdit: e => {
        this.editPlanningMoment(self.context, e.eventRecord.data, e.resourceRecord.data);
        return false;
      },

      beforeEventResizeFinalize: ({ context }) => {
        const data = context.resizedRecord.data.event.data || context.resizedRecord.data;
        const planningMoment = {
          id: data.planningMomentId,
          start: context.startDate.toISOString(),
          end: context.endDate.toISOString(),
          title: data.name,
          isAllDay: data.allDay,
          assignees: data.assignees,
          module: data.module,
          subjectId: data.subjectId,
          actions: data.actions,
        };
        this.scheduledActionManager.schedule("resize_planning_moment"
          , () => this.handleDataChange(context, { created: [], updated: [planningMoment], deleted: [] })
          , 50);
        return false;
      },

      eventClick: (source) => {
        const { filtered, selected } = this.state;
        const { eventRecord } = source;
        const planningMoment = filtered
          .filter(x => x.id === eventRecord.data.id)[0];
        if (selected !== planningMoment) {
          this.selectPlanningMoment(planningMoment, source.assignmentRecord.data.resourceId);
        }
      },

      scheduleClick: (e) => {
        this.selectPlanningMoment();
      },

      beforeEventDelete: (source) => {
        const { eventRecords } = source;
        if (eventRecords.length === 1) {
          this.deletePlanningMoment(self.context, eventRecords[0].data);
        }
        return false;
      },

      eventDrop: (source) => {
        const { eventRecords } = source;
        if (eventRecords) {
          this.handleDataChange(self.context, { created: [], updated: eventRecords.map(this.mapFromEventRecord), deleted: [] });
        } else {
          arxs.logger.error("Planning::Scheduler.eventDrop didn't contained eventRecords");
        }
      },
    };
  })();

  viewPreset = {
    base: "hourAndDay",
    timeResolution: { unit: 'minute', increment: 15 },
  }

  render() {
    const { selected, securityContext, infiniteScroll } = this.state;

    const hasSelectedResources = this.state.selectedResources.length > 0;
    const filtered = hasSelectedResources ? this.state.filtered : [];
    const assignments = hasSelectedResources ? this.state.assignments : [];

    const renderToolbar = (context, planningMoment) => {
      if (!planningMoment) {
        return <></>;
      }
      const subject = (planningMoment.subject && arxs.Api.lookups.resolveSubject(planningMoment.subject)) || {};

      const isTasklike = [OriginModuleEnum.Task, OriginModuleEnum.PeriodicControl, OriginModuleEnum.PeriodicMaintenance, OriginModuleEnum.RiskAnalysis].contains(subject.module);
      const canEdit = planningMoment.actions.contains("edit");
      const canDelete = planningMoment.actions.contains("delete");
      const canConfirm = isTasklike && (subject.actions || []).any(x => x.startsWith("complete:"));
      const canCreateFollowUp = (subject.actions || []).any(x => x.startsWith("create_follow_up:"));

      const confirmationButton = canConfirm
        && <Button className="section"
          title={arxs.t("actions.planning.confirm_planning")}
          onClick={() => this.completePlanningMoment(context, planningMoment)}
        >
          <i className="far fa-calendar-check"></i>
        </Button>;

      const createFollowUpButton = canCreateFollowUp
        && <Button className="section"
          title={arxs.t("actions.task.create_follow_up")}
          onClick={() => this.createFollowUp(context, planningMoment)}
        >
          <i className="far fa-chevron-square-right"></i>
        </Button>;

      const editButton = canEdit
        && <Button className="section"
          title={arxs.t("actions.planning.edit_planning")}
          onClick={() => this.editPlanningMoment(context, planningMoment)}
        >
          <i className="far fa-pencil"></i>
        </Button>;

      const deleteButton = canDelete
        && <Button className="section"
          title={arxs.t("actions.remove")}
          onClick={() => this.deletePlanningMoment(context, planningMoment, this.state.selectedResourceId)}
        >
          <i className="far fa-trash-alt"></i>
        </Button>;

      return [canConfirm, canEdit, deleteButton].any(x => x) && <div className="planning-action-toolbar">
        <div className="section">
          <i className="far fa-times" onClick={() => this.selectPlanningMoment()}></i>
        </div>
        {confirmationButton}
        {createFollowUpButton}
        {editButton}
        {deleteButton}
      </div>;
    };

    return (<GlobalContext.Consumer>
      {(context) => {
        this.context = context;
        return (
          <div className={`planning${this.state.isSideBarOpen ? "" : " closed"}`}>
            <div className="planning-sidebar-wrapper">
              <div className="planning-sidebar-collapser" onClick={this.toggleSidebar}>
                <i className={`fas ${this.state.isSideBarOpen ? "fa-chevron-left" : "fa-chevron-right"}`} />
              </div>
              <div className="planning-sidebar">
                <Calendar
                  navigation={false}
                  value={this.state.date}
                  onChange={this.handleDateChange}
                />
                <Tabs className="planning-sidebar-tabs">
                  <TabList>
                    <Tab>{arxs.t("common.employees")} ({this.state.selectedEmployeesInOrder.length})</Tab>
                    <Tab>{arxs.t("common.teams")} ({Object.keys(this.state.selectedTeams).length})</Tab>
                  </TabList>
                  <TabPanel>
                    <EmployeeSelector
                      data={this.state.employees}
                      selected={this.state.selectedEmployees}
                      onChange={this.handleEmployeesSelectionChange}
                      placeholder={arxs.t("planning.search_for_employee")}
                    />
                  </TabPanel>
                  <TabPanel>
                    <TeamSelector
                      data={this.state.userRoles}
                      selected={this.state.selectedTeams}
                      onChange={this.handleTeamsSelectionChange}
                      placeholder={arxs.t("planning.search_for_team")}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="planning-body">
              <div className="planning-body-header">
                <h1>{arxs.dateTime.formatLongDate(this.state.date)}</h1>
                <div className="planning-body-header-search">
                  <div className={`toggle ${this.state.period === "week" ? "on" : "off"}`} onClick={this.handleWeekView}>
                    <i className="fas fa-calendar-week"></i>
                  </div>
                  <div className={`toggle ${this.state.period === "month" ? "on" : "off"}`} onClick={this.handleMonthView}>
                    <i className="fas fa-calendar-alt"></i>
                  </div>
                  <div className="button" onClick={this.handleIsAllDay}>
                    <i className={this.state.isAllDay ? "fas fa-sun" : "fas fa-moon"}></i>
                  </div>
                  <TextInput
                    value={this.state.searchTerm}
                    onChange={this.handleSearchTermChange}
                    placeholder={arxs.t("planning.search_for_card")}
                    icon="far fa-search" />
                </div>
              </div>
              <div className="planning-container">
                <BryntumScheduler
                  ref={this.state.schedulerRef}
                  columns={this.schedulerColumns}
                  // eventDragFeature={{
                  //   // Allow drag outside of this Scheduler
                  //   constrainDragToTimeline: false
                  // }}
                  eventColor="indigo"
                  // dependencies={dependencies}
                  // resources={this.state.selectedResources}
                  // events={filtered}
                  // assignments={assignments}
                  rowHeight={56}

                  // dependenciesFeature={{ allowCreate: false }}
                  // dependencyEditFeature={false}

                  infiniteScroll={infiniteScroll}

                  viewPreset={this.viewPreset}

                  workingTime={this.state.isAllDay ? null : { fromDay: 0, toDay: 7, fromHour: 7, toHour: 19 }}
                  nonWorkingTimeFeature={true}

                  timeRangesFeature={{
                    showCurrentTimeLine: true
                  }}

                  // Disables right-click for events
                  eventMenuFeature={false}

                  // Disables right-click for resources
                  cellMenuFeature={false}

                  // Disables right-click in scheduler body
                  scheduleMenuFeature={false}

                  eventDragFeature={this.eventEditValidator}
                  eventResizeFeature={this.eventEditValidator}
                  eventRenderer={this.eventRenderer}

                  listeners={this.schedulerListeners}
                />
              </div>
              {!context.isMobile && <ResizePanel direction="n" containerClass={`plannable-items-panel ${this.state.isPlannableItemsOpen ? "" : "closed"}`}>
                <div className="plannable-items-wrapper">
                  <div className="plannable-items-collapser" onClick={this.togglePlannableItems}>
                    <i className={`fas ${this.state.isPlannableItemsOpen ? "fa-chevron-down" : "fa-chevron-up"}`} />
                  </div>
                  <PlannableItemSelector
                    innerRef={this.state.plannableItemSelectorRef}
                    activeTab={this.state.activeTab}
                    onChangeActiveTab={this.handleChangeActiveTab}
                    onSelectCard={this.selectCardAndAddAssignees}
                    onMouseDown={this.onStartDragPlannableItem}
                    securityContext={securityContext}
                    undraggable={true}
                  />
                </div>
              </ResizePanel>}
              {renderToolbar(context, selected)}
            </div>
          </div>);
      }}
    </GlobalContext.Consumer>);
  }
}
