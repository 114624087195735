import React, { useState, useEffect } from "react";
import { Spinner } from "components/animations/Spinner";
import Form from "components/controls/form/Form";
import "./LibraryItemPreview.scss";

export default function LibraryItemPreview(props) {
    const [payload, setPayload] = useState(null);

    useEffect(() => {
        if (props.selected) {
            setPayload(null); // Reset payload before fetching new data
            fetch(props.selected.url)
                .then(x => x.json())
                .then(x => {
                    setPayload(x);
                });
        }
    }, [props.selected]);

    return (
        <div className="library-item-preview">
            {props.title && <h1>{props.title}</h1>}
            <div className="library-item-body-wrapper">
                {props.selected && !payload && <Spinner />}
                {payload && (
                    <div className="library-item-preview-body">
                        <Form
                            cards={[payload]}
                            module={payload.module}
                            readOnly={true}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
