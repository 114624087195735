import React, { useState, useEffect } from 'react';
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { transformExtent } from 'ol/proj';

import arxs from 'infra/arxs';
import UrlUtil from './UrlUtil';

import './NominatimSearch.scss';

const defaultProps = {
  nominatimBaseUrl: 'https://nominatim.openstreetmap.org/search?',
  format: 'json',
  viewBox: '-180,90,180,-90',
  bounded: 1,
  polygonGeoJSON: 1,
  addressDetails: 1,
  limit: 10,
  //countryCodes: 'be',
  minChars: 3,
  visible: true,
  renderOption: (li, itemProps) => {
    const index = itemProps.index;
    const itemChildren = (
      <span
        style={{
          color: "#00F",
        }}
      >
        {li.props.children} {index}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  },
  onSelect: (selected, olMap) => {
    if (selected && selected.boundingbox && olMap) {
      const olView = olMap.getView();
      const bbox = selected.boundingbox.map(parseFloat);
      let extent = [
        bbox[2],
        bbox[0],
        bbox[3],
        bbox[1]
      ];

      extent = transformExtent(extent, 'EPSG:4326',
        olView.getProjection().getCode());

      olView.fit(extent, {
        duration: 500
      });
    }
  }
};

export function NominatimSearch(props) {
  const className = `nominatimsearch`;

  const [searchTerm, setSearchTerm] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [searchTimer, setSearchTimer] = useState();

  useEffect(() => {
    onUpdateInput(props.searchTerm);
  }, [props.searchTerm]);

  useEffect(() => {
    if (searchTerm.length >= (props.minChars || 3)) {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }

      setSearchTimer(setTimeout(
        () => {
          const nominatimBaseUrl = props.nominatimBaseUrl || defaultProps.nominatimBaseUrl;
          const baseParams = {
            format: props.format || defaultProps.format,
            viewbox: props.viewBox || defaultProps.viewBox,
            bounded: props.bounded || defaultProps.bounded,
            // eslint-disable-next-line camelcase
            polygon_geojson: props.polygonGeoJSON || defaultProps.polygonGeoJSON,
            addressdetails: props.addressDetails || defaultProps.addressDetails,
            limit: props.limit || defaultProps.limit,
            countrycodes: props.countryCodes || defaultProps.countryCodes,
            q: searchTerm
          };

          const getRequestParams = UrlUtil.objectToRequestString(baseParams);

          fetch(`${nominatimBaseUrl}${getRequestParams}`)
            .then(response => response.json())
            .then((response) => {
              setDataSource(response);

              if (props.onFetchSuccess) {
                props.onFetchSuccess(response);
              }
            })
            .catch((error) => {
              arxs.logger.error("Error while requesting Nominatim: {error}", error);
              if (props.onFetchError) {
                props.onFetchError(error);
              }
            });

          setSearchTimer(null);
      }, 350));
    }
  }, [searchTerm]);

  const onUpdateInput = (inputValue) => {
    const { onClear } = props;

    setDataSource([]);
    setSearchTerm(inputValue || '');

    if (!inputValue && onClear) {
      onClear();
    }
  };

  const onMenuItemSelected = (value) => {
    const selected = dataSource.find(
      i => i.display_name.toString() === value
    );
    const onSelect = props.onSelect || defaultProps.onSelect;
    if (onSelect && selected) {
      onSelect(selected, props.map);
    }
  };

  const {
    map,
    onSelect,
    minChars,
    visible,
    ...passThroughProps
  } = props;

  if (visible === false) {
    return <></>;
  }

  const finalClassName = props.className
    ? `${props.className} ${className}`
    : className;

  return (
    <AutoComplete
      className={finalClassName}
      data={dataSource}
      textField="display_name"
      placeholder={arxs.t("controls.geo.nominatim_search.placeholder")}
      itemRender={defaultProps.renderItem}
      onChange={e => onUpdateInput(e.value)}
      onClose={e => onMenuItemSelected(e.value)}
      {...passThroughProps}
    />
  );
}

export default NominatimSearch;