import React, { useEffect, useState } from "react";

import arxs from "infra/arxs";
import Switch from "components/controls/Switch";
import PopOver from "components/controls/PopOver";
import { getKnownLayers } from "components/controls/geolocation/MapMetadata";

import "./LayerToggle.scss";

export default function LayerToggle(props) {
    const [knownLayers, setKnownLayers] = useState([]);

    useEffect(() => {
        setKnownLayers(getKnownLayers(arxs));
    }, []);

    const layers = props.value || [];

    return <PopOver
        className="layer-toggle"
        collapsedIcon="fas fa-layer-group"
        >
        {knownLayers.filter(x => x.id !== "map").map(x => <Switch
                key={x.id}
                title={x.name}
                checked={layers.contains(x.id)}
                onChange={(checked) => {
                    const newLayers = props.value
                        .concat([x.id])
                        .distinct()
                        .filter(id => id === x.id ? checked : true);

                    props.onChange(newLayers);
                }}
                />)}
    </PopOver>;
}