import React, { useState, useEffect, Fragment } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from "infra/GlobalContext";
import {
  DateRangePicker,
  TimePicker
} from "@progress/kendo-react-dateinputs";
import Switch from "components/controls/Switch";
import './DateRange.scss';

export default function DateRange(props) {
  const [allDay, setAllDay] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const value = props.value || {};
    setData({ start: value.start && new Date(value.start), end: (value.end && new Date(value.end)) || (value.start && new Date(value.start)) });
  }, [props.value]);

  useEffect(() => {
    if (allDay === true) {
      let newData = { ...data };
      newData.start = new Date(new Date(newData.start).setHours(0, 0, 0));
      newData.end = new Date(new Date(newData.end).setHours(23, 59, 59));

      props.onChange && props.onChange(newData);
    }
  }, [allDay]);

  const renderInput = () => {
    const setRange = (value) => {
      let origStart = data.start;
      let origEnd = data.end;
      let newStart = value.start;
      let newEnd = value.end;

      if (newStart && origStart) {
        origStart = new Date(origStart);
        newStart = newStart && new Date(newStart);
        newStart = newStart && origStart ? newStart.setHours(origStart.getHours(), origStart.getMinutes(), origStart.getSeconds()) : newStart;
      } else {
        newStart = newStart && new Date(newStart);
      }

      if (newEnd && origEnd) {
        origEnd = new Date(origEnd);
        newEnd = newEnd && new Date(newEnd);
        newEnd = newEnd && origEnd ? newEnd.setHours(origEnd.getHours(), origEnd.getMinutes(), origEnd.getSeconds()) : newEnd;
      } else {
        newEnd = newEnd && new Date(newEnd);
      }

      let newData = { start: newStart, end: newEnd };

      props.onChange && props.onChange(newData)
    }

    const setTime = (field, value) => {
      let newData = {};

      newData[field] = value;

      props.onChange && props.onChange(newData);
    }

    const getMinTime = () => {
      if (data && data.start && data.end) {
        if (data.start.getFullYear() === data.end.getFullYear() &&
          data.start.getMonth() === data.end.getMonth() &&
          data.start.getDate() === data.end.getDate()) {
          return data.start;
        }
      }

      return undefined;
    }

    return <Fragment>
      <div className="date-range-content-values">
        <div className="date-range-content-values-dates">
          <DateRangePicker
            className="input"
            value={data.start && data.end && data}
            onChange={(event) => setRange(event.value)}
            disabled={props.disabled}
            placeholder="dd-mm-jjjj"
            allowReverse={false}
          />
          {props && props.showTime && renderSwitch()}</div>
        {props && props.showTime && !allDay && <div className="date-range-content-values-times">
          <TimePicker
            format="HH:mm"
            value={data && data.start}
            onChange={(event) => setTime("start", event.value)}
            placeholder="uu:mm"
            disabled={props.disabled}
          />
          <TimePicker
            format="HH:mm"
            value={data && data.end}
            onChange={(event) => setTime("end", event.value)}
            placeholder="uu:mm"
            disabled={props.disabled}
            min={getMinTime()}
          />
        </div>}
      </div>
    </Fragment>
  }

  const renderSwitch = () => {
    return <Switch
      title={arxs.t("field.allDay")}
      checked={allDay}
      onChange={setAllDay}
      disabled={props.disabled || (!data || !data.end || !data.start)}
    />;
  }

  return (
    <GlobalContext.Consumer>
      {
        context => <div className={`date-range ${props.className || ""}`}>
          {props.title && (
            <label>
              {props.title}
              {props.required && <span> *</span>}
            </label>
          )}
          <div className="date-range-content">
            {renderInput()}
          </div>
        </div>}
    </GlobalContext.Consumer>
  );
}