import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { OriginModuleEnum, RelationshipType } from "infra/api/contracts";

class ActivityEntryBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const employeeParticipants = (card.participants || [])
      .filter((x) => x.module === OriginModuleEnum.Employee)
      .map((y) => this.getLookupValue(stateProxy.getter("employeeMap"), y));
    const contactParticipants = (card.participants || [])
      .filter((x) => x.module === OriginModuleEnum.Contact)
      .map((y) =>
        this.getLookupValueWithFieldNames(stateProxy.getter("contactMap"), y, [
          "firstname",
          "surname",
        ])
      );

    return {
      ...card,

      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      assignees: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.Assignee
        ),
        stateProxy.getter("employeeMap"),
        stateProxy.getter("userRoleMap")
      ),
      participants: [].concat(employeeParticipants).concat(contactParticipants),
      supplier: this.getLookupValue(
        stateProxy.getter("supplierMap"),
        card.supplier
      ),
      startAt: card.startAt && new Date(card.startAt),
      endAt: card.endAt && new Date(card.endAt),
      createdAt: card.createdAt && new Date(card.createdAt),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.title,
      card.description,
      card.supplier,
      ...card.participants,
      ...card.assignees,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.activityEntries) {
        lookups.pristine = lookups.activityEntries
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.activityEntries;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Activities yet");
  };
}
export default new ActivityEntryBoard();
