import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts";

import { CardClassProps } from "components/card/CardContracts";
import CardLocation from "components/card/CardLocation";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardCategorization from "components/card/CardCategorization";

function getValue(map: any, cardValue: any) {
  const id = (cardValue || {}).id;
  const ref = (map && map[id]) || {};
  return ref.name
    ? ref.name
    : Object.keys(ref).some((x) => x)
    ? ref
    : undefined;
}

export default function IncidentCard(props: CardClassProps) {
    const initialLookups = {
      employeeMap: {},
      contactMap: {},
    };
    
    const [lookups, setLookups] = useState(initialLookups);

    useEffect(() => {
      const subscriptions = {
        lookups: arxs.Api.lookups.subscribe(initialLookups, (values: any) => {
          setLookups((lookups) => ({ ...lookups, ...values }));
        }),
      };

      return () => {
          subscriptions.lookups.dispose();
      };
    }, []);
  
    const { card, actionProps, cardProps } = props;
    const { isSerious } = card;

    if (actionProps.condensed) {
      return (
        <div className={`user-card ${cardProps.className || ""} selectable`}>
          <i
            className={arxs.modules.icons[OriginModuleEnum.IncidentManagement]}
          ></i>
          <div className="user-card-names">
            <div className="user-card-name">
              <CardCategorization {...props} />
            </div>
          </div>
          <CardActions {...actionProps} />
        </div>
      );
    }

    if (actionProps.mini) {
      return (
        <>
          <i
            className={arxs.modules.icons[OriginModuleEnum.IncidentManagement]}
          ></i>
          <div className="mini-card-names">
            <div className="mini-card-name">
              <CardCategorization {...props} />
            </div>
          </div>
        </>
      );
    }

    const getPrintableValueForVictim = (card: {
      victim?: string | { module: string; id: string };
    }) => {
      if (!card.victim) return;
      if (typeof card.victim === "string") {
        return card.victim;
      }
      if (card.victim.module === OriginModuleEnum.Employee) {
        return getValue(lookups.employeeMap, card.victim);
      }
      if (card.victim.module === OriginModuleEnum.Contact) {
        const contact = getValue(lookups.contactMap, card.victim);
        return contact && [contact.firstname, contact.surname].join(" ");
      }
    };

    const victim = getPrintableValueForVictim(card);
    const locationType = (
      arxs.enums.getTitle(
        "IncidentManagementLocationType",
        card.locationType
      ) || ""
    ).split(" ")[0];
    const incidentTime = card.incidentTimeRaw || card.incidentTime;
    return (
      <>
        <div className="list-card-right">
          <div className="list-card-header">
            {incidentTime && (
              <div>{arxs.dateTime.formatDateTime(incidentTime)}</div>
            )}
            <div>{locationType}</div>
            {
              <div>
                {card.isAccident && arxs.t("kanban.common.accident")}
              </div>
            }
            {isSerious && (
              <div className="error">{arxs.t("kanban.common.serious")}</div>
            )}
          </div>
          {victim && (
            <div className="list-card-subheader">
              <div>{victim}</div>
            </div>
          )}
          <h2>
            <div className="list-card-title">
              <CardCategorization {...props} />
            </div>
            <CardActions {...actionProps} />
          </h2>
          <CardSubtitle cardProps={cardProps} card={card} />
          <div className="list-card-details">
            <CardLocation card={card} wrapLocation={true} />
          </div>
        </div>
      </>
    );
}