import Toaster from "components/util/Toaster";

import arxs from "infra/arxs";
import { ProductType } from "infra/Types";
import { ObjectDocumentType, ReportDefinitionSettings, ReportTemplate } from "infra/api/contracts";
import { ReportSelectionFilter, OriginModuleEnum } from "infra/api/contracts";

interface ReportRequest {
  template: ReportTemplate,
  filters: Array<ReportSelectionFilter>,
}

class ReportClient {
  endpointUrl = process.env.REACT_APP_REPORTGENERATOR_ENDPOINT;
  headers: any;

  constructor() {
    this.headers = { "Content-Type": "application/json" };
  }

  initialize = () => { };

  toastFetchError = (error?: any) => {
    if (typeof error === "string") {
      Toaster.error(error);
    } else {
      Toaster.error("Er ging iets mis...");
    }
  };

  handleFetchError = (url: RequestInfo, error: string) => {
    const errorMessage = "" + error;
    arxs.logger.warn("Fetch {url} failed: {error}", url, errorMessage);
    this.toastFetchError();
    return new Promise((resolve, reject) => { });
  };

  tryFetch = (url: RequestInfo, body: RequestInit | undefined) => {
    if (!body) {
      body = { headers: this.getHeaders() };
    }
    return fetch(url, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 422) {
            return Promise.resolve(response);
          }
          return response
            .json()
            .then((x) => {
              if (response.status >= 400) {
                arxs.logger.warn(
                  "Fetch {url} failed: {error} {payload}"
                  , url, x.error, this.fetchErrorToLogPayload(x)
                );
              } else {
                arxs.logger.warn(
                  "Fetch {url} failed with unexpected payload: {payload}"
                  , url, x
                );
                this.toastFetchError(x.error);
              }
              return new Promise((resolve, reject) => { });
            })
            .catch((error) => {
              this.handleFetchError(url, error);
              return new Promise((resolve, reject) => { });
            });
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        this.handleFetchError(url, error);
        return new Promise((resolve, reject) => { });
      });
  };

  fetchErrorToLogPayload = (result: any) => {
    const payload = { ...result };
    if (payload.traceId) {
      payload.SpanId = payload.traceId;
      delete payload.traceId;
    }
    return payload;
  };

  tryFetchJson<T>(url: string, body?: any): Promise<T> {
    return this.tryFetch(url, body).then((response: any) => {
      return response.json().catch((error: string) => {
        const errorMessage = "" + error;
        arxs.logger.warn(
          "Fetch {url} failed: {error}"
          , url, errorMessage
        );
        this.toastFetchError();
        return new Promise((resolve, reject) => { });
      });
    });
  }

  getHeaders = () => {
    const headers = { ...this.headers };
    return headers;
  };

  generateBinary = (report: string, module: string) => {
    if (arxs !== null) {
      let fileName = "";

      switch (report.toLowerCase()) {
        case "hazardoussubstanceinventory":
          fileName = "Gevaarlijke stoffen - inventaris";
          break;
        case "incidentinventory":
          fileName = "Incidenten - inventaris";
          break;
      }

      fileName = fileName + "_" + arxs.dateTime.formatDateTime(Date());

      switch (module) {
        case OriginModuleEnum.HazardousSubstance:
          switch (report.toLowerCase()) {
            case "hazardoussubstanceinventory":
              arxs.ApiClient.assets.hazardousSubstance
                .getInventory()
                .then((resObj: any) => {
                  const objUrl = window.URL.createObjectURL(resObj);
                  var fileLink = document.createElement("a");
                  fileLink.href = objUrl;
                  fileLink.download = fileName;
                  fileLink.click();
                });
              break;
          }
        case OriginModuleEnum.IncidentManagement:
          switch (report.toLowerCase()) {
            case "incidentinventory":
              arxs.ApiClient.safety.incident
                .getInventory()
                .then((resObj: any) => {
                  const objUrl = window.URL.createObjectURL(resObj);
                  var fileLink = document.createElement("a");
                  fileLink.href = objUrl;
                  fileLink.download = fileName;
                  fileLink.click();
                });
              break;
          }

      }
    }
  };

  generatePDF = (
    definitions: any[],
    fileName: string,
    returnBlobUrl?: boolean
  ): Promise<void | string> => {
    if (!arxs) return Promise.resolve();
  
    const generate = (settings?: ReportDefinitionSettings): Promise<void | string> => {
      const promises = definitions.map((report) => {
        const fullName = arxs.Identity?.profile?.fullName || "";
        const email = arxs.Identity?.profile?.email || "";
        const baseUrl = process.env.REACT_APP_API_ENDPOINT;
        const headers = {
          Authorization: `Bearer ${arxs.Api.getToken()}`,
        };
  
        const payload = {
          metadata: {
            author: { name: fullName, email: email },
            filter: report.filter
              ? { dateRange: report.filter.dateRange, scope: report.filter.scope }
              : {},
            settings: settings?.headerFooter,
          },
          endpoints: {
            host: baseUrl,
            swagger: "/swagger/v1/swagger.json",
            translations: `/api/shared/translations/${arxs.Identity.language}/${ProductType[arxs.Identity.productType]}`,
            legalStructures: "/api/masterdata/legalstructure",
            branches: "/api/masterdata/branch",
            buildings: "/api/assetmanagement/building",
            headers: headers,
            dataSources: report.dataSources.map((dataSource: any) => ({
              ...dataSource,
              headers: headers,
            })),
            tenant: window.location.origin,
          },
          report: `/api/shared/report/${report.id}`,
        };
  
        const url = `${this.endpointUrl}/pdf/generate`;

        return fetch(url, {
          method: "POST",
          headers: this.headers,
          body: JSON.stringify(payload),
        })
          .then((response) => {
            if (!response.ok) {
              if (response.status === 422) {
                return Promise.reject(new Error('Unprocessable entity'));
              }
              return response.json().then((x) => {
                arxs.logger.warn(
                  "Fetch {url} failed: {error} {payload}",
                  url,
                  x.error,
                  this.fetchErrorToLogPayload(x)
                );
                this.toastFetchError(x.error);
                throw new Error("Fetch failed");
              });
            }
            return response.blob().then((blob) => ({
              filename: fileName,
              blob: blob,
            }));
          })
          .then((resObj) => {
            if (!resObj || !resObj.blob) {
              throw new Error('Invalid response object');
            }
  
            const newBlob = new Blob([resObj.blob], { type: "application/pdf" });
            const objUrl = window.URL.createObjectURL(newBlob);
  
            if (returnBlobUrl) {
              return objUrl; 
            } else {
              const tab = window.open();
              if (tab) {
                tab.location.href = objUrl;
  
                const fileLink = document.createElement("a");
                fileLink.href = objUrl;
                fileLink.download = fileName;
                fileLink.click();
              }
              return; 
            }
          })
          .catch((error) => {
            this.handleFetchError(url, error);
            throw error;
          });
      });
  
      return Promise.all(promises)
        .then((results) => {
          const blobUrl = results.find((result) => typeof result === "string");
          return blobUrl || undefined;
        })
        .catch((error) => {
          return; 
        });
    };

    return arxs.ApiClient.shared.reportDefinitionSettings.get()
      .then(settings => generate(settings))
      .catch(() => generate());
  };
  
  

  reporting = () => {
    return {
      generateBinary: (report: string, module: string) => {
        this.generateBinary(report, module);
      },
      getZippedDocuments: (module: any, cards: any, documentType: ObjectDocumentType) => {
        const data = {module: module,
          objectIds: cards.map((x: any) => x.id),
          documentType: documentType,
        };

        arxs.ApiClient.shared.attachment.retrieveZippedDocuments(data)
          .then((resObj: any) => {
            if (resObj && resObj.size === 0) {
              Toaster.warning(arxs.t("error.zip_no_content"));
              return; 
            }

            const fileName = cards.map((x : any) => x.uniqueNumber).join("_");
            const objUrl = window.URL.createObjectURL(resObj);
            var fileLink = document.createElement("a");
            fileLink.href = objUrl;
            fileLink.download = fileName + ".zip";
            fileLink.click();
          })
          .catch((error: any) => {
            this.toastFetchError(error)
          });

      },
      generatePDF: (
        requests: ReportRequest | Array<ReportRequest>,
        reportAlias?: string,
        fileNameSuffix?: string,
        returnBlobUrl?: boolean
      ): Promise<void | string> => { 
        const definitions = (Array.isArray(requests) ? requests : [requests])
          .filter(x => x)
          .map(request => {
            const { template, filters } = request;
            let scope: Array<string> = [];
            let dateRange: any = {};
      
            if (filters && filters.length > 0) {
              const filter = filters[0];
              if (filter.from) {
                dateRange.from = filter.from;
              }
              if (filter.to) {
                dateRange.to = filter.to;
              }
      
              if (filter.scope && filter.scope.length > 0) {
                scope = scope.concat(filter.scope);
              }
            }
      
            return {
              ...template,
              dataSources: JSON.parse(template.dataSourceJsonRaw || "").map(
                (rds: any, index: number) => ({ ...rds, body: filters[index] })
              ),
              filter: { scope: scope, dateRange: dateRange },
            };
          });
      
        if (definitions.length === 0) {
          return Promise.resolve();
        }
      
        const fileName = [
          arxs.t(`report.${reportAlias || definitions[0].alias || ""}`),
          fileNameSuffix,
          arxs.dateTime.formatDateTime(Date()),
          ".pdf"
        ]
          .filter(x => x)
          .join("_");
      
        if (returnBlobUrl) {
          return this.generatePDF(definitions, fileName, returnBlobUrl)
            .then((url) => {
              if (typeof url === 'string') {
                return url; 
              }
              throw new Error('Failed to generate URL');
            })
            .catch((error) => {
              throw error; 
            });
        } else {
          return this.generatePDF(definitions, fileName).then(() => undefined);
        }
      },
    };
  };
}
export default new ReportClient();
