import React, { useState } from "react";

import "./PopOver.scss";

export default function PopOver(props) {
    const [expanded, setExpanded] = useState(false);
    const [expandTimer, setExpandTimer] = useState();

    const closeAfterDelay = () => {
        cancelClose();
        setExpandTimer(setTimeout(() => setExpanded(false), props.delay || 2000));
    };

    const cancelClose = () => {
        if (expandTimer) {
            clearTimeout(expandTimer);
        }
    };

    const className = ["pop-over", props.className].filter(x => x).join(" ");
    const panelClassName = ["pop-over-panel", expanded && "expanded"].filter(x => x).join(" ");

    return <div className={className} onMouseLeave={closeAfterDelay} onMouseEnter={cancelClose}>
        {expanded && <div className={panelClassName}>{props.children}</div>}

        <div className="pop-over-toggle">
            {!expanded
                ? <i className={props.collapsedIcon || "fa fa-question"} onClick={() => setExpanded(true)}></i>
                : <i className={props.openIcon || "fa fa-xmark"} onClick={() => setExpanded(false)}></i>}
            
        </div>
    </div>;
}