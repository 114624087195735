import React from "react";
import { marked } from "marked";
import * as DOMPurify from "dompurify";

import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";

import "./Instruction.scss";

export default class Instruction extends FormItemController {
    render() {
        let dangerouslySetInnerHTML = DOMPurify.sanitize(marked.parse(this.props.title || ""));
        dangerouslySetInnerHTML = dangerouslySetInnerHTML.replace(/<a(?![^>]*\btarget\b)[^>]*>/g, match => {
          return match.replace(/<a/, '<a target="_blank"');
          });

        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control instruction">
                    <div
                        className="markdown"
                        dangerouslySetInnerHTML={{
                        __html: dangerouslySetInnerHTML,
                        }}
                    /></div>
            }
        </GlobalContext.Consumer>)
    }
}