import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import AssetActions from "modules/assetmanagement/AssetActions";
import IntangibleAssetBoard from "./IntangibleAssetBoard";
import IntangibleAssetWizard from "./IntangibleAssetWizard";
import {
  OriginModuleEnum,
  RelationshipType,
  StatusEnum,
  ObjectDocumentType,
} from "infra/api/contracts";
import AssetCard from "../AssetCard";

const getIntangibleAssetMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.IntangibleAsset;

  return {
    module,
    title: arxs.t("modules.intangible_asset"),
    icon: "fas fa-hand-holding-box",
    path: "/intangibleasset",
    base: {
      name: "IntangibleAsset",
      route: "/intangibleAsset",
      getResource: () => arxs.Api.getResource("intangibleAsset"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(
          OriginModuleEnum.IntangibleAsset,
          id,
          handler
        ),
      readAction: "IntangibleAsset.Read",
      writeAction: "IntangibleAsset.Write",
    },
    settings: {
      name: "IntangibleAssetSettings",
      route: "/intangibleAsset/settings",
      getImportResource: () => arxs.ApiClient.assets.intangibleAssetSettings,
      readAction: "IntangibleAsset.Settings.Write",
      writeAction: "IntangibleAsset.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "description" },
          { name: "internalNumber" },
          { name: "kind" },
          { name: "type", parent: "kind" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
      {
        title: arxs.t("details_pane.details"),
        fields: [
          { name: "brand" },
          { name: "model" },
          { name: "purchaseDate" },
        ],
      },
      {
        title: arxs.t("details_pane.extra_specifications"),
        fields: [
          {
            name: "extraSpecifications",
            children: [{ name: "name" }, { name: "description" }],
          },
        ],
      },
      {
        title: arxs.t("details_pane.supplier_customer"),
        fields: [{ name: "supplier" }, { name: "contacts" }],
      },
      {
        title: arxs.t("details_pane.ownership"),
        fields: [
          { name: "purchasePrice" },
          { name: "depreciationPeriod" },
        ],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.Manual,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      StatusEnum.Active,
      StatusEnum.ToCommission1,
      StatusEnum.ToCommission2,
      StatusEnum.InService,
      StatusEnum.Expired,
      StatusEnum.OutOfService,
    ],
    statusColorOverride: [
      { status: StatusEnum.Active, color: "status-completed" },
    ],
    actions: AssetActions.getActions(module),
    wizard: {
      wizardClass: IntangibleAssetWizard,
      steps: [
        {
          title: arxs.t("details_pane.location"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                  ],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  IntangibleAssetWizard.setScopeFields(
                    stateProxy,
                    "context"
                  ).then(() =>
                    IntangibleAssetWizard.applyProcessFlow(
                      stateProxy,
                      "context"
                    )
                  );
                },
                onLoad: (stateProxy) => {
                  IntangibleAssetWizard.getContext(stateProxy);
                },
                title: arxs.t("field.location"),
              },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = [
              "legalStructure",
              "branch",
              "building",
              "location",
            ].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "type",
                parent: "kind",
                props: { code: "IntangibleAsset.Kind" },
                type: "codeelementlist",
                onChange: (stateProxy) =>
                  IntangibleAssetWizard.applyProcessFlow(stateProxy, "context"),
              },
            ],
            [{ name: "brand" }, { name: "model" }],
            [{ name: "internalNumber" }],
            [{ name: "description" }],
            [],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [{ name: "purchaseDate" }, { name: "purchasePrice" }],
            [
              {
                name: "depreciationPeriod",
                unit: arxs.t("unit.month").toLowerCase(),
              },
            ],
            [
              {
                name: "recommendation",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Consultancy],
                  singleSelection: true,
                },
                title: arxs.t("field.recommendation"),
                onChange: (stateProxy) => {
                  IntangibleAssetWizard.copyRecommendationData(stateProxy);
                },
              },
            ],
            [
              {
                name: "supplier",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.supplier"),
              },
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  setFilterOnField: "supplier",
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.contact"),
              },
            ],
            [
              {
                name: "parts",
                type: "itemlist",
                children: [
                  { name: "name", title: arxs.t(`field.description`) },
                  {
                    name: "warrantyPeriod",
                    unit: arxs.t("unit.month").toLowerCase(),
                  },
                ],
              },
            ],
            [
              {
                name: "extraSpecifications",
                type: "itemlist",
                children: [
                  { name: "name", code: "IntangibleAsset.ExtraSpecification" },
                  { name: "description", multiLine: false },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.expiration"),
          fields: [
            [
              { name: "expirationDate" },
              { name: "expirationNotificationDate" },
            ],
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        recommendationMap: {},
      },
      fieldsWithIdsForDuplication: ["extraSpecifications", "parts"],
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        intangibleAssets: {},
        tagMap: {},
        employeeMap: {},
        contactMap: {},
        supplierMap: {},
        recommendationMap: {},
        combinedInstallationMap: {},
        periodicalMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "sort" },
        { name: "kind" },
        { name: "type" },
        { name: "description" },
        { name: "brand" },
        { name: "model" },
        { name: "internalNumber" },
        { name: "purchaseDate" },
        { name: "recommendation" },
        { name: "supplier" },
        { name: "purchasePrice" },
        { name: "depreciationPeriod" },
        { name: "remainingDepreciationPeriod" },
        { name: "depreciationPeriodEnd" },
        { name: "expirationDate" },
        { name: "combinedInstallationUniqueNumber" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
        { name: "link" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: IntangibleAssetBoard,
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Tree],
    },
    cardClass: AssetCard
  };
};
export default getIntangibleAssetMetadata;
