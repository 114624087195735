import React, { useCallback, useEffect, useState } from "react";
import { Switch as KendoSwitch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import FieldLabel from "./FieldLabel";

import "./Switch.scss";

export interface SwitchProps {
    className?: string;
    checked: boolean;
    onChange?: (checked: boolean, event: Event) => void;
    disabled?: boolean;
    title?: string;
    required?: boolean;
    reverse?: boolean;
}
  
export default function Switch(props: SwitchProps) {
    const [idProps, setIdProps] = useState<any>({});

    const onChange = useCallback((e: SwitchChangeEvent) => {
        if (props.onChange) {
            const nativeEvent = e.nativeEvent;
            nativeEvent.stopPropagation();
            props.onChange(e.target.value, e.nativeEvent);
        }
    }, [props.onChange]);

    useEffect(() => {
        if (props.title) {
            const titleId = props.title.trim().toLowerCase().replace(/\s+/g, '-');
            setIdProps({
                id: `switch-${titleId}`
            });
        } else {
            setIdProps({});
        }
    }, [props.title]);

    const label = props.title && <FieldLabel
        {...idProps}
        title={props.title}
        required={props.required}
        showHeader={true}
    />;

    return <div className="switch-wrapper">
        {!props.reverse && label}
        <KendoSwitch
            {...idProps}
            size={"small"}
            className={props.className}
            checked={props.checked}
            onChange={onChange}
            disabled={props.disabled}
        />
        {props.reverse && label}
    </div>;
}