import React, { useState, useEffect, useContext } from 'react';
import { Prompt } from "react-router-dom";

import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { EditFormAction, TaskStatus } from 'infra/api/contracts';

import Toaster from 'components/util/Toaster';
import Button from "components/controls/Button";
import { createInputPopupWithOptions } from 'components/shell/InputPopup/InputPopup';

import Form from './Form';

import './FormAction.scss';

const _createFormValuesCacheKey = (key) => `Form_Values_${key}`;

export default function FormAction(props) {
  const { module, match } = props;
  const { params } = match || {};

  const context = useContext(GlobalContext);
  const [requiredItemsWithoutValue, setRequiredItemsWithoutValue] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const [pristine, setPristine] = useState();
  const [formValuesByCard, setFormValuesByCard] = useState();
  const [formValuesCacheKey, setFormValuesCacheKey] = useState();

  useEffect(() => {
    const metadata = arxs.moduleMetadataRegistry.get(module);
    const resource = metadata.base.getResource();
    resource.getById(params.id)
      .then((x) => {
        context.detailsPane.open({ id: params.id, module }, true);

        const cacheKey = _createFormValuesCacheKey(params.id);
        setFormValuesCacheKey(cacheKey);

        const formValuesByCardDefault = { [x.id]: x.formValues };
        const cachedValues = localStorage.getItem(cacheKey);
        if (cachedValues && cachedValues.indexOf("itemsBySubject") > -1) {
          const inputPopup = createInputPopupWithOptions(context,
            {
              title: arxs.t("form_action.restore_unfinished_form_title"),
              description: arxs.t("form_action.restore_unfinished_form_description"),
              icon: "fas fa-circle-info",
              onSubmit: () => {
                onFormChange(JSON.parse(cachedValues), false);
              },
              onCancel: () => {
                onFormChange(formValuesByCardDefault, false);
              }
            });

          context.inputPopup.show(inputPopup);
        } else {
          onFormChange(formValuesByCardDefault, false);
        }

        setPristine(x);
      });
  }, []);

  const close = () => {
    localStorage.removeItem(formValuesCacheKey);
    props.history.goBack();
  };

  const handleRegularSubmit = (context, action, formValues) => {
    setIsDirty(false);
    setRequiredItemsWithoutValue([]);

    const serializeAttachments = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map(item => serializeAttachments(item));
      } else if (typeof obj === 'object' && obj !== null) {
        const newObj = { ...obj };
        if (newObj.attachments && Array.isArray(newObj.attachments)) {
          newObj.attachments = newObj.attachments.map(attachment => {
            const newAttachment = { ...attachment };

            // Check if the type is 'Media' before processing
            if (newAttachment.type === "Media" && Array.isArray(newAttachment.value)) {
              // Keep only the selected fields in the value array
              const updatedValue = newAttachment.value.map(item => {
                const { id, name, hash, url, contentType, type } = item;
                return { refId: id, name, hash, url, contentType, type };
              });
              newAttachment.value = JSON.stringify(updatedValue); // Serialize `value` to string
            }

            return newAttachment;
          });
        }
        // Recursively traverse the rest of the object
        Object.keys(newObj).forEach(key => {
          newObj[key] = serializeAttachments(newObj[key]);
        });
        return newObj;
      }
      return obj;
    };


    formValues = serializeAttachments(formValues);

    const metadata = arxs.moduleMetadataRegistry.get(module);
    if (!metadata.actionClass) {
      throw "Missing actionClass for module " + module;
    }

    return metadata.actionClass
      .completeForm({
        context,
        cards: [pristine],
        ids: [pristine.id]
      },
        pristine,
        formValues,
        action)
      .then(close);
  }

  const submit = (context, action) => {
    let formValues = formValuesByCard[pristine.id];

    if (action === EditFormAction.Complete) {
      const formCompletedResult = arxs.getFormCompletionResult(pristine.formDefinition, formValues);
      if (!formCompletedResult.isCompleted) {
        setRequiredItemsWithoutValue(formCompletedResult.unCompletedItemIds);
        Toaster.error(arxs.t("actions.form.error.not_all_required_fields_filled_in"));
        return Promise.resolve();
      } else {
        handleRegularSubmit(context, action, formValues);
      }
    } else {
      handleRegularSubmit(context, action, formValues);
    }
  };

  const onFormChange = (formValuesByCard, persist) => {
    // If not explicitely set to false, persist
    if (persist !== false) {
      localStorage.setItem(formValuesCacheKey, JSON.stringify(formValuesByCard));
      setIsDirty(true);
    }

    setFormValuesByCard(formValuesByCard);
  };

  return (
    <GlobalContext.Consumer>
      {(context) => pristine && <div className="form-popup">
        <div className="form-popup-body">
          <Form
            className="field"
            cards={[pristine]}
            module={module}
            initialFormValuesByCard={formValuesByCard}
            onChange={onFormChange}
            requiredItemsWithoutValue={requiredItemsWithoutValue}
            readOnly={props.readOnly}
          />
        </div>
        {!props.readOnly && (
          <div className="wizard-buttons">
            <Button
              className="wizard-button wizard-cancel icon"
              onClick={close}
              title={arxs.t("wizard.cancel")}
            >
              <i className="fas fa-times"></i>
            </Button>
            <Button
              className="wizard-button wizard-save icon"
              onClick={() => submit(context, EditFormAction.Save)}
              title={arxs.t("wizard.save")}
              disable-on-click
            >
              <i className="fad fa-save"></i>
            </Button>
            <Button
              className="wizard-button wizard-save icon"
              onClick={() => submit(context, pristine.status === TaskStatus.ToVerify ? EditFormAction.Verify : EditFormAction.Complete)}
              title={pristine.status === TaskStatus.ToVerify ? arxs.t("forms.verify") : arxs.t("forms.complete")}
              disable-on-click
            >
              <i className="fas fa-check"></i>
            </Button>
          </div>
        )}
        <Prompt
          when={isDirty}
          message={arxs.t("common.confirm_navigation")}
        />
      </div>}
    </GlobalContext.Consumer >
  );
}