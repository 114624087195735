import React, { useState } from "react";
import arxs from "infra/arxs";
import Button from "components/controls/Button";
import FieldSet from "components/controls/FieldSet";

import "./GenerateOccurrencePopup.scss";

export const createGenerateOccurrencePopup = (
  onSubmit,
  onCancel,
  title,
) => {
  const state = {
    title: title,
    content: (
      <GenerateOccurrencePopup
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    ),
    onSubmit: onSubmit,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function GenerateOccurrencePopup(props) {
  const [data, setData] = useState({ });

  const close = () => props.onCancel && props.onCancel();
  const confirm = () => {
    props.onSubmit && data && data.targetDate && props.onSubmit(data);
  };

  const rows = [
    [
      {
        name: "targetDate",
      },
    ],
  ];

  return (
    <div className={`generate-occurrence-popup ${props.className || ""}`}>
      <div className="generate-occurrence-popup-body">
        <h3>
          {arxs.t("actions.periodical.generate_occurence_description")}
        </h3>
        <FieldSet
          schemaName="GeneratePeriodicalOccurrence"
          rows={rows}
          data={data}
          setData={setData}
        />
        <div className="buttons">
          <Button
            key={`generate-occurrence-cancel`}
            className="icon alternative"
            onClick={close}
          >
            {arxs.t("common.cancel")}
          </Button>
          <Button
            key={`generate-occurrence-confirm`}
            className="icon"
            onClick={confirm}
            disabled={
              !data.targetDate
            }
          >
            {arxs.t("common.complete")}
          </Button>
        </div>
      </div>
    </div>
  );
}
