import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import { OriginModuleEnum } from "infra/api/contracts";
import { TextArea } from "components/controls/TextArea";
import NumericEditor from "components/controls/NumericEditor";

export default class Periodicals extends FormItemEditorController {
    lookups = {
        codeElements: {},
        codeElementsById: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    renderPeriodical = (context, settings, onItemChange, onDeleteItem, data) => {
        const { codeElementsById } = this.state;
        const { modules, executors, frequencies } = settings;
        const { readOnly } = this.props;
        const selectedModule = data && data.module && { id: data.module, name: arxs.modules.titles[data.module] };
        const selectedExecutor = data && data.executor && codeElementsById ? { id: data.executor, name: (codeElementsById[data.executor] || {}).name } : undefined;
        const selectedFrequency = data && data.frequency && codeElementsById ? { id: data.frequency, name: (codeElementsById[data.frequency] || {}).name } : undefined;

        const onModuleChange = (state) => {
            onItemChange({ ...data, module: state.id });
        }

        const onExecutorChange = (state) => {
            onItemChange({ ...data, executor: state.id });
        }

        const onFrequencyChange = (state) => {
            onItemChange({ ...data, frequency: state.id });
        }

        const onDescriptionChange = (state) => {
            onItemChange({ ...data, description: state })
        }

        const onNumberChange = (state) => {
            onItemChange({ ...data, number: state })
        }

        const handleDelete = () => {
            onDeleteItem(data.id);
        }

        const getDescription = () => {
            return data.description;
        }

        return <div className="periodical" key={(data && data.id) || 0}>
            <div className="periodical-identification">
                <DropDown
                    id={`module-${data.id}`}
                    key={`module-${data.id}`}
                    className="periodical-module"
                    items={modules}
                    selected={selectedModule}
                    onChange={onModuleChange}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.periodicals.module")}
                />
                <TextArea
                    readOnly={this.props.readOnly}
                    field={{ getter: getDescription, setter: onDescriptionChange, multiLine: false }}
                    required={this.props.required}
                />
                <div className="periodical-actions">
                    {!readOnly && data && <div className="periodical-action" onClick={handleDelete}>
                        <i className="far fa-trash-alt"></i>
                    </div>}
                </div>
            </div>
            <div className="periodical-details">
                <DropDown
                    id={`executor-${data.id}`}
                    key={`executor-${data.id}`}
                    className="periodical-executor"
                    items={executors}
                    selected={selectedExecutor}
                    onChange={onExecutorChange}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.periodicals.executor")}
                />
                <NumericEditor
                    className="periodical-number"
                    spinners={false}
                    min={0}
                    max={20}
                    value={data.number}
                    disabled={readOnly}
                    onChange={onNumberChange}
                    placeholder={arxs.t("forms.items.periodicals.each")}
                />
                <DropDown
                    id={`frequency-${data.id}`}
                    key={`frequency-${data.id}`}
                    className="periodical-frequency"
                    items={frequencies}
                    selected={selectedFrequency}
                    onChange={onFrequencyChange}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.periodicals.frequency")}
                />
            </div>

        </div>;
    }

    renderPeriodicals = (context) => {
        const { codeElements } = this.state;
        const modules = [OriginModuleEnum.PeriodicControl, OriginModuleEnum.PeriodicMaintenance, OriginModuleEnum.RiskAnalysis].map(x => ({ id: x, name: arxs.modules.titles[x] }));
        const { field, readOnly } = this.props;
        const executorKind = ((codeElements || {})["Executor"] || [])[0] || {};
        const executors = (executorKind.children || []).map(x => ({ id: x.id, name: x.name }));
        const frequencyIterationKind = ((codeElements || {})["PeriodicMaintenance.IterationFrequency"] || [])[0] || {};
        const frequencies = (frequencyIterationKind.children || []).map(x => ({ id: x.id, name: x.name }));
        const value = field.getter();
        const data = value.data || [];

        const onItemChange = (state) => {
            let newData = (data || []);
            const index = newData.findIndex(x => x.id === state.id);
            if (index === -1) {
                newData.push(state);
            } else {
                newData[index] = state;
            }
            field.setter({ ...value, data: newData });
        };

        const renderNonEmptyLines = () => {
            return data.map(line => this.renderPeriodical(context, { modules, executors, frequencies }, onItemChange, onDeleteItem, line));
        }

        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        }

        const onDeleteItem = (id) => {
            let newData = (data || []).filter(x => x.id !== id);
            field.setter({ ...value, data: newData });
        }

        return <div className="periodicals-container">
            {renderNonEmptyLines()}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}
        </div>;

    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderPeriodicals(context)}
                    </div>
                    {this.renderEnd()}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}