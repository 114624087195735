import arxs from "infra/arxs";
import { PeriodicalStatus } from "infra/api/contracts";
import Toaster from "components/util/Toaster";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import GlobalActions from "modules/GlobalActions";
import { createGenerateOccurrencePopup } from "./GenerateOccurrencePopup";

class PeriodicalActions {
  getActions = (module) => {
    return GlobalActions.getActions(module).concat([
      {
        name: "activate",
        module: module,
        icon: arxs.actions.icons["reactivate"],
        getTitle: () => arxs.actions.titles["activate"],
        onClick: (state) => this.activate(state),
      },
      {
        name: "set_inprocess",
        module: module,
        icon: arxs.actions.icons["set_inprocess"],
        getTitle: () => arxs.actions.titles["set_inprocess"],
        onClick: (state) => this.setInProcess(state),
      },
      {
        name: "hold",
        module: module,
        icon: arxs.actions.icons["hold"],
        getTitle: () => arxs.actions.titles["hold"],
        onClick: (state) => this.hold(state),
      },
      {
        name: "unhold",
        module: module,
        icon: arxs.actions.icons["unhold"],
        getTitle: () => arxs.actions.titles["unhold"],
        onClick: (state) => this.unhold(state),
      },
      {
        name: "generate_occurrence",
        module: module,
        icon: arxs.actions.icons["generate_occurrence"],
        getTitle: () => arxs.actions.titles["generate_occurrence"],
        onClick: (state) => this.generateOccurrence(state),
      },
    ]);
  };

  activate = (state) => {
    const ids = state.ids || [state.item.objectId];
    const data = { ids: ids };

    arxs.ApiClient.facilitymanagement.periodical
      .activate(data)
      .then(() => Toaster.success(arxs.t("actions.periodical.setactive.confirmation")));
  }

  unhold = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.facilitymanagement.periodical
        .unhold(data)
        .then(() =>
          Toaster.success(arxs.t("actions.periodical.unhold_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.periodical.unhold_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  };

  setInProcess = (state) => {
    const ids = state.ids || [state.item.objectId];
    const data = { ids: ids };
    return arxs.ApiClient.facilitymanagement.periodical
      .resetToDraft(data)
      .then(() => Toaster.success(arxs.t("actions.periodical.set_inprocess_confirmation")));
  };

  hold = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.facilitymanagement.periodical
        .hold(data)
        .then(() =>
          Toaster.success(arxs.t("actions.periodical.hold_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.periodical.hold_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  generateOccurrence = (state) => {
    const context = state.context;
    const id = state.ids[0] || [state.item.objectId];

    const executeAction = (props) => {
      const data = { id, ...props };
      arxs.ApiClient.facilitymanagement.periodical
        .generateOccurrence(data)
        .then(() => {
          Toaster.success(arxs.t("actions.periodical.generate_occurence_confirmation"));
          context.popup.close();
        });
    };

    const onCancel = () => {
      context.popup.close();
    };

    const generateOccurrencePopup = createGenerateOccurrencePopup(
      (data) => executeAction(data),
      onCancel,
      arxs.t("actions.periodical.generate_occurence_title"),
    );

    context.popup.show(generateOccurrencePopup);
  }

  dropActions = () => {
    return {
      onDropInProcess: (state) => {
        this.setInProcess(state);
      },
      onDropInActive: (state) => {
        switch (state.item.status) {
          case PeriodicalStatus.OnHold:
            this.unhold(state);
            break;
          case PeriodicalStatus.InProcess:
            this.activate(state)
            break;
          default: return;
        }
      },
      onDropInOnHold: (state) => {
        this.hold(state);
      }
    };
  };
}
export default new PeriodicalActions();
