import React, { Component, Fragment } from "react";
import { Scanner } from '@yudiel/react-qr-scanner';

import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import Toaster from "components/util/Toaster";
import "./QRScanner.scss";

const _barcodeFormats = [
    "aztec", "code_128", "code_39", "code_93", "codabar", "databar", "databar_expanded", "data_matrix", "dx_film_edge", "ean_13", "ean_8", "itf", "maxi_code", "micro_qr_code", "pdf417", "qr_code", "rm_qr_code", "upc_a", "upc_e", "linear_codes", "matrix_codes", "unknown"
];
class QRScanner extends Component {
    lookups = {
        labourmeanMap: {},
        protectionEquipmentMap: {},
        hazardousSubstanceMap: {},
        equipmentMap: {},
        combinedInstallationMap: {},
        employeeMap: {},
        objectsByModule: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups,
            scanningQR: false,
        };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups.subscribe(this.lookups, (lookups) =>
                this.setState({ ...lookups })
            ),
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    toggleScanQR = async () => {
        this.setState({ scanningQR: !this.state.scanningQR });
    };

    navigateToCard = (card) => {
        const module = card.module;
        this.props.history.push(arxs.moduleMetadataRegistry.getModulePath(module) + `/${card.id}`);
        this.props.onSearchResult([card]);
    }

    handleScan = (data) => {
        if (data && data.length > 0) {
            const rawValue = data[0].rawValue;

            const result = arxs.parseURL(rawValue);
            if (result.host === window.location.host) {
                let searchTerm = result.searchObject["searchterm"];
                if (!searchTerm) {
                    const pattern = /\/search\/(.*)/i;
                    const matches = result.pathname.match(pattern);
                    searchTerm = matches[1];
                }
                if (searchTerm) {
                    if (this.props.history) {
                        const module = arxs.modules.getModuleForUniqueNumber(searchTerm);

                        if (module && this.state.objectsByModule && this.state.objectsByModule[module]) {
                            const card = this.state.objectsByModule[module].filter(x => x.uniqueNumber.toLowerCase() === searchTerm.toLowerCase())[0];
                            if (card) {
                                this.navigateToCard(card);
                            } else {
                                Toaster.error(arxs.t("search.not_found", { uniqueNumber: searchTerm }));
                            }
                        } else {
                            this.props.history.push(`/search/${searchTerm}`);
                        }
                    } else {
                        this.toggleScanQR();
                        this.props.onSearchResult(searchTerm);
                    }
                }
            } else if (result.host === "") {
                const matches = Object.values(this.state.objectsByModule)
                    .flatMap(cards => cards)
                    .filter(card => card.internalNumber && card.internalNumber === rawValue);

                if (matches.length > 0) {
                    const card = matches[0]
                    this.navigateToCard(card);
                } else {
                    Toaster.error(arxs.t("search.not_found", { uniqueNumber: rawValue }));
                }

            } else {
                window.open(data[0].rawValue, '_blank');
            }

            this.toggleScanQR();
        }
    };

    render() {
        return (
            <GlobalContext.Consumer>
                {(context) => (<Fragment>
                    <button onClick={this.toggleScanQR} className="button-scan-qr">
                        <i
                            className={
                                this.state.scanningQR ? "far fa-times" : "far fa-barcode-read"
                            }
                        ></i>
                    </button>
                    {this.state.scanningQR && <div className="scan-qr">
                        <div clasName="scan-qr-viewport">
                            <Scanner
                                onScan={this.handleScan}
                                constraints={{ facingMode: "environment" }}
                                formats={_barcodeFormats}
                            />
                        </div>
                    </div>}
                </Fragment>
                )}

            </GlobalContext.Consumer>
        );
    }
}
export default QRScanner;
