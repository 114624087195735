import OlLayerTile from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import { GeoJSON } from 'ol/format';
import { bbox } from 'ol/loadingstrategy';
import OlSourceVector from 'ol/source/Vector';
import OlSourceOsm from 'ol/source/OSM';
import OlSourceXyz from 'ol/source/XYZ';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlLayerGroup from 'ol/layer/Group';

import arxs, { ArXsState } from 'infra/arxs';

export enum MapLayerType {
  Osm = 0,
  Base = 1,
  Xyz = 2,
  Wms = 3,
  Vector = 4,
}

export interface MapLayerMetadata {
  id: string,
  name: string,
  type: MapLayerType,
  minZoom?: number | undefined,
  maxZoom?: number | undefined,
  url?: string | undefined,
  params?: any,
}

export interface MapMetadata {
  layers: Array<MapLayerMetadata>,
}

const toLayerGroup = (layers: MapLayerMetadata[]) => {
  const mapToLayer: (meta: MapLayerMetadata) => any[] = (meta: MapLayerMetadata) => {
    switch (meta.type) {
      case MapLayerType.Osm:
        {
          const layer: any = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceOsm(),
            name: meta.name,
            visible: true,
          };
          return [new OlLayerTile(layer)];
        }
      case MapLayerType.Xyz:
        {
          const opts: any = {
            url: meta.url,
            params: meta.params
          };
          const layer: any = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceXyz(opts),
            name: meta.name,
            visible: true,
          };
          return [new OlLayerTile(layer)];
        }
      case MapLayerType.Wms:
        {
          const opts: any = {
            url: meta.url,
            params: meta.params
          };
          const layer: any = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceTileWMS(opts),
            name: meta.name,
            visible: true,
          };
          return [new OlLayerTile(layer)];
        }
      case MapLayerType.Vector:
        {
          const layer = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceVector({
              format: new GeoJSON(),
              url: function (extent) {
                return `${meta.url}${extent.join(",")},EPSG:3857`
              },
              strategy: bbox
            }),
            name: meta.name,
            visible: true,
          };
          return [new OlVectorLayer(layer)];
        }
    }
    return [];
  };

  const group: any = {
    name: "Layergroup",
    layers: layers.flatMap(mapToLayer)
  };

  return new OlLayerGroup(group);
}

export function getKnownLayers(arxs: ArXsState): MapLayerMetadata[] {
  return [
    {
      id: "map",
      name: arxs.t("controls.geo.layers.map_view"),
      type: MapLayerType.Osm
    },
    {
      id: "satellite",
      name: arxs.t("controls.geo.layers.satellite_view"),
      type: MapLayerType.Xyz,
      maxZoom: 19,
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    },
    {
      id: "percelen-vlaanderen",
      name: arxs.t("controls.geo.layers.plot_view"),
      type: MapLayerType.Vector,
      minZoom: 16,
      url: "https://geo.api.vlaanderen.be/Adpf/wfs?service=WFS&request=GetFeature&typeName=Adpf:Adpf&outputFormat=application%2Fjson&srsname=EPSG:3857&bbox=",
    },
  ];
}

export default function generateLayerGroup(layerIds: string[]) {
  return toLayerGroup(getKnownLayers(arxs)
    .filter(x => layerIds.includes(x.id)));
}