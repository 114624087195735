import { ArXsState } from "infra/arxs";
import { ObjectDocumentType, OriginModuleEnum } from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import EmployeeWizard from "./EmployeeWizard";
import EmployeeActions from "./EmployeeActions";
import EmployeeBoard from "./EmployeeBoard";
import EmployeeCard from "./EmployeeCard";

const getEmployeeMetaData = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Employee;

  return {
    module,
    title: arxs.t("modules.employee"),
    icon: "far fa-users",
    path: "/employee",
    base: {
      name: "Employee",
      route: "/employee",
      getResource: () => arxs.Api.getResource("employee"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Employee.Write",
      readAction: "Employee.Read",
    },
    settings: {
      name: "EmployeeSettings",
      route: "/employee/settings",
      getResource: () => arxs.Api.getResource("employee").settings(),
      getImportResource: () => arxs.ApiClient.masterdata.employeeSettings,
      readAction: "Employee.Settings.Write",
      writeAction: "Employee.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.employee_information"),
        initiallyOpen: true,
        fields: [
          { name: "firstname" },
          { name: "surname" },
          { name: "userName" },
          { name: "initials" },
          { name: "emails", fullWidth: true },
          { name: "phones", fullWidth: true },
          { name: "nationalNumber" },
          { name: "employeeNumber" },
          { name: "birthDate" },
          { name: "dateInService" },
        ],
      },
      {
        title: arxs.t("details_pane.assignment"),
        initiallyOpen: true,
        fields: [{ name: "assignments" }],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.Contract,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument,
    ],
    singleFileDocumentTypes: [arxs.documentTypes.image],
    statuses: ["Active", "Sick", "OutOfService"],
    actions: EmployeeActions.getActions(module),
    wizard: {
      wizardClass: EmployeeWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "firstname",
                onChange: (stateProxy) =>
                  EmployeeWizard.setUserNameIfEmpty(stateProxy),
              },
              {
                name: "surname",
                onChange: (stateProxy) =>
                  EmployeeWizard.setUserNameIfEmpty(stateProxy),
              },
            ],
            [{ name: "userName" }, { name: "initials" }],
            [{ name: "nationalNumber" }, { name: "employeeNumber" }],
            [{ name: "birthDate" }, { name: "dateInService" }],
            [{ name: "sex" }],
            [
              {
                name: "nationalities",
                type: "itemlist",
                children: [
                  {
                    name: "name",
                    code: "Nationality",
                  },
                ],
              },
            ],
            [],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.contact"),
          fields: [
            [
              {
                title: arxs.t("field.email"),
                name: "emails",
                type: "itemlist",
                preferredOnly: true,
                noHeaders: true,
                children: [{ name: "email" }],
              },
              {
                title: arxs.t("field.phone"),
                name: "phones",
                type: "itemlist",
                preferredOnly: true,
                noHeaders: true,
                children: [{ name: "number" }],
              },
            ],
            [
              {
                title: arxs.t("field.address"),
                name: "addresses",
                type: "itemlist",
                preferredOnly: true,
                children: [
                  { name: "country", width: "100px" },
                  { name: "zipCode", width: "60px" },
                  { name: "city", width: "150px" },
                  { name: "street" },
                  { name: "number", width: "60px" },
                  { name: "bus", width: "50px" },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.functions"),
          fields: [
            [
              {
                name: "assignments",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  {
                    name: "legalStructure",
                  },
                  {
                    name: "branch",
                    parent: "legalStructure",
                  },
                  { name: "function", code: "Employee.Function" },
                  { name: "statute", code: "Employee.Type" },
                  {
                    name: "paritairComite",
                    filter: (stateProxy: any, row: any, id: number) =>
                      EmployeeWizard.getFilteredParitairComites(
                        stateProxy,
                        row,
                        id
                      ),
                  },
                  { name: "division", code: "Employee.Division" },
                  { name: "fraction" },
                ],
                onChange: (stateProxy, fieldName, row, childName) => {
                  EmployeeWizard.setFirstItemInItemListAsPreferred(
                    stateProxy,
                    fieldName
                  )
                    .then(() =>
                      EmployeeWizard.onChangePreferredInItemList(
                        stateProxy,
                        fieldName,
                        row,
                        childName
                      )
                    )
                    .then(() =>
                      EmployeeWizard.filterParitairComitePerAssignment(
                        stateProxy
                      )
                    );
                },
                onLoad: (stateProxy) =>
                  EmployeeWizard.filterParitairComitePerAssignment(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        employees: [],
      },
    },
    board: {
      lookups: {
        codeElementsById: {},
        employees: [],
        legalStructureMap: {},
        branchMap: {}
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "firstname" },
        { name: "surname" },
        { name: "email" },
        { name: "functions" },
        { name: "address" },
        { name: "geoLocation" },
        { name: "phone" },
        { name: "nationalNumber" },
        { name: "userName" },
        { name: "assignmentLocations" },
        { name: "owner" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: EmployeeBoard,
      bucketConfig: [
        {
          module: module,
          status: "Active",
          onDrop: (state) =>
            EmployeeActions.dropActions().onDropInActive(state),
          requiredActions: ["in_service"],
        },
        {
          module: module,
          status: "Sick",
          onDrop: (state) => EmployeeActions.dropActions().onDropInSick(state),
          requiredActions: ["sick"],
        },
        {
          module: module,
          status: "OutOfService",
          onDrop: (state) =>
            EmployeeActions.dropActions().onDropInOutOfService(state),
          requiredActions: ["out_of_service"],
        },
      ],
      views: [BoardViewType.KanBan, BoardViewType.Grid],
    },
    cardClass: EmployeeCard,
  };
};
export default getEmployeeMetaData;
