import React, { Fragment, useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import arxs from 'infra/arxs';
import { OriginModuleEnum, LinkType } from 'infra/api/contracts';
import Badge from "components/controls/Badge";
import CardList from 'components/controls/cardlist/CardList';

import './LinksTab.scss';

const initialLookups = {
  codeElementsById: {},
  employeeMap: {},
  userRoleMap: {},
  contactMap: {},
  supplierMap: {},

  legalStructureMap: {},
  branchMap: {},
  buildingMap: {},
  locationMap: {},

  labourmeanMap: {},
  equipmentMap: {},
  intangibleAssetMap: {},
  protectionEquipmentMap: {},
  hazardousSubstanceMap: {},
  combinedInstallationMap: {},

  taskMap: {},
  taskRequestMap: {},
  projectMap: {},
  planningMomentsBySubjectMap: {},
  recommendationMap: {},
  commissioningMap: {},
  decommissioningMap: {},
  instructionCardMap: {},
  safetyInstructionCardMap: {},
  riskAnalysisMap: {},
};

export default function LinksTab(props) {
  const { uniqueNumber, inboundLinks, outboundLinks } = props;

  const [lookups, setLookups] = useState(initialLookups);
  const [moduleOrderMap, setModuleOrderMap] = useState({});

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(initialLookups, (values) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
      }),
    };

    const documentManagementModuleExtension = [
      OriginModuleEnum.SchoolGroup,
      OriginModuleEnum.School,
      OriginModuleEnum.Building,
      OriginModuleEnum.Room,
    ]

    const modulesInOrder = [
      OriginModuleEnum.Room,
      OriginModuleEnum.Labourmeans,
      OriginModuleEnum.EquipmentInstallation,
      OriginModuleEnum.IntangibleAsset,
      OriginModuleEnum.Pbm,
      OriginModuleEnum.HazardousSubstance,
      OriginModuleEnum.Consultancy,
      OriginModuleEnum.CombinedInstallation,
      OriginModuleEnum.RiskAnalysis,
      OriginModuleEnum.InstructionCard,
      OriginModuleEnum.SafetyInstructionCard,
      OriginModuleEnum.Commissioning,
      OriginModuleEnum.NotificationDefect,
      OriginModuleEnum.Task,
      OriginModuleEnum.PeriodicControl,
      OriginModuleEnum.PeriodicMaintenance,
      OriginModuleEnum.Periodical,
      OriginModuleEnum.GlobalPreventionPlan,
      OriginModuleEnum.YearActionPlan,
      OriginModuleEnum.IncidentManagement,
      OriginModuleEnum.OutOfCommissioning,
      OriginModuleEnum.Project,
      OriginModuleEnum.ActivityEntry
    ]

    const allModules = (props.module === OriginModuleEnum.Document ? documentManagementModuleExtension.concat(modulesInOrder) : modulesInOrder)
      .map((module, index) => ({ module, index }));

    setModuleOrderMap(allModules.toDictionary(x => x.module, x => x.index));

    return () => {
      subscriptions.lookups.dispose();
    };
  }, []);

  const groupedLinks = (inboundLinks || [])
    .concat(outboundLinks || [])
    .filter(x => props.module === OriginModuleEnum.Room || x.type !== LinkType.TransientReference)
    .groupBy(x => x.module);

  const orderedGroups = Object.keys(groupedLinks)
    .filter(module => moduleOrderMap[module] !== undefined)
    .orderBy(module => moduleOrderMap[module])
    .map(module => ({ module, links: groupedLinks[module] }));

  const renderHeader = (module, title, count, metadata) => {
    const navigateToModule = () => {
      window.open(
        `${metadata.base.route}/link%3a${uniqueNumber}`,
        "_blank",
        "noreferrer"
      );
    };
    return <Fragment>
      {title}
      <Badge>{count}</Badge>
      <div className="spacer"></div>
      <i className="far fa-external-link" onClick={navigateToModule}></i>
      <div className="terminal"></div>
    </Fragment>;
  };


  const renderLinks = () => {
    return <div className="links-tab">
      {orderedGroups
        .flatMap(group => {
          const module = group.module;
          const metadata = arxs.moduleMetadataRegistry.get(module);
          const title = arxs.modules.titles[module];
          const links = group.links.distinct(x => x.id);
          const cards = links.map(link => arxs.Api.lookups.resolveSubject(link));
          const filteredCards = cards
            .orderByDescending(card => new Date(card.createdAt).getTime() + (card.isDeleted ? -1 : 0) * 1e12);

          const count = filteredCards.length;

          if (count === 0) {
            return [];
          }

          return [<Collapsible key={module}
            trigger={renderHeader(module, title, count, metadata)}
            open={false}>
            <CardList
              key={module}
              readOnly={true}
              value={filteredCards}
            />
          </Collapsible>];
        })}
      {orderedGroups.length === 0 && <div className="no-links">{arxs.t("details_pane.no_links")}</div>}
    </div>;
  }

  return <Fragment>
    {renderLinks()}
  </Fragment>;
}
