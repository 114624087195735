import React from "react";
import Toaster from "components/util/Toaster";
import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { OriginModuleEnum, RelationshipType } from "infra/api/contracts";

class IncidentBoard extends BoardController {
  getVictim = (victim, stateProxy) => {
    if (victim !== undefined) {
      switch (victim.module) {
        case OriginModuleEnum.Employee: return this.getEmployeeValue(
          victim,
          stateProxy.getter("employeeMap"));
        case OriginModuleEnum.Contact: return this.getContactValue( 
          victim,
          stateProxy.getter("contactMap"));
        default: return;
      }
    }
  }

  getSeniority = (victim, stateProxy) => {
    if (victim !== undefined && victim.module === OriginModuleEnum.Employee){
      const employeeMap = stateProxy.getter("employeeMap");
      const employee = employeeMap && employeeMap[victim.id];
      if (employee && employee.dateInService){
        var seniorityDiffs = Date.now() - new Date(employee.dateInService);
        var seniorityAge = new Date(seniorityDiffs);
        return Math.abs(seniorityAge.getUTCFullYear() - 1970);

      }
      return;
    }
  }

  enrichCard(card, stateProxy) {
    const incidentTime = card.incidentTime && new Date(card.incidentTime);
    return {
      ...card,

      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"),
        stateProxy.getter("userRoleMap")
      ),
      preventionAdvisor: this.getEmployeeValue(
        card.relationships.filter(
          (x) => x.type === RelationshipType.PreventionAdvisor
        ),
        stateProxy.getter("employeeMap")
      ),
      victimDepartment: this.getCodeElementValue(
        card.victimDepartment,
        stateProxy.getter("codeElementsById")
      ),
      victimFunction: this.getCodeElementValue(
        card.victimFunction,
        stateProxy.getter("codeElementsById")
      ),
      seniority: this.getSeniority(card.victim, stateProxy),
      victim: this.getVictim(card.victim, stateProxy),
      incidentTimeRaw: incidentTime,
      incidentDate: incidentTime && arxs.dateTime.formatDate(incidentTime),
      incidentTime: incidentTime && arxs.dateTime.formatTime(incidentTime),
      incidentGridDate: incidentTime && new Date(incidentTime),
      injuryDescription: this.getCodeElementValue(
        card.injuryDescription,
        stateProxy.getter("codeElementsById")
      ),
      inactivityDuration:
        card.activityStoppedTime &&
        card.activityRestartedTime &&
        arxs.dateTime.daysBetween(
          new Date(card.activityStoppedTime),
          new Date(card.activityRestartedTime)
        ),
      title: this.getEmployeeValue(
        card.victim,
        stateProxy.getter("employeeMap")
      ).name,
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      createdAt: card.createdAt && new Date(card.createdAt),
      victimPbms: (card.victimPbms || []).map((x) =>
        this.getCodeElementValue(x, stateProxy.getter("codeElementsById"))
      ),
      suppliers: (card.suppliers || []).map(x => this.getLookupValue(stateProxy.getter("supplierMap"), x)),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      card.kind,
      card.type,
      card.description,
      card.responsible,
      ...card.coResponsibles,
      card.preventionAdvisor,
      card.victim,
      arxs.enums.getTitle(
        "IncidentManagementVictimType",
        card.victimType
      ),
      card.victimDepartment,
      card.victimFunction,
      card.injuryDescription,
      card.owner,
      card.incidentDate,
      card.incidentTime,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.isSerious && arxs.t("kanban.common.serious"),
      card.locationType &&
      arxs.enums.getTitle(
        "IncidentManagementLocationType",
        card.locationType
      ),
      ...(card.victimPbms || []),
      ...(card.tags || []).map((x) => x.name),
      ...(card.suppliers || []),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.incidents) {
        lookups.pristine = lookups.incidents
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.incidents;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Activities yet");
  };

  getFullExportActions() {
    const metadata = arxs.moduleMetadataRegistry.get(OriginModuleEnum.IncidentManagement);

    return (metadata.board.excelExports || [])
      .filter((x) => !!x.alwaysOn === true)
      .map((report) => ({
        content: (
          <div className="board-export-selector">
            <i className="far fa-file-excel"></i>
            {arxs.t(`report.${report.name}`)}
          </div>
        ),
        onClick: (e) => {
          arxs.ReportClient.reporting().generateBinary(
            report.name,
            metadata.module
          );
          Toaster.notify(arxs.t("report.requested"));
        },
      }));
  }
}
export default new IncidentBoard();
