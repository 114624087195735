import React from "react";

import GlobalContext from "infra/GlobalContext";

import { CardClassProps } from "components/card/CardContracts";
import CardActions from "components/card/CardActions";
import { createGeoLookup } from "components/controls/geolocation/GeoLookup";

export default function GeoLocationCard(props: CardClassProps) {
    const { card, cardProps, actionProps } = props;

    const toggleGeoPopup = (context: any) => {
        const addressLookup = createGeoLookup(context, card);
        context.popup.show(addressLookup);
    };

    if (actionProps.condensed) {
        return <GlobalContext.Consumer>
            {(context) => (
            <div className={`user-card ${cardProps.className || ""}`}>
                <div className="user-card-icon">
                    <i className="far fa-map-marked-alt"></i>
                </div>
                <div className="user-card-names">
                    <div className="user-card-name">
                        <div>
                            {card.street} {card.number} {card.bus && `bus ${card.street}`}
                        </div>
                        <div>
                            {card.zipCode} {card.city}
                        </div>
                        <div>{card.country}</div>
                        {card.latitude && card.longitude && (
                            <div>
                                {card.latitude.toFixed(6)}&deg; {card.longitude.toFixed(6)}
                                &deg;
                            </div>
                        )}
                    </div>
                </div>
                <CardActions
                    {...actionProps}
                    showNavigateTo={true}
                    onNavigateTo={() => toggleGeoPopup(context)}
                    showCheckbox={false}
                />
            </div>
            )}
        </GlobalContext.Consumer>;
    }
  
    return <GlobalContext.Consumer>
        {(context) => (
            <>
                <div className="list-card-left">
                    <i className="far fa-map-marked-alt"></i>
                </div>
                <div className="list-card-right">
                    <h2>
                        <CardActions
                            {...actionProps}
                            onNavigateTo={() => toggleGeoPopup(context)}
                        />
                    </h2>
                    <div className="list-card-details">
                        <div>
                            {card.street} {card.number} {card.bus && `bus ${card.street}`}
                        </div>
                        <div>
                            {card.zipCode} {card.city}
                        </div>
                        <div>{card.country}</div>
                        {card.latitude && card.longitude && (
                            <div>
                                {card.latitude.toFixed(6)}&deg; {card.longitude.toFixed(6)}&deg;
                            </div>
                        )}
                    </div>
                </div>
            </>
        )}
    </GlobalContext.Consumer>;
}